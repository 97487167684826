/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { Injectable, Directive, TemplateRef, Optional, isDevMode, Input, ViewChild, ElementRef, HostBinding, ViewChildren, QueryList, ContentChildren, Component, SkipSelf, Host, Renderer2, EventEmitter, Output, ContentChild, HostListener, ChangeDetectorRef, NgZone, Inject, forwardRef, ViewEncapsulation, NgModule } from '@angular/core';
import { LocalizationService, L10N_PREFIX, ComponentMessages } from '@progress/kendo-angular-l10n';
import { Keys, DraggableDirective, PreventableEvent, isDocumentAvailable, hasObservers, EventsModule, DraggableModule, ResizeSensorModule, isChanged } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import { trigger, state, style, transition, animate, AUTO_STYLE, AnimationBuilder } from '@angular/animations';
import { Subject, BehaviorSubject, Subscription, of } from 'rxjs';
import { tap, filter, switchMap, delay, takeUntil, map, take } from 'rxjs/operators';
import Draggable from '@telerik/kendo-draggable';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-layout',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1644936591,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * Represents the expand modes of the PanelBar.
 * By default, the expand mode is set to `multiple`.
 */
var PanelBarExpandMode;
(function (PanelBarExpandMode) {
    /**
     * Allows you to expand only one item at a time.
     * When you expand an item, the item that was previously expanded is coll.
     */
    PanelBarExpandMode[PanelBarExpandMode["Single"] = 0] = "Single";
    /**
     * Allows you to expand only one item at a time and requires you to set the `height` property.
     * The expanded area occupies the entire height of the PanelBar.
     */
    PanelBarExpandMode[PanelBarExpandMode["Full"] = 1] = "Full";
    /**
     * The default mode of the PanelBar.
     * Allows you to expand more than one item at a time. Items can also be toggled.
     */
    PanelBarExpandMode[PanelBarExpandMode["Multiple"] = 2] = "Multiple";
    /**
     * By default, the expand mode is set to `multiple`.
     */
    PanelBarExpandMode[PanelBarExpandMode["Default"] = 2] = "Default";
})(PanelBarExpandMode || (PanelBarExpandMode = {}));

/**
 * @hidden
 */
let nextPanelbarId = 0;
/**
 * @hidden
 */
let PanelBarService = class PanelBarService {
    constructor() {
        this.parentSource = new Subject();
        this.keepContentSource = new BehaviorSubject(false);
        this.childSource = new Subject();
        this.parent$ = this.parentSource.asObservable();
        this.children$ = this.childSource.asObservable();
        this.keepContent$ = this.keepContentSource.asObservable();
        this.pbId = nextPanelbarId++;
    }
    onKeepContent(keepContent) {
        this.keepContentSource.next(keepContent);
    }
    onSelect(event) {
        this.childSource.next(event);
    }
    onFocus() {
        this.parentSource.next(true);
    }
    onBlur() {
        this.parentSource.next(false);
    }
};
PanelBarService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], PanelBarService);

/**
 * Represents the content template of the declaratively initialized PanelBar items.
 * The content can be expanded or collapsed through the item.
 */
let PanelBarContentDirective = class PanelBarContentDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PanelBarContentDirective = __decorate([
    Directive({
        selector: "[kendoPanelBarContent]"
    }),
    __metadata("design:paramtypes", [TemplateRef])
], PanelBarContentDirective);

/**
 * Represents the template directive of the PanelBar which helps to customize the item title
 * ([more information and example]({% slug templates_panelbar %}#toc-customizing-the-appearance-of-the-title)).
 *
 * > The `kendoPanelBarItemTitle` directive overrides the PanelBarItem [title]({% slug api_layout_panelbaritemcomponent %}#toc-title) option.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *        <kendo-panelbar>
 *            <kendo-panelbar-item [expanded]="true">
 *                <ng-template kendoPanelBarItemTitle>
 *                    Item Title
 *                </ng-template>
 *            </kendo-panelbar-item>
 *        </kendo-panelbar>
 *     `
 * })
 *
 * class AppComponent {}
 *
 * ```
 */
let PanelBarItemTitleDirective = class PanelBarItemTitleDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PanelBarItemTitleDirective = __decorate([
    Directive({
        selector: '[kendoPanelBarItemTitle]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PanelBarItemTitleDirective);

let nextId = 0;
const SIZES = {
    small: 'sm',
    medium: 'md',
    large: 'lg'
};
const ROUNDNESS = {
    small: 'sm',
    medium: 'md',
    large: 'lg',
    full: 'full'
};
const SHAPE_TO_ROUNDED = {
    rounded: 'large',
    circle: 'full'
};
const parsePanelBarItems = (data) => {
    return data.map((item) => {
        if (!item.id) {
            item.id = `default-${nextId++}`;
        }
        if (item.children) {
            item.children = parsePanelBarItems(item.children);
        }
        return item;
    });
};
var util = {
    parsePanelBarItems
};
/**
 * @hidden
 */
const isPresent = (value) => value !== null && value !== undefined;
/**
 * @hidden
 */
const isHorizontalArrowKey = keyCode => keyCode === Keys.ArrowLeft || keyCode === Keys.ArrowRight;
/**
 * @hidden
 */
const isVerticalArrowKey = keyCode => keyCode === Keys.ArrowUp || keyCode === Keys.ArrowDown;
/**
 * @hidden
 */
const isArrowKey = keyCode => isHorizontalArrowKey(keyCode) || isVerticalArrowKey(keyCode);
/**
 * @hidden
 */
const isNavigationKey = keyCode => keyCode === Keys.PageUp || keyCode === Keys.PageDown ||
    keyCode === Keys.Home || keyCode === Keys.End;
/**
 * @hidden
 *
 * Returns the styling classes to be added and removed
 */
const getStylingClasses = (componentType, stylingOption, previousValue, newValue) => {
    switch (stylingOption) {
        case 'size':
            return {
                toRemove: `k-${componentType}-${SIZES[previousValue]}`,
                toAdd: newValue ? `k-${componentType}-${SIZES[newValue]}` : null
            };
        case 'rounded':
            return {
                toRemove: `k-rounded-${ROUNDNESS[previousValue]}`,
                toAdd: newValue ? `k-rounded-${ROUNDNESS[newValue]}` : null
            };
        default:
            break;
    }
};
/**
 * @hidden
 */
const mapShapeToRounded = (shape) => SHAPE_TO_ROUNDED[shape] || null;
/**
 * @hidden
 */
const isNumber = (value) => typeof value === 'number' && isFinite(value);

const focusableRegex = /^(?:a|input|select|option|textarea|button|object)$/i;
const toClassList = (classNames) => String(classNames).trim().split(' ');
/**
 * @hidden
 */
const isFocusable = (element) => {
    if (element.tagName) {
        const tagName = element.tagName.toLowerCase();
        const tabIndex = element.getAttribute('tabIndex');
        const skipTab = tabIndex === '-1';
        let focusable = tabIndex !== null && !skipTab;
        if (focusableRegex.test(tagName)) {
            focusable = !element.disabled && !skipTab;
        }
        return focusable;
    }
    return false;
};
/**
 * @hidden
 */
const hasClass = (element, className) => Boolean(toClassList(element.className).find((name) => name === className));
/**
 * @hidden
 */
const closestInScope = (target, predicate, scope, targetAttr) => {
    while (target && target !== scope && !predicate(target, targetAttr)) {
        target = target.parentNode;
    }
    if (target !== scope) {
        return target;
    }
};
/**
 * @hidden
 */
const itemIndex = (item, indexAttr) => +item.getAttribute(indexAttr);
const hasItemIndex = (item, indexAttr) => isPresent(item.getAttribute(indexAttr));
/**
 * @hidden
 */
const closestItem = (target, targetAttr, scope) => closestInScope(target, hasItemIndex, scope, targetAttr);

var PanelBarItemComponent_1;
/**
 * @hidden
 */
let nextId$1 = 0;
/**
 * Represents the items of the PanelBar.
 */
let PanelBarItemComponent = PanelBarItemComponent_1 = class PanelBarItemComponent {
    constructor(parent, eventService, element, renderer) {
        this.parent = parent;
        this.eventService = eventService;
        this.element = element;
        this.renderer = renderer;
        /**
         * Allows the component to set the `"id"` property to each item.
         * Used to set the `id` attributes of the nested elements and to enable the WAI-ARIA support.
         */
        this.id = `default-${nextId$1++}`;
        /**
         * Defines the icon that will be rendered next to the title ([see example]({% slug items_panelbar %}#toc-title-icons)).
         */
        this.icon = '';
        /**
         * Defines  the icon that will be rendered next to the title by using a custom CSS class
         * ([see example]({% slug items_panelbar %}#toc-title-icons)).
         */
        this.iconClass = '';
        /**
         * Defines the location of the image that will be displayed next to the title
         * ([see example]({% slug items_panelbar %}#toc-title-images)).
         */
        this.imageUrl = '';
        /**
         * When set to `true`, disables a PanelBar item ([see example]({% slug items_panelbar %}#toc-disabled-state)).
         */
        this.disabled = false;
        /**
         * Sets the selected state of a PanelBar item ([see example]({% slug items_panelbar %}#toc-selected-state)).
         */
        this.selected = false;
        this.keepContent = false;
        this.hasChildItems = false;
        this.hasItems = false;
        this.hasContent = false;
        this.state = "inactive";
        this.role = "treeitem";
        this.titleAttribute = null; // tslint:disable-line
        this.kItemClass = true;
        this.focused = false;
        this.wrapperFocused = false;
        this.subscriptions = new Subscription(() => { });
        this._expanded = false;
        this.subscriptions.add(eventService.parent$.subscribe(focused => this.onWrapperFocusChange(focused)));
        this.subscriptions.add(eventService.keepContent$.subscribe(keepContent => this.keepContent = keepContent));
        this.wrapperFocused = parent ? parent.focused : false;
        this.level = this.parent ? this.parent.level + 1 : 0;
    }
    /**
     * When set to `true`, expands the PanelBar item ([see example]({% slug items_panelbar %}#toc-expanded-state)).
     */
    set expanded(value) {
        const activeState = this.animate ? "active" : "activeWithoutAnimation";
        this.state = value ? activeState : "inactive";
        if (!this.keepContent) {
            this.toggleExpandedChildAnimations(value);
        }
        this._expanded = value;
    }
    get expanded() {
        return this._expanded;
    }
    get animate() {
        return this.eventService.animate;
    }
    get kStateDefaultClass() {
        return !this.disabled;
    }
    get kStateDisabledClass() {
        return this.disabled;
    }
    get kStateExpandedClass() {
        return !this.disabled && this.expanded && (this.hasChildItems || this.hasContent);
    }
    get itemId() {
        return 'k-panelbar-' + this.eventService.pbId + '-item-' + this.id;
    }
    get ariaExpanded() {
        return (this.hasChildItems || this.hasContent) ? !this.disabled && this.expanded : null;
    }
    get ariaSelected() {
        return !this.disabled && this.selected;
    }
    get ariaDisabled() {
        return this.disabled ? true : null;
    }
    get headerClass() {
        return this.parent ? null : true;
    }
    get childClass() {
        return this.parent ? true : null;
    }
    /**
     * @hidden
     */
    get titleTemplate() {
        return this.titleTemplates.length > 0 ? this.titleTemplates.toArray()[0].templateRef : undefined;
    }
    /**
     * @hidden
     */
    headerHeight() {
        return this.element.nativeElement.offsetHeight - (this.contentWrapper ? this.contentWrapper.nativeElement.offsetHeight : 0);
    }
    ngOnInit() {
        this.addLevelClass();
    }
    ngAfterContentChecked() {
        this.hasItems = this.items && this.items.filter(item => !item.hidden).length > 0;
        this.hasChildItems = this.contentItems.filter(item => item !== this).length > 0 || this.hasItems;
        this.hasContent = (this.contentTemplate !== undefined && this.contentTemplate.length > 0) ||
            this.content !== undefined;
        this.validateConfiguration();
    }
    ngAfterViewChecked() {
        if (this.items) {
            this.childrenItems = this.viewChildItems.toArray();
        }
        else {
            this.childrenItems = this.contentItems.filter(item => item !== this);
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    /**
     * @hidden
     */
    onItemAction() {
        if (!this.disabled) {
            this.eventService.onSelect(this);
        }
    }
    /**
     * @hidden
     */
    onItemClick(e) {
        if (!isFocusable(e.target)) {
            this.onItemAction();
        }
    }
    /**
     * @hidden
     */
    get iconClasses() {
        let icon = this.icon ? 'k-i-' + this.icon : null;
        return {
            [icon || this.iconClass]: true
        };
    }
    /**
     * @hidden
     */
    serialize() {
        return {
            content: this.content,
            disabled: this.disabled,
            expanded: this.expanded,
            focused: this.focused,
            icon: this.icon,
            iconClass: this.iconClass,
            id: this.id,
            imageUrl: this.imageUrl,
            selected: this.selected,
            title: this.title
        };
    }
    /**
     * @hidden
     */
    subTreeViewItems() {
        let subTree = [];
        this.viewChildItems.forEach(item => {
            subTree = subTree.concat(item.subTreeViewItems());
            subTree.push(item);
        });
        return subTree;
    }
    /**
     * @hidden
     */
    validateConfiguration() {
        if (isDevMode()) {
            if (this.content && (this.contentTemplate !== undefined && this.contentTemplate.length > 0)) {
                throw new Error("Invalid configuration: mixed template components and component property.");
            }
        }
    }
    /**
     * @hidden
     */
    toggleAnimationState(value) {
        if (!this.animate) {
            return;
        }
        this.state = value && this.eventService.expandMode !== PanelBarExpandMode.Single ? 'active' : 'activeWithoutAnimation';
    }
    /**
     * @hidden
     */
    toggleExpandedChildAnimations(value) {
        if (this.childrenItems) {
            this.childrenItems.forEach(child => {
                if (child.expanded) {
                    child.toggleAnimationState(value);
                    child.toggleExpandedChildAnimations(value);
                }
            });
        }
    }
    /**
     * @hidden
     */
    addLevelClass() {
        if (this.level >= 0) {
            this.renderer.addClass(this.element.nativeElement, `k-level-${this.level}`);
        }
    }
    onWrapperFocusChange(focused) {
        this.wrapperFocused = focused;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "id", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "imageUrl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PanelBarItemComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PanelBarItemComponent.prototype, "expanded", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PanelBarItemComponent.prototype, "selected", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PanelBarItemComponent.prototype, "content", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], PanelBarItemComponent.prototype, "items", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], PanelBarItemComponent.prototype, "template", void 0);
__decorate([
    ViewChild('header', { static: false }),
    __metadata("design:type", ElementRef)
], PanelBarItemComponent.prototype, "header", void 0);
__decorate([
    ViewChild('contentWrapper', { static: false }),
    __metadata("design:type", ElementRef)
], PanelBarItemComponent.prototype, "contentWrapper", void 0);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "role", void 0);
__decorate([
    HostBinding('attr.title'),
    __metadata("design:type", String)
], PanelBarItemComponent.prototype, "titleAttribute", void 0);
__decorate([
    HostBinding('class.k-item'),
    __metadata("design:type", Boolean)
], PanelBarItemComponent.prototype, "kItemClass", void 0);
__decorate([
    HostBinding('class.k-state-default'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "kStateDefaultClass", null);
__decorate([
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "kStateDisabledClass", null);
__decorate([
    HostBinding('class.k-state-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "kStateExpandedClass", null);
__decorate([
    HostBinding('id'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "itemId", null);
__decorate([
    HostBinding('attr.aria-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "ariaExpanded", null);
__decorate([
    HostBinding('attr.aria-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "ariaSelected", null);
__decorate([
    HostBinding('attr.aria-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "ariaDisabled", null);
__decorate([
    HostBinding('class.k-panelbar-header'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "headerClass", null);
__decorate([
    HostBinding('class.k-panelbar-item'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], PanelBarItemComponent.prototype, "childClass", null);
__decorate([
    ViewChildren(PanelBarItemComponent_1),
    __metadata("design:type", QueryList)
], PanelBarItemComponent.prototype, "viewChildItems", void 0);
__decorate([
    ContentChildren(PanelBarItemComponent_1),
    __metadata("design:type", QueryList)
], PanelBarItemComponent.prototype, "contentItems", void 0);
__decorate([
    ContentChildren(PanelBarContentDirective, { descendants: false }),
    __metadata("design:type", QueryList)
], PanelBarItemComponent.prototype, "contentTemplate", void 0);
__decorate([
    ContentChildren(PanelBarItemTitleDirective, { descendants: false }),
    __metadata("design:type", QueryList)
], PanelBarItemComponent.prototype, "titleTemplates", void 0);
PanelBarItemComponent = PanelBarItemComponent_1 = __decorate([
    Component({
        animations: [
            trigger('toggle', [
                state('inactive', style({ display: 'none' })),
                transition('* => active', [
                    style({ overflow: 'hidden', display: 'block', height: 0 }),
                    animate(200, style({ height: AUTO_STYLE }))
                ]),
                transition('active => *', [
                    style({ overflow: 'hidden', height: AUTO_STYLE }),
                    animate(200, style({ height: 0, display: 'none' }))
                ])
            ])
        ],
        exportAs: 'kendoPanelbarItem',
        selector: "kendo-panelbar-item",
        template: `
            <span
                #header
                [class.k-link]="true"
                [class.k-state-selected]="!disabled && selected"
                [class.k-state-focused]="focused && wrapperFocused"
                (click)="onItemClick($event)">
                <span
                    *ngIf="icon || iconClass"
                    class="k-icon k-panelbar-item-icon"
                    [ngClass]="iconClasses">
                </span>
                <img
                    *ngIf="imageUrl"
                    class="k-image k-panelbar-item-icon"
                    [src]="imageUrl"
                    alt="">
                <ng-container *ngIf="!titleTemplate"><span class="k-panelbar-item-text">{{title}}</span></ng-container>
                <ng-template *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate"></ng-template>
                <span *ngIf="hasChildItems || hasContent"
                    class="k-icon k-panelbar-toggle"
                    [ngClass]="{'k-i-arrow-chevron-up k-panelbar-collapse': expanded, 'k-i-arrow-chevron-down k-panelbar-expand': !expanded}">
                </span>
            </span>
            <div #contentWrapper
                *ngIf="keepContent || (!disabled && expanded && (hasChildItems || hasContent))"
                [@toggle]="state"
                [attr.role]="'group'"
                [attr.aria-hidden]="!disabled && !expanded">
                <div
                    *ngIf="hasChildItems && !items?.length"
                    [style.overflow]="contentOverflow"
                    [style.height]="contentHeight"
                    class="k-panel k-group k-panelbar-group">
                        <ng-content select="kendo-panelbar-item"></ng-content>
                </div>
                <div
                    *ngIf="hasContent && !content"
                    [style.overflow]="contentOverflow"
                    [style.height]="contentHeight"
                    class="k-content k-panelbar-content">
                    <ng-template
                        [ngTemplateOutlet]="contentTemplate.first.templateRef"
                        [ngTemplateOutletContext]="{
                            $implicit: {
                                title: title,
                                id: id,
                                icon: icon,
                                imageUrl: imageUrl,
                                disabled: disabled,
                                content: content
                            }
                        }">
                    </ng-template>
                </div>
                <div *ngIf="hasItems"
                    [style.overflow]="contentOverflow"
                    [style.height]="contentHeight"
                    class="k-panel k-group k-panelbar-group">
                    <ng-container *ngFor="let item of items">
                        <kendo-panelbar-item *ngIf="!item.hidden"
                            [title]="item.title"
                            [id]="item.id"
                            [icon]="item.icon"
                            [iconClass]="item.iconClass"
                            [imageUrl]="item.imageUrl"
                            [selected]="!!item.selected"
                            [expanded]="!!item.expanded"
                            [disabled]="!!item.disabled"
                            [template]="template"
                            [items]="item.children"
                            [content]="item.content">
                        </kendo-panelbar-item>
                    </ng-container>
                </div>
                <div
                    *ngIf="content"
                    [style.overflow]="contentOverflow"
                    [style.height]="contentHeight"
                    class="k-content k-panelbar-content">
                    <ng-template
                        [ngTemplateOutlet]="template"
                        [ngTemplateOutletContext]="{
                            $implicit: {
                                title: title,
                                id: id,
                                icon: icon,
                                imageUrl: imageUrl,
                                disabled: disabled,
                                content: content
                            }
                        }">
                    </ng-template>
                    <ng-template [ngIf]="!template">{{content}}</ng-template>
                </div>
            </div>`
    }),
    __param(0, SkipSelf()), __param(0, Host()), __param(0, Optional()),
    __metadata("design:paramtypes", [PanelBarItemComponent,
        PanelBarService,
        ElementRef,
        Renderer2])
], PanelBarItemComponent);

/**
 * Represents the template directive of the PanelBar which helps to customize the item content.
 */
let PanelBarItemTemplateDirective = class PanelBarItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
PanelBarItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoPanelBarItemTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], PanelBarItemTemplateDirective);

/**
 * Represents the [Kendo UI PanelBar component for Angular]({% slug overview_panelbar %}).
 */
let PanelBarComponent = 
// TODO: add styles as input prop
class PanelBarComponent {
    constructor(elementRef, eventService, localization) {
        this.localization = localization;
        /**
         * Sets the expand mode of the PanelBar through the `PanelBarExpandMode` enum ([see example]({% slug expandmodes_panelbar %})).
         *
         * The available modes are:
         * - `"single"`&mdash;Expands only one item at a time. Expanding an item collapses the item that was previously expanded.
         * - `"multiple"`&mdash;The default mode of the PanelBar.
         * Expands more than one item at a time. Items can also be toggled.
         * - `"full"`&mdash;Expands only one item at a time.
         * The expanded area occupies the entire height of the PanelBar. Requires you to set the `height` property.
         */
        this.expandMode = PanelBarExpandMode.Default;
        /**
         * Allows the PanelBar to modify the selected state of the items.
         */
        this.selectable = true;
        /**
         * Sets the animate state of the PanelBar ([see example]({% slug animations_panelbar %})).
         */
        this.animate = true;
        /**
         * Sets the height of the component when the `"full"` expand mode is used.
         * This option is ignored in the `"multiple"` and `"single"` expand modes.
         */
        this.height = "400px";
        /**
         * Fires each time the user interacts with a PanelBar item
         * ([see example]({% slug routing_panelbar %}#toc-getting-the-selected-item)).
         * The event data contains all items that are modified.
         */
        this.stateChange = new EventEmitter();
        this.tabIndex = 0;
        this.role = "tree";
        this.hostClass = true;
        this.activeDescendant = "";
        this.isViewInit = true;
        this.focused = false;
        this._keepItemContent = false;
        this.updateChildrenHeight = () => {
            let childrenHeight = 0;
            const panelbarHeight = this.elementRef.nativeElement.offsetHeight;
            const contentOverflow = this.expandMode === PanelBarExpandMode.Full ? 'auto' : 'visible';
            this.childrenItems.forEach(item => {
                childrenHeight += item.headerHeight();
            });
            this.childrenItems.forEach(item => {
                item.contentHeight = PanelBarExpandMode.Full === this.expandMode ? (panelbarHeight - childrenHeight) + "px" : 'auto';
                item.contentOverflow = contentOverflow;
            });
        };
        validatePackage(packageMetadata);
        /* tslint:disable-line */
        this.keyBindings = this.computedKeys;
        this.elementRef = elementRef;
        this.eventService = eventService;
        this.eventService.children$.subscribe(event => this.onItemAction(event));
    }
    /**
     * When set to `true`, the PanelBar renders the content of all items and they are persisted in the DOM
     * ([see example]({% slug templates_panelbar %}#toc-collections)).
     * By default, this option is set to `false`.
     */
    get keepItemContent() {
        return this._keepItemContent;
    }
    set keepItemContent(keepItemContent) {
        this._keepItemContent = keepItemContent;
        this.eventService.onKeepContent(keepItemContent);
    }
    /**
     * Sets the items of the PanelBar as an array of `PanelBarItemModel` instances
     * ([see example]({% slug items_panelbar %})).
     */
    set items(data) {
        if (data) {
            this._items = util.parsePanelBarItems(data);
        }
    }
    get items() {
        return this._items;
    }
    get hostHeight() {
        return this.expandMode === PanelBarExpandMode.Full ? this.height : 'auto';
    }
    get overflow() {
        return this.expandMode === PanelBarExpandMode.Full ? "hidden" : "visible";
    }
    get dir() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
    /**
     * @hidden
     */
    invertKeys(original, inverted) {
        return this.localization.rtl ? inverted : original;
    }
    get computedKeys() {
        return {
            [Keys.Space]: () => this.selectFocusedItem(),
            [Keys.Enter]: () => this.selectFocusedItem(),
            [Keys.ArrowUp]: () => this.focusPreviousItem(),
            [this.invertKeys(Keys.ArrowLeft, Keys.ArrowRight)]: () => this.collapseItem(),
            [Keys.ArrowDown]: () => this.focusNextItem(),
            [this.invertKeys(Keys.ArrowRight, Keys.ArrowLeft)]: () => this.expandItem(),
            [Keys.End]: () => this.focusLastItem(),
            [Keys.Home]: () => this.focusFirstItem()
        };
    }
    ngOnDestroy() {
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }
    ngOnInit() {
        this.localizationChangeSubscription = this.localization
            .changes.subscribe(() => this.keyBindings = this.computedKeys);
        this.eventService.animate = this.animate;
        this.eventService.expandMode = this.expandMode;
    }
    ngAfterViewChecked() {
        if (this.items) {
            this.childrenItems = this.viewChildItems.toArray();
            this.allItems = this.viewItems;
        }
        else {
            this.childrenItems = this.contentChildItems.toArray();
            this.allItems = this.contentItems.toArray();
        }
        if (this.isViewInit && this.childrenItems.length) {
            this.isViewInit = false;
            setTimeout(() => this.updateChildrenHeight());
        }
        this.validateConfiguration();
    }
    ngOnChanges(changes) {
        if (changes['height'] || changes['expandMode'] || changes["items"]) { // tslint:disable-line
            if (this.childrenItems) {
                setTimeout(this.updateChildrenHeight);
            }
        }
        if (changes.animate) {
            this.eventService.animate = this.animate;
        }
        if (changes.expandMode) {
            this.eventService.expandMode = this.expandMode;
        }
    }
    get templateRef() {
        return this.template ? this.template.templateRef : undefined;
    }
    /**
     * @hidden
     */
    onComponentClick(event) {
        const itemClicked = this.visibleItems().some((item) => {
            return item.header.nativeElement.contains(event.target);
        });
        if (!isFocusable(event.target) && !this.focused && itemClicked) {
            this.elementRef.nativeElement.focus();
        }
    }
    /**
     * @hidden
     */
    onComponentFocus() {
        this.eventService.onFocus();
        this.focused = true;
        if (this.allItems.length > 0) {
            const visibleItems = this.visibleItems();
            const focusedItems = visibleItems.filter(item => item.focused);
            if (!focusedItems.length && visibleItems.length > 0) {
                visibleItems[0].focused = true;
                this.activeDescendant = visibleItems[0].itemId;
            }
        }
    }
    /**
     * @hidden
     */
    onComponentBlur() {
        this.eventService.onBlur();
        this.focused = false;
        this.activeDescendant = "";
    }
    /**
     * @hidden
     */
    onComponentKeyDown(event) {
        if (event.target === this.elementRef.nativeElement) {
            if (event.keyCode === Keys.Space || event.keyCode === Keys.ArrowUp || event.keyCode === Keys.ArrowDown ||
                event.keyCode === Keys.ArrowLeft || event.keyCode === Keys.ArrowRight || event.keyCode === Keys.Home ||
                event.keyCode === Keys.End || event.keyCode === Keys.PageUp || event.keyCode === Keys.PageDown) {
                event.preventDefault();
            }
            const handler = this.keyBindings[event.keyCode];
            //TODO: check if next item is disabled and skip operation?
            if (handler) {
                handler();
            }
        }
    }
    get viewItems() {
        let treeItems = [];
        this.viewChildItems.toArray().forEach(item => {
            treeItems.push(item);
            treeItems = treeItems.concat(item.subTreeViewItems());
        });
        return treeItems;
    }
    validateConfiguration() {
        if (isDevMode()) {
            if (this.items && (this.contentItems && this.contentItems.length > 0)) {
                throw new Error("Invalid configuration: mixed template components and items property.");
            }
        }
    }
    onItemAction(item) {
        if (!item) {
            return;
        }
        let modifiedItems = new Array();
        this.allItems
            .forEach((currentItem) => {
            let selectedState = currentItem === item;
            let focusedState = selectedState;
            selectedState = this.selectable ? selectedState : currentItem.selected;
            if (currentItem.selected !== selectedState || currentItem.focused !== focusedState) {
                currentItem.selected = selectedState;
                currentItem.focused = focusedState;
                this.activeDescendant = focusedState ? currentItem.itemId : "";
                modifiedItems.push(currentItem);
            }
        });
        if (this.expandMode === PanelBarExpandMode.Multiple) {
            if (item.hasChildItems || item.hasContent) {
                item.expanded = !item.expanded;
            }
            if (modifiedItems.indexOf(item) < 0) {
                modifiedItems.push(item);
            }
        }
        else {
            let siblings = item.parent ? item.parent.childrenItems : this.childrenItems;
            if (item.hasChildItems || item.hasContent) {
                siblings
                    .forEach((currentItem) => {
                    let expandedState = currentItem === item;
                    if (currentItem.expanded !== expandedState) {
                        currentItem.expanded = expandedState;
                        if (modifiedItems.indexOf(currentItem) < 0) {
                            modifiedItems.push(currentItem);
                        }
                    }
                });
            }
        }
        if (modifiedItems.length > 0) {
            this.stateChange.emit(modifiedItems.map(currentItem => currentItem.serialize()));
        }
    }
    isVisible(item) {
        const visibleItems = this.visibleItems();
        return visibleItems.some(i => i === item);
    }
    getVisibleParent(item) {
        const visibleItems = this.visibleItems();
        if (!item.parent) {
            return item;
        }
        return visibleItems.some(i => i === item.parent) ? item.parent : this.getVisibleParent(item.parent);
    }
    focusItem(action) {
        const visibleItems = this.visibleItems();
        let currentIndex = visibleItems.findIndex(item => item.focused);
        let currentItem = visibleItems[currentIndex];
        let nextItem;
        if (currentIndex === -1) {
            let focusedItem = this.allItems.find(item => item.focused);
            focusedItem.focused = false;
            currentItem = this.getVisibleParent(focusedItem);
            currentIndex = visibleItems.findIndex(item => item === currentItem);
        }
        switch (action) {
            case "lastItem":
                nextItem = visibleItems[visibleItems.length - 1];
                break;
            case "firstItem":
                nextItem = visibleItems[0];
                break;
            case "nextItem":
                nextItem = visibleItems[currentIndex < visibleItems.length - 1 ? currentIndex + 1 : 0];
                break;
            case "previousItem":
                nextItem = visibleItems[currentIndex > 0 ? currentIndex - 1 : visibleItems.length - 1];
                break;
            default:
        }
        if (currentItem && nextItem && currentItem !== nextItem) {
            this.moveFocus(currentItem, nextItem);
        }
    }
    moveFocus(from, to) {
        from.focused = false;
        to.focused = true;
        this.activeDescendant = to.itemId;
        const modifiedItems = new Array(from.serialize(), to.serialize());
        this.stateChange.emit(modifiedItems);
    }
    focusLastItem() {
        this.focusItem("lastItem");
    }
    focusFirstItem() {
        this.focusItem("firstItem");
    }
    focusNextItem() {
        this.focusItem("nextItem");
    }
    focusPreviousItem() {
        this.focusItem("previousItem");
    }
    expandItem() {
        let currentItem = this.allItems.filter(item => item.focused)[0];
        if (!this.isVisible(currentItem)) {
            currentItem.focused = false;
            currentItem = this.getVisibleParent(currentItem);
        }
        if (currentItem.hasChildItems || currentItem.hasContent) {
            if (!currentItem.expanded) {
                this.onItemAction(currentItem);
            }
            else if (currentItem.hasChildItems) {
                const firstChildIndex = currentItem.childrenItems.findIndex(item => !item.disabled);
                if (firstChildIndex > -1) {
                    this.moveFocus(currentItem, currentItem.childrenItems[firstChildIndex]);
                }
            }
        }
    }
    collapseItem() {
        const currentItem = this.allItems.filter(item => item.focused)[0];
        if (currentItem.expanded) {
            this.onItemAction(currentItem);
        }
        else if (currentItem.parent) {
            this.moveFocus(currentItem, currentItem.parent);
        }
    }
    selectFocusedItem() {
        let focusedItem = this.allItems.filter(item => item.focused)[0];
        if (!this.isVisible(focusedItem)) {
            focusedItem.focused = false;
            focusedItem = this.getVisibleParent(focusedItem);
        }
        if (focusedItem) {
            focusedItem.onItemAction();
        }
    }
    visibleItems() {
        return this.flatVisibleItems(this.childrenItems);
    }
    flatVisibleItems(listOfItems = new Array(), flattedItems = new Array()) {
        listOfItems.forEach(item => {
            flattedItems.push(item);
            if (item.expanded && item.hasChildItems) {
                this.flatVisibleItems(item.childrenItems, flattedItems);
            }
        });
        return flattedItems;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], PanelBarComponent.prototype, "expandMode", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PanelBarComponent.prototype, "selectable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PanelBarComponent.prototype, "animate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PanelBarComponent.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PanelBarComponent.prototype, "keepItemContent", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], PanelBarComponent.prototype, "items", null);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], PanelBarComponent.prototype, "stateChange", void 0);
__decorate([
    HostBinding('attr.tabIndex'),
    __metadata("design:type", Number)
], PanelBarComponent.prototype, "tabIndex", void 0);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], PanelBarComponent.prototype, "role", void 0);
__decorate([
    HostBinding('class.k-panelbar'),
    __metadata("design:type", Boolean)
], PanelBarComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('attr.aria-activedescendant'),
    __metadata("design:type", String)
], PanelBarComponent.prototype, "activeDescendant", void 0);
__decorate([
    HostBinding('style.height'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PanelBarComponent.prototype, "hostHeight", null);
__decorate([
    HostBinding('style.overflow'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PanelBarComponent.prototype, "overflow", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], PanelBarComponent.prototype, "dir", null);
__decorate([
    ContentChild(PanelBarItemTemplateDirective, { static: false }),
    __metadata("design:type", PanelBarItemTemplateDirective)
], PanelBarComponent.prototype, "template", void 0);
__decorate([
    ContentChildren(PanelBarItemComponent, { descendants: true }),
    __metadata("design:type", QueryList)
], PanelBarComponent.prototype, "contentItems", void 0);
__decorate([
    ContentChildren(PanelBarItemComponent),
    __metadata("design:type", QueryList)
], PanelBarComponent.prototype, "contentChildItems", void 0);
__decorate([
    ViewChildren(PanelBarItemComponent),
    __metadata("design:type", QueryList)
], PanelBarComponent.prototype, "viewChildItems", void 0);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PanelBarComponent.prototype, "onComponentClick", null);
__decorate([
    HostListener('focus'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PanelBarComponent.prototype, "onComponentFocus", null);
__decorate([
    HostListener('blur'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], PanelBarComponent.prototype, "onComponentBlur", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], PanelBarComponent.prototype, "onComponentKeyDown", null);
PanelBarComponent = __decorate([
    Component({
        exportAs: 'kendoPanelbar',
        providers: [
            PanelBarService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.panelbar'
            }
        ],
        selector: 'kendo-panelbar',
        template: `
        <ng-content *ngIf="contentChildItems && !items" select="kendo-panelbar-item"></ng-content>
        <ng-template [ngIf]="items?.length">
            <ng-container *ngFor="let item of items">
                <kendo-panelbar-item *ngIf="!item.hidden"
                     [title]="item.title"
                     [id]="item.id"
                     [icon]="item.icon"
                     [iconClass]="item.iconClass"
                     [imageUrl]="item.imageUrl"
                     [selected]="!!item.selected"
                     [expanded]="!!item.expanded"
                     [disabled]="!!item.disabled"
                     [template]="templateRef"
                     [items]="item.children"
                     [content]="item.content"
                >
                </kendo-panelbar-item>
            </ng-container>
        </ng-template>
    `
    })
    // TODO: add styles as input prop
    ,
    __metadata("design:paramtypes", [ElementRef,
        PanelBarService,
        LocalizationService])
], PanelBarComponent);

/**
 * Represents the pane component of the Splitter.
 */
let SplitterPaneComponent = class SplitterPaneComponent {
    constructor(element, renderer, cdr) {
        this.element = element;
        this.renderer = renderer;
        this.cdr = cdr;
        /**
         * Specifies if the user is allowed to resize the pane and provide space for other panes.
         */
        this.resizable = true;
        /**
         * Specifies if the user is allowed to hide the pane and provide space for other panes.
         */
        this.collapsible = false;
        /**
         * Specifies if overflowing content is scrollable or hidden.
         */
        this.scrollable = true;
        /**
         * Specifies if the pane is initially collapsed.
         */
        this.collapsed = false;
        /**
         * @hidden
         */
        this.orientation = 'horizontal';
        /**
         * @hidden
         */
        this.overlayContent = false;
        /**
         * Fires each time the user resizes the Splitter pane.
         * The event data contains the new pane size.
         * Allows a two-way binding of the pane `size` property.
         */
        this.sizeChange = new EventEmitter();
        /**
         * Fires each time the `collapsed` property changes.
         * The event data contains the new property state.
         * Allows a two-way binding of the `collapsed` pane property.
         */
        this.collapsedChange = new EventEmitter();
        this.hostClass = true;
        /**
         * @hidden
         */
        this.forceExpand = false;
        /**
         * @hidden
         */
        this.isResized = false;
    }
    /**
     * @hidden
     */
    set order(paneOrder) {
        this._order = paneOrder;
        this.setOrderStyles();
    }
    get order() {
        return this._order;
    }
    /**
     * Sets the initial size of the pane.
     * Has to be between the `min` and `max` properties.
     */
    set size(newSize) {
        this._size = newSize;
        const element = this.element.nativeElement;
        this.renderer.setStyle(element, '-ms-flex-preferred-size', newSize);
        this.renderer.setStyle(element, 'flex-basis', newSize);
        if (this.staticPaneClass) {
            this.renderer.addClass(element, 'k-pane-static');
        }
        else {
            this.renderer.removeClass(element, 'k-pane-static');
        }
    }
    get size() {
        return this._size;
    }
    /**
     * @hidden
     */
    set containsSplitter(value) {
        const element = this.element.nativeElement;
        if (value) {
            this.renderer.addClass(element, 'k-pane-flex');
        }
        else {
            this.renderer.removeClass(element, 'k-pane-flex');
        }
    }
    get isHidden() {
        return this.collapsed;
    }
    get staticPaneClass() {
        if (this.forceExpand) {
            return false;
        }
        return !this.resizable && !this.collapsible || this.fixedSize;
    }
    get scrollablePaneClass() {
        return this.scrollable;
    }
    get fixedSize() {
        return this.size && this.size.length > 0;
    }
    ngAfterViewChecked() {
        const element = this.element.nativeElement;
        if (this.isHidden) {
            this.renderer.addClass(element, 'k-state-hidden');
            this.renderer.addClass(element, 'hidden');
        }
        else {
            this.renderer.removeClass(element, 'k-state-hidden');
            this.renderer.removeClass(element, 'hidden');
        }
    }
    /**
     * @hidden
     */
    get computedSize() {
        if (this.orientation === 'vertical') {
            return this.element.nativeElement.offsetHeight;
        }
        else {
            return this.element.nativeElement.offsetWidth;
        }
    }
    /**
     * @hidden
     */
    toggleOverlay(show) {
        this.overlayContent = show;
        this.cdr.detectChanges();
    }
    /**
     * @hidden
     */
    detectChanges() {
        this.cdr.detectChanges();
    }
    setOrderStyles() {
        const element = this.element.nativeElement;
        this.renderer.setStyle(element, '-ms-flex-order', this.order);
        this.renderer.setStyle(element, 'order', this.order);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], SplitterPaneComponent.prototype, "order", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], SplitterPaneComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitterPaneComponent.prototype, "min", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitterPaneComponent.prototype, "max", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "resizable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "collapsible", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "scrollable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "collapsed", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitterPaneComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], SplitterPaneComponent.prototype, "containsSplitter", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "overlayContent", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitterPaneComponent.prototype, "sizeChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitterPaneComponent.prototype, "collapsedChange", void 0);
__decorate([
    HostBinding('class.k-pane'),
    __metadata("design:type", Boolean)
], SplitterPaneComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-pane-static'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitterPaneComponent.prototype, "staticPaneClass", null);
__decorate([
    HostBinding('class.k-scrollable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitterPaneComponent.prototype, "scrollablePaneClass", null);
SplitterPaneComponent = __decorate([
    Component({
        exportAs: 'kendoSplitterPane',
        selector: 'kendo-splitter-pane',
        template: `
        <ng-container *ngIf="!collapsed"><ng-content></ng-content></ng-container>
        <div *ngIf="overlayContent" class="k-splitter-overlay k-overlay"></div>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        ChangeDetectorRef])
], SplitterPaneComponent);

const SIZING_DOC_LINK = 'https://www.telerik.com/kendo-angular-ui/components/layout/splitter/panes/#toc-size';
/**
 * @hidden
 */
let SplitterService = class SplitterService {
    constructor(zone) {
        this.zone = zone;
        this.layoutChange = new EventEmitter();
        this.containerSize = () => { };
    }
    tryToggle(paneIndex) {
        const pane = this.pane(paneIndex);
        if (pane.collapsible) {
            pane.collapsed = !pane.collapsed;
            pane.collapsedChange.emit(pane.collapsed);
            this.emit(this.layoutChange, {});
            if (pane.collapsed) {
                pane.detectChanges();
            }
        }
        const notCollapsed = this.panes.filter(p => !p.collapsed);
        const allHaveFixedSize = notCollapsed.every(p => p.fixedSize);
        notCollapsed[notCollapsed.length - 1].forceExpand = allHaveFixedSize ? true : false;
        return pane.collapsible;
    }
    toggleContentOverlay(index, show) {
        this.pane(index).toggleOverlay(show);
        this.pane(index + 1).toggleOverlay(show);
    }
    dragState(splitbarIndex) {
        let prev = this.pane(splitbarIndex);
        let next = this.pane(splitbarIndex + 1);
        const total = prev.computedSize + next.computedSize;
        const px = s => this.toPixels(s);
        return {
            prev: {
                index: splitbarIndex,
                initialSize: prev.computedSize,
                min: px(prev.min) || total - px(next.max) || 0,
                max: px(prev.max) || total - px(next.min) || total
            },
            next: {
                index: splitbarIndex + 1,
                initialSize: next.computedSize,
                min: px(next.min) || total - px(prev.max) || 0,
                max: px(next.max) || total - px(prev.min) || total
            }
        };
    }
    setSize(state$$1, delta) {
        const clamp = (min, max, v) => Math.min(max, Math.max(min, v));
        const resize = (paneState, change) => {
            const pane = this.pane(paneState.index);
            const splitterSize = this.containerSize();
            const newSize = clamp(paneState.min, paneState.max, paneState.initialSize + change);
            let size = "";
            if (this.isPercent(pane.size)) {
                size = (100 * newSize / splitterSize) + "%";
            }
            else {
                size = newSize + "px";
            }
            pane.size = size;
            pane.isResized = true;
            this.emit(pane.sizeChange, size);
        };
        const prev = this.pane(state$$1.prev.index);
        const next = this.pane(state$$1.next.index);
        // determine which pane to resize
        if (prev.fixedSize && next.fixedSize) {
            // resizing both panes
            resize(state$$1.prev, delta);
            resize(state$$1.next, -delta);
        }
        else if (next.collapsible || next.fixedSize) {
            // resizing next
            resize(state$$1.next, -delta);
        }
        else {
            // resizing prev
            resize(state$$1.prev, delta);
        }
        this.emit(this.layoutChange, {});
    }
    isDraggable(splitBarIndex) {
        const prev = this.pane(splitBarIndex);
        const next = this.pane(splitBarIndex + 1);
        const betweenResizablePanes = prev.resizable && next.resizable;
        const nearCollapsedPane = prev.collapsed || next.collapsed;
        return betweenResizablePanes && !nearCollapsedPane;
    }
    isStatic(splitBarIndex) {
        const prev = this.pane(splitBarIndex);
        const next = this.pane(splitBarIndex + 1);
        const betweenResizablePanes = prev.resizable && next.resizable;
        const nearCollapsiblePane = prev.collapsible || next.collapsible;
        return !betweenResizablePanes && !nearCollapsiblePane;
    }
    pane(index) {
        if (!this.panes) {
            throw new Error("Panes not initialized");
        }
        if (index < 0 || index >= this.panes.length) {
            throw new Error("Index out of range");
        }
        return this.panes[index];
    }
    configure({ panes, orientation, containerSize }) {
        this.panes = panes;
        this.panes.forEach((pane, index) => {
            pane.order = index * 2;
            pane.orientation = orientation;
        });
        if (isDevMode()) {
            const allPanesWithSize = panes.length && !panes.some(pane => !pane.fixedSize);
            const hasResizedPane = panes.length && panes.some(pane => pane.isResized);
            if (allPanesWithSize && !hasResizedPane) {
                throw new Error(`
                    The Splitter should have at least one pane without a set size.
                    See ${SIZING_DOC_LINK} for more information.
                `);
            }
        }
        this.containerSize = containerSize;
    }
    isPercent(size) {
        return /%$/.test(size);
    }
    toPixels(size) {
        let result = parseFloat(size);
        if (this.isPercent(size)) {
            result = (this.containerSize() * result / 100);
        }
        return result;
    }
    emit(emitter, args) {
        if (emitter.observers.length) {
            this.zone.run(() => emitter.emit(args));
        }
    }
};
SplitterService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgZone])
], SplitterService);

const stopPropagation = ({ originalEvent: event }) => {
    event.stopPropagation();
    event.preventDefault();
};
const preventOnDblClick = release => mouseDown => of(mouseDown).pipe(delay(150), takeUntil(release));
const classFromObject = classes => Object.keys(classes).filter(c => classes[c]).join(' ');
const createMoveStream = (draggable) => mouseDown => draggable.kendoDrag
    .pipe(takeUntil(draggable.kendoRelease), map(({ pageX, pageY }) => ({
    originalX: mouseDown.pageX,
    originalY: mouseDown.pageY,
    pageX,
    pageY
})));
/**
 * @hidden
 */
let SplitterBarComponent = class SplitterBarComponent {
    constructor(draggable, element, splitter, localization) {
        this.draggable = draggable;
        this.element = element;
        this.splitter = splitter;
        this.localization = localization;
        this.orientation = 'horizontal';
        this.index = 0;
        this.ariaRole = 'separator';
        this.focused = false;
        this.subscriptions = new Subscription();
    }
    get direction() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
    get tabIndex() {
        return this.splitter.isStatic(this.index) ? -1 : 0;
    }
    get hostClasses() {
        const isHorizontal = this.orientation === 'horizontal';
        const isDraggable = this.splitter.isDraggable(this.index);
        const isStatic = this.splitter.isStatic(this.index);
        return classFromObject({
            'k-state-focused': this.focused,
            'k-splitbar': true,
            'k-splitbar-horizontal': isHorizontal,
            'k-splitbar-vertical': !isHorizontal,
            'k-splitbar-draggable-horizontal': isHorizontal && isDraggable,
            'k-splitbar-draggable-vertical': !isHorizontal && isDraggable,
            'k-splitbar-static-horizontal': isHorizontal && isStatic,
            'k-splitbar-static-vertical': !isHorizontal && isStatic,
            'k-touch-action-none': isDraggable
        });
    }
    get order() {
        return 2 * this.index + 1;
    }
    collapseAny() {
        if (this.expandLast) {
            this.toggleNext();
        }
        else {
            this.tryToggleNearest();
        }
    }
    onFocusIn() {
        this.focused = true;
    }
    onFocusOut() {
        this.focused = false;
    }
    onKeyDown(event) {
        const keyCode = event && event.keyCode;
        const isHorizontal = this.orientation === 'horizontal';
        const resize = delta => {
            event.preventDefault();
            const state$$1 = this.splitter.dragState(this.index);
            this.splitter.setSize(state$$1, delta);
        };
        if (keyCode === Keys.Enter) {
            event.preventDefault();
            this.collapseAny();
        }
        else if (isHorizontal && keyCode === Keys.ArrowLeft) {
            resize(-10);
        }
        else if (isHorizontal && keyCode === Keys.ArrowRight) {
            resize(10);
        }
        else if (!isHorizontal && keyCode === Keys.ArrowUp) {
            resize(-10);
        }
        else if (!isHorizontal && keyCode === Keys.ArrowDown) {
            resize(10);
        }
    }
    get expandLast() {
        const panes = this.splitter.panes;
        return panes.length === 2 && panes[1].collapsed;
    }
    ngOnInit() {
        let state$$1;
        const listener = this.draggable.kendoPress.pipe(tap(stopPropagation), filter(() => this.splitter.isDraggable(this.index)), tap(() => state$$1 = this.splitter.dragState(this.index)), tap(() => this.splitter.toggleContentOverlay(this.index, true)), switchMap(preventOnDblClick(this.draggable.kendoRelease)), switchMap(createMoveStream(this.draggable))).subscribe(({ pageX, pageY, originalX, originalY }) => {
            let delta;
            if (this.orientation === 'vertical') {
                delta = pageY - originalY;
            }
            else if (this.direction === 'rtl') {
                delta = originalX - pageX;
            }
            else {
                delta = pageX - originalX;
            }
            this.splitter.setSize(state$$1, delta);
        });
        this.subscriptions.add(listener);
        this.subscriptions.add(this.draggable.kendoRelease.subscribe(() => this.splitter.toggleContentOverlay(this.index, false)));
    }
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
    togglePrevious() {
        this.splitter.tryToggle(this.index);
    }
    toggleNext() {
        this.splitter.tryToggle(this.index + 1);
    }
    previousArrowClass() {
        const pane = this.splitter.pane(this.index);
        const nextPane = this.splitter.pane(this.index + 1);
        const isCollapsible = pane.collapsible;
        const isCollapsed = pane.collapsed;
        const isHorizontal = this.orientation === 'horizontal';
        return classFromObject({
            'k-icon': true,
            'k-hidden': !isCollapsible || nextPane.isHidden,
            'k-collapse-prev': isCollapsible,
            'k-i-arrow-60-left': isCollapsible && isHorizontal && !isCollapsed,
            'k-i-arrow-60-right': isCollapsible && isHorizontal && isCollapsed,
            'k-i-arrow-60-up': isCollapsible && !isHorizontal && !isCollapsed,
            'k-i-arrow-60-down': isCollapsible && !isHorizontal && isCollapsed
        });
    }
    nextArrowClass() {
        const pane = this.splitter.pane(this.index + 1);
        const prevPane = this.splitter.pane(this.index);
        const isCollapsible = pane.collapsible;
        const isCollapsed = pane.collapsed;
        const isHorizontal = this.orientation === 'horizontal';
        return classFromObject({
            'k-icon': true,
            'k-hidden': !isCollapsible || prevPane.isHidden,
            'k-collapse-next': isCollapsible,
            'k-i-arrow-60-right': isCollapsible && isHorizontal && !isCollapsed,
            'k-i-arrow-60-left': isCollapsible && isHorizontal && isCollapsed,
            'k-i-arrow-60-down': isCollapsible && !isHorizontal && !isCollapsed,
            'k-i-arrow-60-up': isCollapsible && !isHorizontal && isCollapsed
        });
    }
    tryToggleNearest() {
        const prev = this.index;
        const next = this.index + 1;
        if (!this.splitter.tryToggle(prev)) {
            this.splitter.tryToggle(next);
        }
    }
};
__decorate([
    Input(),
    HostBinding('attr.aria-orientation'),
    __metadata("design:type", String)
], SplitterBarComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], SplitterBarComponent.prototype, "index", void 0);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], SplitterBarComponent.prototype, "ariaRole", void 0);
__decorate([
    HostBinding('class.k-state-focused'),
    __metadata("design:type", Boolean)
], SplitterBarComponent.prototype, "focused", void 0);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], SplitterBarComponent.prototype, "tabIndex", null);
__decorate([
    HostBinding('class'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], SplitterBarComponent.prototype, "hostClasses", null);
__decorate([
    HostBinding('style.-ms-flex-order'),
    HostBinding('style.order'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], SplitterBarComponent.prototype, "order", null);
__decorate([
    HostListener('dblclick'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SplitterBarComponent.prototype, "collapseAny", null);
__decorate([
    HostListener('focusin'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SplitterBarComponent.prototype, "onFocusIn", null);
__decorate([
    HostListener('focusout'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SplitterBarComponent.prototype, "onFocusOut", null);
__decorate([
    HostListener('keydown', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SplitterBarComponent.prototype, "onKeyDown", null);
SplitterBarComponent = __decorate([
    Component({
        selector: 'kendo-splitter-bar',
        template: `
      <div [class]="previousArrowClass()" (click)="togglePrevious()"></div>
      <div class="k-resize-handle"></div>
      <div [class]="nextArrowClass()" (click)="toggleNext()"></div>
    `
    }),
    __param(0, Host()),
    __metadata("design:paramtypes", [DraggableDirective,
        ElementRef,
        SplitterService,
        LocalizationService])
], SplitterBarComponent);

/**
 * Represents the [Kendo UI Splitter component for Angular]({% slug overview_splitter %}).
 *
 * ```ts-preview
 *
 *  @Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-splitter style="height: 280px;">
 *
 *          <kendo-splitter-pane [collapsible]="true" size="30%">
 *            <h3>Inner splitter / left pane</h3>
 *            <p>Resizable and collapsible.</p>
 *          </kendo-splitter-pane>
 *
 *          <kendo-splitter-pane>
 *            <h3>Inner splitter / center pane</h3>
 *            <p>Resizable only.</p>
 *          </kendo-splitter-pane>
 *
 *          <kendo-splitter-pane [collapsible]="true" size="30%">
 *            <h3>Inner splitter / right pane</h3>
 *            <p>Resizable and collapsible.</p>
 *          </kendo-splitter-pane>
 *
 *        </kendo-splitter>
 *      `,
 *    styles: [ `
 *        h3 { font-size: 1.2em; }
 *        h3, p { margin: 10px; padding: 0; }
 *    ` ]
 *  })
 *  class AppComponent {}
 * ```
 */
let SplitterComponent = class SplitterComponent {
    constructor(element, splitterService, localization, renderer, enclosingPane) {
        this.element = element;
        this.splitterService = splitterService;
        this.localization = localization;
        this.renderer = renderer;
        this.enclosingPane = enclosingPane;
        /**
         * Specifies the orientation of the panes within the Splitter.
         * Panes in a horizontal Splitter are placed horizontally.
         * Panes in a vertical Splitter are placed vertically.
         */
        this.orientation = 'horizontal';
        this.ariaRole = 'splitter';
        validatePackage(packageMetadata);
        if (enclosingPane) {
            enclosingPane.containsSplitter = true;
        }
        // the handler only runs in NgZone if there are bound handlers
        // this line merges both streams
        this.layoutChange = this.splitterService.layoutChange;
        this.configure = this.configure.bind(this);
    }
    get hostClasses() {
        return true;
    }
    get horizontalHostClasses() {
        return this.orientation === 'horizontal';
    }
    get verticalHostClasses() {
        return this.orientation === 'vertical';
    }
    get dir() {
        return this.direction;
    }
    set splitbars(splitbars) {
        if (!isPresent(splitbars) || !isPresent(this.panes)) {
            return;
        }
        const components = [...this.panes.toArray(), ...splitbars.toArray()]
            .sort((a, b) => a.order - b.order);
        const elements = components.map(component => component.element.nativeElement);
        elements.forEach(element => this.renderer.appendChild(this.element.nativeElement, element));
    }
    ngAfterContentInit() {
        this.reconfigure();
    }
    ngOnChanges(changes) {
        if (changes.orientation && !changes.orientation.isFirstChange()) {
            this.reconfigure();
        }
    }
    ngOnDestroy() {
        if (this.enclosingPane) {
            this.enclosingPane.containsSplitter = false;
        }
        this.unsubscribeChanges();
    }
    reconfigure() {
        this.unsubscribeChanges();
        this.configure();
        this.paneChangesSubscription = this.panes.changes.subscribe(this.configure);
    }
    unsubscribeChanges() {
        if (this.paneChangesSubscription) {
            this.paneChangesSubscription.unsubscribe();
            this.paneChangesSubscription = null;
        }
    }
    configure() {
        this.splitterService.configure({
            panes: this.panes.toArray(),
            orientation: this.orientation,
            containerSize: () => {
                if (this.orientation === 'vertical') {
                    return this.element.nativeElement.clientHeight;
                }
                else {
                    return this.element.nativeElement.clientWidth;
                }
            }
        });
    }
    get direction() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SplitterComponent.prototype, "orientation", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], SplitterComponent.prototype, "layoutChange", void 0);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-splitter'),
    HostBinding('class.k-splitter-flex'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitterComponent.prototype, "hostClasses", null);
__decorate([
    HostBinding('class.k-splitter-horizontal'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitterComponent.prototype, "horizontalHostClasses", null);
__decorate([
    HostBinding('class.k-splitter-vertical'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], SplitterComponent.prototype, "verticalHostClasses", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], SplitterComponent.prototype, "dir", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], SplitterComponent.prototype, "ariaRole", void 0);
__decorate([
    ViewChildren(SplitterBarComponent),
    __metadata("design:type", QueryList),
    __metadata("design:paramtypes", [QueryList])
], SplitterComponent.prototype, "splitbars", null);
__decorate([
    ContentChildren(SplitterPaneComponent),
    __metadata("design:type", QueryList)
], SplitterComponent.prototype, "panes", void 0);
SplitterComponent = __decorate([
    Component({
        exportAs: 'kendoSplitter',
        selector: 'kendo-splitter',
        providers: [
            SplitterService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.spliter'
            }
        ],
        template: `
      <ng-content select="kendo-splitter-pane"></ng-content>
      <ng-container *ngFor="
        let pane of panes;
        let index = index;
        let last = last;
      ">
        <kendo-splitter-bar
          kendoDraggable
          *ngIf="!last"
          [index]="index"
          [orientation]="orientation">
        </kendo-splitter-bar>
      </ng-container>
    `
    }),
    __param(4, Optional()), __param(4, Host()), __param(4, Inject(SplitterPaneComponent)),
    __metadata("design:paramtypes", [ElementRef,
        SplitterService,
        LocalizationService,
        Renderer2,
        SplitterPaneComponent])
], SplitterComponent);

/**
 * Represents the content template of the Kendo UI TabStrip.
 * To define the template, nest a `<ng-template>` tag with the `kendoTabContent` inside the component tag.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-tabstrip [ngStyle]="{'width': '400px'}" [animate]="true">
 *           <kendo-tabstrip-tab title="Paris" [selected]="true">
 *             <ng-template kendoTabContent>
 *               <h3>Content 1</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *
 *           <kendo-tabstrip-tab title="Sofia">
 *             <ng-template kendoTabContent>
 *               <h3>Content 2</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *         </kendo-tabstrip>
 *     `
 * })
 *
 * class AppComponent {}
 *
 * ```
 */
let TabContentDirective = class TabContentDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
TabContentDirective = __decorate([
    Directive({
        selector: '[kendoTabContent]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], TabContentDirective);

/**
 * Represents the title template of the Kendo UI TabStrip.
 * To define the template, nest a `<ng-template>` tag with the `kendoTabTitle` directive inside the component tag.
 *
 * > The `kendoTabTitle` directive overrides the TabStripTab [title]({% slug api_layout_tabstriptabcomponent %}#toc-title) option.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-tabstrip>
 *           <kendo-tabstrip-tab [selected]="true">
 *             <ng-template kendoTabTitle>
 *               <h4>Custom Title</h4>
 *             </ng-template>
 *             <ng-template kendoTabContent>
 *               <h3>Content 1</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *
 *           <kendo-tabstrip-tab title="Sofia">
 *             <ng-template kendoTabContent>
 *               <h3>Content 2</h3>
 *             </ng-template>
 *           </kendo-tabstrip-tab>
 *         </kendo-tabstrip>
 *     `
 * })
 *
 * class AppComponent {}
 *
 * ```
 */
let TabTitleDirective = class TabTitleDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
TabTitleDirective = __decorate([
    Directive({
        selector: '[kendoTabTitle]'
    }),
    __metadata("design:paramtypes", [TemplateRef])
], TabTitleDirective);

/**
 * Represents the tab component of the TabStrip.
 */
let TabStripTabComponent = class TabStripTabComponent {
    /**
     * Represents the tab component of the TabStrip.
     */
    constructor() {
        /**
         * Used to disable a tab ([see example]({% slug tabs_tabstrip %}#toc-disabled-tabs)).
         *
         * Defaults to `false`.
         */
        this.disabled = false;
        this._tabContent = new QueryList();
        /**
         * @hidden
         *
         * Currently only disabled tabs can be focused. Otherwise they will be
         * immediately selected
         */
        this.focused = false;
    }
    get tabContent() {
        return this._tabContent.first;
    }
    get tabTitle() {
        return this._tabTitleDirective.first;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripTabComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripTabComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TabStripTabComponent.prototype, "cssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TabStripTabComponent.prototype, "cssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripTabComponent.prototype, "selected", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripTabComponent.prototype, "closable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripTabComponent.prototype, "closeIcon", void 0);
__decorate([
    ContentChildren(TabContentDirective),
    __metadata("design:type", QueryList)
], TabStripTabComponent.prototype, "_tabContent", void 0);
__decorate([
    ContentChildren(TabTitleDirective),
    __metadata("design:type", QueryList)
], TabStripTabComponent.prototype, "_tabTitleDirective", void 0);
TabStripTabComponent = __decorate([
    Component({
        exportAs: 'kendoTabStripTab',
        selector: 'kendo-tabstrip-tab',
        template: ``
    })
], TabStripTabComponent);

/**
 * Arguments for the `tabClose` event of the TabStrip.
 * The `tabClose` event fires when the close button of the tab is clicked.
 *
 */
class TabCloseEvent {
    /**
     * Constructs the event arguments for the `tabClose` event.
     * @param index - The index of the closed tab.
     * @param tab - The closed tab instance
     */
    constructor(index, tab) {
        this.index = index;
        this.tab = tab;
    }
}

/**
 * Arguments for the `select` event of the TabStrip.
 * The `select` event fires when a tab is selected (clicked).
 */
class SelectEvent extends PreventableEvent {
    /**
     * Constructs the event arguments for the `select` event.
     * @param index - The index of the selected tab.
     * @param title - The title of the selected tab.
     */
    constructor(index, title) {
        super();
        this.index = index;
        this.title = title;
    }
}

/**
 * @hidden
 *
 * Checks if the current target is a TabStrip tab element
 */
const isTabElement = (target) => {
    const targetId = target.getAttribute('id');
    if (isPresent(targetId) || targetId.indexOf('k-tabstrip-tab-') >= 0) {
        return true;
    }
    return false;
};
/**
 * @hidden
 *
 * Checks if the current tab is closable. Depends on the value of the TabStrip closable property.
 */
const isTabClosable = (tab, tabStripClosable) => {
    if (tab.closable !== undefined) {
        return tab.closable;
    }
    return tabStripClosable;
};
/**
 * @hidden
 *
 * Checks if the TabStrip scroll buttons will be rendered. Depends on the value of the TabStrip scrollable settings.
 */
const tabStripHasScrollButtons = (scrollableSettings) => {
    return scrollableSettings.enabled && scrollableSettings.scrollButtons !== 'hidden';
};
/**
 * @hidden
 *
 * Checks if the TabStrip mouse scroll will be enabled. Depends on the value of the TabStrip scrollable settings.
 */
const mouseScrollEnabled = (scrollableSettings) => {
    return scrollableSettings.enabled && scrollableSettings.mouseScroll;
};
/**
 * @hidden
 *
 * Retrieves the current active tab element and its index.
 * This could either be the currently selected tab or the currently focused tab.
 */
const getActiveTab = (tabs) => {
    let focusedTab;
    let selectedTab;
    let focusedIndex = -1;
    let selectedIndex = -1;
    tabs.forEach((tab, index) => {
        if (tab.selected) {
            selectedTab = tab;
            selectedIndex = index;
        }
        else if (tab.focused) {
            focusedTab = tab;
            focusedIndex = index;
        }
    });
    return focusedIndex >= 0 ?
        { tab: focusedTab, index: focusedIndex } :
        { tab: selectedTab, index: selectedIndex };
};
/**
 * @hidden
 */
const getTabByIndex = (tabs, index) => {
    const filtered = tabs.filter((_tab, i) => i === index);
    if (filtered.length > 0) {
        return filtered[0];
    }
    return null;
};
/**
 * @hidden
 */
const getTabHeaderByIndex = (tabHeaderContainers, index) => {
    const filtered = tabHeaderContainers.filter((_tabHeader, i) => i === index);
    if (filtered.length > 0) {
        return filtered[0];
    }
    return null;
};
/**
 * @hidden
 */
const resetTabFocus = (tabs) => {
    tabs.forEach((tab) => {
        tab.focused = false;
    });
};
/**
 * @hidden
 */
const resetTabSelection = (tabs) => {
    tabs.forEach((tab) => {
        tab.selected = false;
    });
};
/**
 * @hidden
 */
const isTablistHorizontal = (tabPosition) => tabPosition === 'top' || tabPosition === 'bottom';

/**
 * @hidden
 */
let TabStripService = class TabStripService {
    constructor(localization, ngZone) {
        this.localization = localization;
        this.ngZone = ngZone;
    }
    onKeyDown(event) {
        if (!isTabElement(event.target)) {
            return;
        }
        const key = event.keyCode;
        if (this.shouldHandleKey(key)) {
            event.preventDefault();
        }
        else {
            return;
        }
        if (isNavigationKey(key) || isArrowKey(key)) {
            this.onNavigate(key);
        }
        else if (key === Keys.Delete) {
            this.onDelete();
        }
    }
    onTabSelect(tab, index) {
        const selectArgs = new SelectEvent(index, tab.title);
        this.owner.tabSelect.emit(selectArgs);
        if (!selectArgs.isDefaultPrevented()) {
            if (tab.selected) {
                this.focusTabHeader(index);
                return;
            }
            this.selectTab(tab, index);
        }
    }
    selectTab(tab, index) {
        resetTabSelection(this.owner.tabs);
        this.focusTabHeader(index);
        tab.selected = true;
        if (this.owner.isScrollable) {
            this.owner.scrollToSelectedTab();
        }
    }
    onTabClose(tab, index) {
        const closeArgs = new TabCloseEvent(index, tab);
        this.owner.tabClose.emit(closeArgs);
    }
    onNavigate(keyCode) {
        let { tab: activeTab, index: activeIndex } = getActiveTab(this.owner.tabs);
        if (!NgZone.isInAngularZone()) {
            this.ngZone.run(() => {
                if (activeIndex < 0) {
                    this.owner.selectTab(this.firstNavigatableIndex());
                    return;
                }
                activeTab.focused = false;
                const nextIndex = this.computeNextIndex(activeIndex, keyCode);
                this.activateTab(nextIndex);
            });
        }
    }
    onDelete() {
        let { tab: activeTab, index: activeTabIndex } = getActiveTab(this.owner.tabs);
        if (isTabClosable(activeTab, this.owner.closable) && !activeTab.disabled) {
            this.ngZone.run(() => {
                this.onTabClose(activeTab, activeTabIndex);
            });
        }
    }
    activateTab(index) {
        const tab = getTabByIndex(this.owner.tabs, index);
        if (tab.disabled) {
            this.focusTabHeader(index);
            tab.focused = true;
        }
        else {
            this.onTabSelect(tab, index);
        }
    }
    focusTabHeader(index) {
        const tabHeader = getTabHeaderByIndex(this.owner.tabHeaderContainers, index);
        tabHeader.nativeElement.focus();
    }
    shouldHandleKey(keyCode) {
        if (isNavigationKey(keyCode)) {
            return true;
        }
        if (isTablistHorizontal(this.owner.tabPosition) && isHorizontalArrowKey(keyCode)) {
            return true;
        }
        if (!isTablistHorizontal(this.owner.tabPosition) && isVerticalArrowKey(keyCode)) {
            return true;
        }
        if (keyCode === Keys.Delete) {
            return true;
        }
        return false;
    }
    computeNextIndex(activeIndex, keyCode) {
        switch (keyCode) {
            case this.invertKeys(Keys.ArrowLeft, Keys.ArrowRight):
            case this.invertKeys(Keys.ArrowUp, Keys.ArrowDown):
                return this.prevNavigatableIndex(activeIndex);
            case this.invertKeys(Keys.ArrowRight, Keys.ArrowLeft):
            case this.invertKeys(Keys.ArrowDown, Keys.ArrowUp):
                return this.nextNavigatableIndex(activeIndex);
            case Keys.Home:
                return this.firstNavigatableIndex();
            case Keys.End:
                return this.lastNavigatableIndex();
            default:
                return;
        }
    }
    invertKeys(original, inverted) {
        return this.localization.rtl ? inverted : original;
    }
    firstNavigatableIndex() {
        return 0;
    }
    lastNavigatableIndex() {
        return this.owner.tabs.length - 1;
    }
    prevNavigatableIndex(selectedIndex) {
        if (selectedIndex - 1 < 0) {
            return this.lastNavigatableIndex();
        }
        return selectedIndex - 1;
    }
    nextNavigatableIndex(selectedIndex) {
        if (selectedIndex + 1 >= this.owner.tabs.length) {
            return this.firstNavigatableIndex();
        }
        return selectedIndex + 1;
    }
};
TabStripService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [LocalizationService,
        NgZone])
], TabStripService);

/**
 * @hidden
 */
const MOUSE_SCROLL_SPEED = 10;
/**
 * @hidden
 */
const BUTTON_SCROLL_SPEED = 100;
/**
 * @hidden
 */
const HIDDEN_CLASS = 'k-hidden';
/**
 * @hidden
 */
const DIRECTION_CLASSES = {
    left: 'k-i-arrow-60-left',
    right: 'k-i-arrow-60-right',
    up: 'k-i-arrow-60-up',
    down: 'k-i-arrow-60-down'
};
/**
 * @hidden
 */
const DEFAULT_SCROLL_BEHAVIOR = 'smooth';

/**
 * @hidden
 */
class PreventableEvent$1 {
    /**
     * @hidden
     */
    constructor(args) {
        this.prevented = false;
        Object.assign(this, args);
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses
     * the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * Returns `true` if the event was prevented
     * by any of its subscribers.
     *
     * @returns `true` if the default action was prevented.
     * Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Arguments for the `tabScroll` event of the TabStrip.
 * The `tabScroll` event fires when the tabs are being scrolled.
 *
 */
class TabScrollEvent extends PreventableEvent$1 {
}

/**
 * @hidden
 */
let ScrollService = class ScrollService {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.position = 0;
        this.scrollButtonActiveStateChange = new Subject();
    }
    get tablistElement() {
        return this.owner.tablist.nativeElement;
    }
    get scrollButtonIconClass() {
        const tabStrip = this.owner;
        const defaultPrevIcon = isTablistHorizontal(tabStrip.tabPosition) ? DIRECTION_CLASSES.left : DIRECTION_CLASSES.up;
        const defaultNextIcon = isTablistHorizontal(tabStrip.tabPosition) ? DIRECTION_CLASSES.right : DIRECTION_CLASSES.down;
        if (typeof tabStrip.scrollable === 'object') {
            const prevIcon = typeof tabStrip.scrollable.prevButtonIcon === 'undefined' ? defaultPrevIcon : tabStrip.scrollable.prevButtonIcon;
            const nextIcon = typeof tabStrip.scrollable.nextButtonIcon === 'undefined' ? defaultNextIcon : tabStrip.scrollable.nextButtonIcon;
            return { prevScrollButton: prevIcon, nextScrollButton: nextIcon };
        }
        return { prevScrollButton: defaultPrevIcon, nextScrollButton: defaultNextIcon };
    }
    get tabstripSize() {
        const hostElement = this.owner.wrapper.nativeElement;
        const wrapperWidth = parseFloat(getComputedStyle(hostElement).width);
        const wrapperHeight = parseFloat(getComputedStyle(hostElement).height);
        return isTablistHorizontal(this.owner.tabPosition) ? wrapperWidth : wrapperHeight;
    }
    get tablistOverflowSize() {
        if (!isDocumentAvailable()) {
            return 0;
        }
        const isHorizontal = isTablistHorizontal(this.owner.tabPosition);
        const overflowSize = Math.floor(this.tablistElement[isHorizontal ? 'scrollWidth' : 'scrollHeight']
            - this.tablistElement.getBoundingClientRect()[isHorizontal ? 'width' : 'height']);
        return overflowSize < 0 ? 0 : overflowSize;
    }
    get tabsOverflow() {
        return this.tablistOverflowSize > 0;
    }
    get scrollButtonsSize() {
        if (!this.owner.hasScrollButtons) {
            return 0;
        }
        const prevRect = this.owner.prevScrollButton.hostBoundingClientRect;
        const prevSize = isTablistHorizontal(this.owner.tabPosition) ? prevRect.width : prevRect.height;
        const nextRect = this.owner.nextScrollButton.hostBoundingClientRect;
        const nextSize = isTablistHorizontal(this.owner.tabPosition) ? nextRect.width : nextRect.height;
        return prevSize + nextSize;
    }
    toggleScrollButtonsState() {
        const tabStrip = this.owner;
        if (!tabStrip.hasScrollButtons) {
            return;
        }
        const currentPrevButtonActive = !tabStrip.prevScrollButton.disabled;
        const currentNextButtonActive = !tabStrip.nextScrollButton.disabled;
        const calculatedPrevButtonActive = this.position > 0 && this.tablistOverflowSize > 0;
        const calculatedNextButtonActive = this.position < this.tablistOverflowSize + this.scrollButtonsSize && this.tablistOverflowSize > 0;
        if (calculatedPrevButtonActive !== currentPrevButtonActive) {
            this.ngZone.run(() => this.toggleButtonActiveState('prev', calculatedPrevButtonActive));
        }
        if (calculatedNextButtonActive !== currentNextButtonActive) {
            this.ngZone.run(() => this.toggleButtonActiveState('next', calculatedNextButtonActive));
        }
    }
    scrollToSelectedTab() {
        if (!this.tabsOverflow) {
            return;
        }
        let { index: activeIndex } = getActiveTab(this.owner.tabs);
        if (activeIndex === -1) {
            return;
        }
        this.position += this.getScrollOffset(activeIndex);
        if (isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollLeft = this.position;
        }
        else {
            this.tablistElement.scrollTop = this.position;
        }
        this.toggleScrollButtonsState();
        const tabStrip = this.owner;
        if (!tabStrip.hasScrollButtons) {
            return;
        }
        const isFirstTabActive = activeIndex === 0;
        const isLastTabActive = activeIndex === this.owner.tabs.length - 1;
        if (isFirstTabActive && !tabStrip.prevScrollButton.disabled) {
            this.ngZone.run(() => this.toggleButtonActiveState('prev', false));
        }
        if (isLastTabActive && !tabStrip.nextScrollButton.disabled) {
            this.ngZone.run(() => this.toggleButtonActiveState('next', false));
        }
    }
    getScrollOffset(activeIndex) {
        if (!isDocumentAvailable()) {
            return 0;
        }
        const isHorizontal = isTablistHorizontal(this.owner.tabPosition);
        this.tablistElement[`scroll${isHorizontal ? 'Left' : 'Top'}`] = this.position;
        const activeTabRect = this.tablistElement.children[activeIndex].getBoundingClientRect();
        const tablistRect = this.tablistElement.getBoundingClientRect();
        const end = isHorizontal ? 'right' : 'bottom';
        const start = isHorizontal ? 'left' : 'top';
        const activeTabStart = activeTabRect[start];
        const activeTabEnd = activeTabRect[end];
        const tablistStart = tablistRect[start];
        const tablistEnd = tablistRect[end];
        const tabEndIsInVisibleRange = activeTabEnd <= tablistEnd;
        const tabStartIsInVisibleRange = activeTabStart >= tablistStart;
        const isWholeTabVisible = tabEndIsInVisibleRange && tabStartIsInVisibleRange;
        if (isWholeTabVisible) {
            return 0;
        }
        if (!tabEndIsInVisibleRange) {
            return activeTabEnd - tablistEnd;
        }
        if (!tabStartIsInVisibleRange) {
            return activeTabStart - tablistStart;
        }
    }
    onMouseScroll(event) {
        event.preventDefault();
        if (!mouseScrollEnabled(this.owner.scrollable)) {
            return;
        }
        const direction = event.deltaY < 0 ? 'prev' : 'next';
        this.calculateListPosition(direction, this.owner.scrollable.mouseScrollSpeed);
        if (isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollLeft = this.position;
        }
        else {
            this.tablistElement.scrollTop = this.position;
        }
        this.toggleScrollButtonsState();
    }
    scrollTabs(direction) {
        this.calculateListPosition(direction, this.owner.scrollable.buttonScrollSpeed);
        if (isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollTo({ left: this.position, behavior: DEFAULT_SCROLL_BEHAVIOR });
        }
        else {
            this.tablistElement.scrollTo({ top: this.position, behavior: DEFAULT_SCROLL_BEHAVIOR });
        }
        this.toggleScrollButtonsState();
    }
    calculateListPosition(direction, scrollSpeed) {
        const adjustedMaxScroll = this.tablistOverflowSize + this.scrollButtonsSize;
        if (direction === 'prev' && this.position > 0) {
            this.position = this.position - scrollSpeed <= 0 ? 0 : this.position - scrollSpeed;
        }
        else if (direction === 'next' && this.position < adjustedMaxScroll) {
            if (this.position + scrollSpeed > adjustedMaxScroll) {
                this.position = adjustedMaxScroll;
                return;
            }
            this.position += scrollSpeed;
        }
    }
    emitScrollEvent(ev) {
        const scrollEvent = new TabScrollEvent({
            originalEvent: ev
        });
        if (hasObservers(this.owner.tabScroll)) {
            this.owner.tabScroll.emit(scrollEvent);
        }
        return scrollEvent;
    }
    restoreScrollPosition() {
        if (isTablistHorizontal(this.owner.tabPosition)) {
            this.tablistElement.scrollTo({ left: this.position });
        }
        else {
            this.tablistElement.scrollTo({ top: this.position });
        }
        this.toggleScrollButtonsState();
    }
    toggleButtonActiveState(buttonType, active) {
        this.scrollButtonActiveStateChange.next({ buttonType, active });
    }
};
ScrollService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgZone])
], ScrollService);

/**
 * @hidden
 */
const normalizeSettings = ({ enabled = true, scrollButtons = 'auto', mouseScroll = true, buttonScrollSpeed = BUTTON_SCROLL_SPEED, mouseScrollSpeed = MOUSE_SCROLL_SPEED, prevButtonIcon, nextButtonIcon }) => ({
    enabled,
    scrollButtons,
    mouseScroll,
    buttonScrollSpeed,
    mouseScrollSpeed,
    prevButtonIcon,
    nextButtonIcon
});
/**
 * @hidden
 */
const normalizeScrollableSettings = (settings) => normalizeSettings(settings === false ? { enabled: false } : settings);

/**
 * @hidden
 */
let TabStripScrollableButtonComponent = class TabStripScrollableButtonComponent {
    constructor(host, scrollService, renderer, ngZone) {
        this.host = host;
        this.scrollService = scrollService;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.btnClasses = true;
        this.prev = false;
        this._disabled = false;
        this.subs = new Subscription();
        this.clickHandler = (scrollEvent) => {
            const tabStripScrollEvent = this.scrollService.emitScrollEvent(scrollEvent);
            const isTabStripScrollEventPrevented = tabStripScrollEvent.isDefaultPrevented();
            if (isTabStripScrollEventPrevented) {
                return;
            }
            const buttonType = this.prev ? 'prev' : 'next';
            this.scrollService.scrollTabs(buttonType);
        };
        this.subs.add(this.scrollService.scrollButtonActiveStateChange.subscribe((activeButtonSettings) => {
            const applyActiveState = (this.prev && activeButtonSettings.buttonType === 'prev') ||
                (!this.prev && activeButtonSettings.buttonType === 'next');
            if (applyActiveState) {
                this.disabled = !activeButtonSettings.active;
            }
        }));
    }
    get prevClass() {
        return this.prev;
    }
    get nextClass() {
        return !this.prev;
    }
    get disabled() {
        return this._disabled;
    }
    get hostBoundingClientRect() {
        return this.host.nativeElement.getBoundingClientRect();
    }
    get iconClass() {
        return this.scrollService.scrollButtonIconClass[this.prev ? 'prevScrollButton' : 'nextScrollButton'];
    }
    set disabled(value) {
        this._disabled = value;
    }
    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            this.subs.add(this.renderer.listen(this.host.nativeElement, 'click', this.clickHandler));
        });
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    toggle(show) {
        this.renderer[show ? 'removeClass' : 'addClass'](this.host.nativeElement, HIDDEN_CLASS);
    }
};
__decorate([
    HostBinding('class.k-button'),
    HostBinding('class.k-button-md'),
    HostBinding('class.k-icon-button'),
    HostBinding('class.k-rounded-md'),
    HostBinding('class.k-button-flat'),
    HostBinding('class.k-button-flat-base'),
    __metadata("design:type", Boolean)
], TabStripScrollableButtonComponent.prototype, "btnClasses", void 0);
__decorate([
    HostBinding('class.k-tabstrip-prev'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripScrollableButtonComponent.prototype, "prevClass", null);
__decorate([
    HostBinding('class.k-tabstrip-next'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripScrollableButtonComponent.prototype, "nextClass", null);
__decorate([
    HostBinding('class.k-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], TabStripScrollableButtonComponent.prototype, "disabled", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripScrollableButtonComponent.prototype, "prev", void 0);
TabStripScrollableButtonComponent = __decorate([
    Component({
        template: `
        <span class="k-icon k-button-icon" [ngClass]="iconClass"></span>
    `,
        selector: '[kendoTabStripScrollableButton]'
    }),
    __metadata("design:paramtypes", [ElementRef,
        ScrollService,
        Renderer2,
        NgZone])
], TabStripScrollableButtonComponent);

/**
 * Represents the [Kendo UI TabStrip component for Angular]({% slug overview_tabstrip %}).
 */
let TabStripComponent = class TabStripComponent {
    constructor(localization, renderer, wrapper, tabstripService, scrollService, ngZone) {
        this.localization = localization;
        this.renderer = renderer;
        this.wrapper = wrapper;
        this.tabstripService = tabstripService;
        this.scrollService = scrollService;
        this.ngZone = ngZone;
        /**
         * Enables the tab animation.
         *
         * @default true
         */
        this.animate = true;
        /**
         * Sets the position of the tabs. Defaults to `top`.
         *
         * @default 'top'
         */
        this.tabPosition = 'top';
        /**
         * When set to `true`, the component renders all tabs and they are persisted in the DOM.
         * By default, `keepTabContent` is `false`.
         *
         * @default false
         */
        this.keepTabContent = false;
        /**
         * When set to `true`, a close button will be rendered inside each tab.
         * By default, `closable` is `false`.
         *
         * @default false
         */
        this.closable = false;
        /**
         * Allows defining a custom CSS class, or multiple classes separated by spaces, which will be applied to the close button span element.
         * Allows the usage of custom icons.
         */
        this.closeIcon = 'k-icon k-i-x';
        /**
         * Fires each time the user selects a tab ([see example]({% slug overview_tabstrip %}#toc-basic-usage)).
         * The event data contains the index of the selected tab and its title.
         */
        this.tabSelect = new EventEmitter();
        /**
         * Fires each time the user closes a tab.
         * The event data contains the index of the closed tab and its instance.
         */
        this.tabClose = new EventEmitter();
        /**
         * Fires each time the user scrolls the TabStrip list.
         * The event is preventable.
         */
        this.tabScroll = new EventEmitter();
        this.hostClasses = true;
        /**
         * A query list of all declared tabs.
         */
        this.tabs = new QueryList();
        this._scrollableSettings = normalizeScrollableSettings(false);
        this.subscriptions = new Subscription();
        this.subscriptionsArePresent = false;
        validatePackage(packageMetadata);
        this.tabstripService.owner = this;
        this.scrollService.owner = this;
    }
    /**
     * Sets the height of the TabStrip.
     */
    set height(value) {
        this._height = value;
        this.renderer.setStyle(this.wrapper.nativeElement, 'height', value);
    }
    get height() {
        return this._height;
    }
    /**
     * Enables the scrolling of the tab list. When set to `true` and the total size of all tabs
     * is greater than the size of the TabStrip container, scroll buttons will be rendered on each end of the tab list.
     *
     * By default, `scrollable` is `false`.
     *
     * @default false
     */
    set scrollable(value) {
        this._scrollableSettings = normalizeScrollableSettings(value);
        if (this.tablist) {
            this.toggleScrollButtons(this.scrollService.tabsOverflow);
            this.attachWheelHandler(this.tablist.nativeElement);
        }
    }
    get scrollable() {
        return this._scrollableSettings;
    }
    get tabsAtTop() {
        return this.tabPosition === 'top';
    }
    get tabsAtRight() {
        return this.tabPosition === 'right';
    }
    get tabsAtBottom() {
        return this.tabPosition === 'bottom';
    }
    get tabsAtLeft() {
        return this.tabPosition === 'left';
    }
    get dir() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
    get tabStripScrollable() {
        return this._scrollableSettings.enabled;
    }
    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            if (this.scrollService.tabsOverflow) {
                this.toggleScrollButtons(true);
            }
            else {
                this.toggleScrollButtons(false);
            }
            setTimeout(() => {
                this.scrollToSelectedTab();
            });
        });
        this.initDomEvents();
    }
    ngOnChanges(changes) {
        const positionChange = changes.tabPosition;
        if (positionChange) {
            const tabsAtBottomChanged = positionChange.previousValue === 'bottom' || positionChange.currentValue === 'bottom';
            this.ngZone.onStable.pipe(take(1)).subscribe(() => {
                if (tabsAtBottomChanged) {
                    if (this.subscriptionsArePresent) {
                        this.subscriptions.unsubscribe();
                        this.subscriptions = new Subscription();
                        this.subscriptionsArePresent = false;
                    }
                    this.initDomEvents();
                }
                this.scrollService.restoreScrollPosition();
            });
        }
    }
    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    get tabsAlignmentStyles() {
        return {
            start: 'flex-start',
            end: 'flex-end',
            center: 'center',
            justify: 'space-between'
        }[this.tabAlignment];
    }
    /**
     * @hidden
     */
    get tabListWidth() {
        if (this.tabPosition === 'top' || this.tabPosition === 'bottom') {
            return '100%';
        }
        return null;
    }
    /**
     * @hidden
     */
    get tabListHeight() {
        if (this.tabPosition === 'left' || this.tabPosition === 'right') {
            return '100%';
        }
        return null;
    }
    /**
     * @hidden
     */
    get isScrollable() {
        return this._scrollableSettings.enabled;
    }
    /**
     * @hidden
     */
    get hasScrollButtons() {
        return tabStripHasScrollButtons(this._scrollableSettings);
    }
    /**
     * @hidden
     */
    get mouseScrollEnabled() {
        return mouseScrollEnabled(this._scrollableSettings);
    }
    /**
     * @hidden
     */
    get itemsWrapperClass() {
        return isTablistHorizontal(this.tabPosition) ? 'k-hstack' : 'k-vstack';
    }
    /**
     * Allows the user to select a tab programmatically.
     * @param {number} index - The index of the tab that will be selected.
     */
    selectTab(index) {
        const tab = getTabByIndex(this.tabs, index);
        if (!tab || tab.disabled) {
            return;
        }
        this.tabstripService.selectTab(tab, index);
        this.scrollToSelectedTab();
    }
    /**
     * @hidden
     */
    onTabClick(originalEvent, tabIndex) {
        if (isFocusable(originalEvent.target)) {
            return;
        }
        const targetElement = originalEvent.target;
        const isTargetCloseButton = hasClass(targetElement, 'k-remove-tab') || hasClass(targetElement.parentElement, 'k-remove-tab');
        if (isTargetCloseButton) {
            return;
        }
        const tab = getTabByIndex(this.tabs, tabIndex);
        this.tabstripService.onTabSelect(tab, tabIndex);
        this.scrollToSelectedTab();
    }
    /**
     * @hidden
     */
    onResize() {
        if (this.scrollService.tabsOverflow) {
            this.toggleScrollButtons(true);
        }
        else {
            this.toggleScrollButtons(false);
        }
        this.ngZone.runOutsideAngular(() => {
            this.scrollService.toggleScrollButtonsState();
        });
    }
    /**
     * @hidden
     */
    scrollToSelectedTab() {
        if (this._scrollableSettings.enabled) {
            this.scrollService.scrollToSelectedTab();
        }
    }
    initDomEvents() {
        if (!this.wrapper || this.subscriptionsArePresent) {
            return;
        }
        const tablist = this.tablist.nativeElement;
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions.add(this.renderer.listen(tablist, 'keydown', (ev) => {
                this.tabstripService.onKeyDown(ev);
            }));
        });
        this.subscriptions.add(this.renderer.listen(tablist, 'focusout', () => {
            resetTabFocus(this.tabs);
        }));
        if (this.isScrollable && this.mouseScrollEnabled) {
            this.attachWheelHandler(tablist);
        }
        this.subscriptionsArePresent = true;
    }
    toggleScrollButtons(tabsOverflow) {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const scrollButtonsSetting = this._scrollableSettings.scrollButtons;
            const scrollButtonsArePresent = this.prevScrollButton && this.nextScrollButton;
            const shouldShowButtons = scrollButtonsArePresent && tabsOverflow;
            const shouldHideButtons = scrollButtonsArePresent &&
                !tabsOverflow &&
                scrollButtonsSetting !== 'visible';
            const alwaysVisible = scrollButtonsSetting === 'visible';
            if (shouldHideButtons) {
                this.prevScrollButton.toggle(false);
                this.nextScrollButton.toggle(false);
            }
            else if (shouldShowButtons || alwaysVisible) {
                this.prevScrollButton.toggle(true);
                this.nextScrollButton.toggle(true);
            }
            if (scrollButtonsArePresent && alwaysVisible) {
                this.ngZone.runOutsideAngular(() => {
                    this.scrollService.toggleScrollButtonsState();
                });
            }
        });
    }
    attachWheelHandler(tablist) {
        this.ngZone.runOutsideAngular(() => {
            this.subscriptions.add(this.renderer.listen(tablist, 'wheel', (wheelEvent) => {
                const tabStripScrollEvent = this.scrollService.emitScrollEvent(wheelEvent);
                const isTabStripScrollEventPrevented = tabStripScrollEvent.isDefaultPrevented();
                if (isTabStripScrollEventPrevented || !this.scrollService.tabsOverflow) {
                    return;
                }
                this.scrollService.onMouseScroll(wheelEvent);
            }));
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], TabStripComponent.prototype, "height", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripComponent.prototype, "animate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripComponent.prototype, "tabAlignment", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripComponent.prototype, "tabPosition", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripComponent.prototype, "keepTabContent", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabStripComponent.prototype, "closable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], TabStripComponent.prototype, "scrollable", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripComponent.prototype, "closeIcon", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TabStripComponent.prototype, "tabSelect", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TabStripComponent.prototype, "tabClose", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TabStripComponent.prototype, "tabScroll", void 0);
__decorate([
    HostBinding('class.k-tabstrip'),
    HostBinding('class.k-floatwrap'),
    __metadata("design:type", Boolean)
], TabStripComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-tabstrip-top'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "tabsAtTop", null);
__decorate([
    HostBinding('class.k-tabstrip-right'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "tabsAtRight", null);
__decorate([
    HostBinding('class.k-tabstrip-bottom'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "tabsAtBottom", null);
__decorate([
    HostBinding('class.k-tabstrip-left'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "tabsAtLeft", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "dir", null);
__decorate([
    HostBinding('class.k-tabstrip-scrollable'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabStripComponent.prototype, "tabStripScrollable", null);
__decorate([
    ContentChildren(TabStripTabComponent),
    __metadata("design:type", QueryList)
], TabStripComponent.prototype, "tabs", void 0);
__decorate([
    ViewChild('tablist', { static: false }),
    __metadata("design:type", ElementRef)
], TabStripComponent.prototype, "tablist", void 0);
__decorate([
    ViewChildren('tabHeaderContainer', { read: ElementRef }),
    __metadata("design:type", QueryList)
], TabStripComponent.prototype, "tabHeaderContainers", void 0);
__decorate([
    ViewChild('prevScrollButton', { static: false }),
    __metadata("design:type", TabStripScrollableButtonComponent)
], TabStripComponent.prototype, "prevScrollButton", void 0);
__decorate([
    ViewChild('nextScrollButton', { static: false }),
    __metadata("design:type", TabStripScrollableButtonComponent)
], TabStripComponent.prototype, "nextScrollButton", void 0);
TabStripComponent = __decorate([
    Component({
        animations: [
            trigger('state', [
                state('active', style({ opacity: 1 })),
                transition('* => active', [
                    style({ opacity: 0 }),
                    animate('400ms ease-in')
                ])
            ])
        ],
        providers: [
            TabStripService,
            ScrollService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.tabstrip'
            }
        ],
        exportAs: 'kendoTabStrip',
        selector: 'kendo-tabstrip',
        template: `
        <ng-container kendoTabStripLocalizedMessages
            i18n-closeTitle="kendo.tabstrip.closeTitle|The title for the **Close** button in the TabStrip tab."
            closeTitle="Close">
        </ng-container>
        <ng-container *ngIf="!tabsAtBottom">
            <ng-container *ngTemplateOutlet="heading">
            </ng-container>
            <ng-container *ngTemplateOutlet="content">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="tabsAtBottom">
            <ng-container *ngTemplateOutlet="content">
            </ng-container>
            <ng-container *ngTemplateOutlet="heading">
            </ng-container>
        </ng-container>
        <ng-template #heading>
            <div class="k-tabstrip-items-wrapper" [ngClass]="itemsWrapperClass">
                <span *ngIf="hasScrollButtons"
                     #prevScrollButton
                    kendoTabStripScrollableButton
                    [prev]="true"></span>
                <ul role="tablist" #tablist
                    class="k-reset k-tabstrip-items"
                    [style.justifyContent]="tabsAlignmentStyles"
                    [style.width]="tabListWidth"
                    [style.height]="tabListHeight"
                >
                    <ng-container *ngFor="let tab of tabs; let i = index;">
                        <li *ngIf="!tab.closed"
                            #tabHeaderContainer
                            kendoTabStripTab
                            [ngClass]="tab.cssClass"
                            [ngStyle]="tab.cssStyle"
                            [tab]="tab"
                            [index]="i"
                            role="tab"
                            [tabStripClosable]="closable"
                            [tabStripCloseIcon]="closeIcon"
                            (click)="onTabClick($event, i)"
                            [id]="'k-tabstrip-tab-' + i"
                            [attr.aria-controls]="'k-tabstrip-tabpanel-' + i">
                        </li>
                    </ng-container>
                </ul>
                <span *ngIf="hasScrollButtons" #nextScrollButton
                    kendoTabStripScrollableButton
                    [prev]="false">
                </span>
            </div>
        </ng-template>
        <ng-template #content>
            <ng-template ngFor let-tab [ngForOf]="tabs" let-i="index">
                <div
                    [@state]="tab.selected && animate ? 'active' : 'inactive'"
                    *ngIf="!tab.closed && (tab.selected || keepTabContent)"
                    [ngClass]="!this.keepTabContent || tab.selected ? 'k-content k-state-active' : 'k-content'"
                    [tabIndex]="0"
                    role="tabpanel"
                    [id]="'k-tabstrip-tabpanel-' + i"
                    [attr.aria-hidden]="!tab.selected"
                    [attr.aria-expanded]="tab.selected"
                    [attr.aria-labelledby]="'k-tabstrip-tab-' + i"
                    [attr.aria-disabled]="tab.disabled"
                >
                    <ng-template [ngTemplateOutlet]="tab.tabContent?.templateRef">
                    </ng-template>
                </div>
            </ng-template>
        </ng-template>
        <kendo-resize-sensor *ngIf="isScrollable" (resize)="onResize()"></kendo-resize-sensor>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        Renderer2,
        ElementRef,
        TabStripService,
        ScrollService,
        NgZone])
], TabStripComponent);

/**
 * @hidden
 */
class TabStripMessages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], TabStripMessages.prototype, "closeTitle", void 0);

var LocalizedTabStripMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedTabStripMessagesDirective = LocalizedTabStripMessagesDirective_1 = class LocalizedTabStripMessagesDirective extends TabStripMessages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedTabStripMessagesDirective = LocalizedTabStripMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: TabStripMessages,
                useExisting: forwardRef(() => LocalizedTabStripMessagesDirective_1)
            }
        ],
        selector: `[kendoTabStripLocalizedMessages]`
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedTabStripMessagesDirective);

var TabStripCustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug rtl_layout %})).
 */
let TabStripCustomMessagesComponent = TabStripCustomMessagesComponent_1 = class TabStripCustomMessagesComponent extends TabStripMessages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
TabStripCustomMessagesComponent = TabStripCustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: TabStripMessages,
                useExisting: forwardRef(() => TabStripCustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-tabstrip-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], TabStripCustomMessagesComponent);

/**
 * Represents a template that defines the content of the Drawer.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDrawerTemplate` directive inside the `<kendo-drawer>` tag.
 * Using this template directive will override all other templates,
 * for example, `kendoDrawerHeaderTemplate` and `kendoDrawerItemTemplate`.
 */
let DrawerTemplateDirective = class DrawerTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DrawerTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDrawerTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DrawerTemplateDirective);

/**
 * Represents a template that defines the item content of the Drawer.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDrawerItemTemplate` directive inside the `<kendo-drawer>` tag.
 */
let DrawerItemTemplateDirective = class DrawerItemTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DrawerItemTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDrawerItemTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DrawerItemTemplateDirective);

/**
 * Represents a template that defines the header content of the Drawer.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDrawerHeaderTemplate` directive inside the `<kendo-drawer>` tag.
 */
let DrawerHeaderTemplateDirective = class DrawerHeaderTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DrawerHeaderTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDrawerHeaderTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DrawerHeaderTemplateDirective);

/**
 * Represents a template that defines the footer content of the Drawer.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoDrawerFooterTemplate` directive inside the `<kendo-drawer>` tag.
 */
let DrawerFooterTemplateDirective = class DrawerFooterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
DrawerFooterTemplateDirective = __decorate([
    Directive({
        selector: '[kendoDrawerFooterTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], DrawerFooterTemplateDirective);

/**
 * @hidden
 */
function miniExpandPush(duration, width, miniWidth) {
    return [
        style({ overflow: 'hidden', flexBasis: `${miniWidth}px` }),
        animate(`${duration}ms ease-in`, style({ flexBasis: `${width}px` }))
    ];
}
/**
 * @hidden
 */
function miniCollapsePush(duration, width, miniWidth) {
    return [
        style({ overflow: 'hidden', flexBasis: `${width}px` }),
        animate(`${duration}ms ease-in`, style({ flexBasis: `${miniWidth}px` }))
    ];
}
/**
 * @hidden
 *
 */
function miniExpandOverlay(duration, width, miniWidth) {
    return [
        style({ width: `${miniWidth}px` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', width: `${width}px` }))
    ];
}
/**
 * @hidden
 */
function expandPush(duration, width) {
    return [
        style({ overflow: 'hidden', flexBasis: '0px' }),
        animate(`${duration}ms ease-in`, style({ flexBasis: `${width}px` }))
    ];
}
/**
 * @hidden
 */
function collapsePush(duration, width) {
    return [
        style({ flexBasis: `${width}px` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', flexBasis: `0px` }))
    ];
}
/**
 * @hidden
 */
function expandRTLOverlay(duration) {
    return [
        style({ transform: `translateX(100%)` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', transform: `translateX(0)` }))
    ];
}
/**
 * @hidden
 */
function expandOverlay(duration, position) {
    const translateDir = position !== 'end' ? `-100%` : `100%`;
    return [
        style({ transform: `translateX(${translateDir})` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', transform: `translateX(0)` }))
    ];
}
/**
 * @hidden
 */
function miniCollapseOverlay(duration, width, miniWidth) {
    return [
        style({ width: `${width}px` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', width: `${miniWidth}px` }))
    ];
}
/**
 * @hidden
 */
function collapseOverlay(duration, position) {
    const translateDir = position !== 'end' ? '-100%' : '100%';
    return [
        style({ transform: `translateX(0)` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', transform: `translateX(${translateDir})` }))
    ];
}
/**
 * @hidden
 */
function collapseRTLOverlay(duration) {
    return [
        style({ transform: `translateX(0)` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', transform: `translateX(100%)` }))
    ];
}
/**
 * @hidden
 */
function expandAnimation(settings) {
    const duration = settings.animation.duration;
    const width = settings.width;
    const miniWidth = settings.miniWidth;
    const mode = settings.mode;
    const mini = settings.mini;
    const rtl = settings.rtl;
    const position = settings.position;
    if (mini && mode === 'push') {
        return miniExpandPush(duration, width, miniWidth);
    }
    if (!mini && mode === 'push') {
        return expandPush(duration, width);
    }
    if (!mini && mode === 'overlay') {
        return rtl ? expandRTLOverlay(duration) : expandOverlay(duration, position);
    }
    if (mini && mode === 'overlay') {
        return miniExpandOverlay(duration, width, miniWidth);
    }
}
/**
 * @hidden
 */
function collapseAnimation(settings) {
    const duration = settings.animation.duration;
    const width = settings.width;
    const miniWidth = settings.miniWidth;
    const mode = settings.mode;
    const mini = settings.mini;
    const rtl = settings.rtl;
    const position = settings.position;
    if (mini && mode === 'push') {
        return miniCollapsePush(duration, width, miniWidth);
    }
    if (!mini && mode === 'push') {
        return collapsePush(duration, width);
    }
    if (!mini && mode === 'overlay') {
        return rtl ? collapseRTLOverlay(duration) : collapseOverlay(duration, position);
    }
    if (mini && mode === 'overlay') {
        return miniCollapseOverlay(duration, width, miniWidth);
    }
}

/**
 * Arguments for the `select` event of the Drawer.
 */
class DrawerSelectEvent extends PreventableEvent$1 {
}

/**
 * @hidden
 */
let DrawerService = class DrawerService {
    /**
     * @hidden
     */
    constructor() {
        this.selectedIndices = [];
    }
    emit(event, args) {
        const drawer = this.owner;
        const eventArgs = new DrawerSelectEvent(Object.assign({}, args, { sender: drawer }));
        if (hasObservers(drawer[event])) {
            drawer[event].emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    onSelect(selectedIdx) {
        this.selectedIndices = [selectedIdx];
        const drawer = this.owner;
        if (drawer.autoCollapse && !drawer.minimized) {
            drawer.toggle(false);
        }
    }
    initSelection() {
        const items = this.owner.items;
        this.selectedIndices = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].selected) {
                this.selectedIndices.push(i);
            }
        }
    }
};
DrawerService = __decorate([
    Injectable()
], DrawerService);

const DEFAULT_ANIMATION = { type: 'slide', duration: 200 };
/**
 * Represents the [Kendo UI Drawer component for Angular]({% slug overview_drawer %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-drawer-container>
 *             <kendo-drawer #drawer
 *                  [items]="items"
 *                  mode="overlay"
 *                  [(expanded)]="expanded">
 *              </kendo-drawer>
 *              <kendo-drawer-content>
 *                  <button class="k-button" (click)="drawer.toggle()">Open the Drawer</button>
 *              </kendo-drawer-content>
 *        </kendo-drawer-container>
 *    `
 * })
 * class AppComponent {
 *    public expanded = false;
 *
 *    public items: any[] = [
 *      { text: 'Inbox', icon: 'k-i-inbox' },
 *      { text: 'Notifications', icon: 'k-i-bell' },
 *      { text: 'Date', icon: 'k-i-calendar' }
 *    ];
 * }
 * ```
 */
let DrawerComponent = class DrawerComponent {
    constructor(element, builder, localizationService, drawerService) {
        this.element = element;
        this.builder = builder;
        this.localizationService = localizationService;
        this.drawerService = drawerService;
        this.hostClasses = true;
        /**
         * Specifies the mode in which the Drawer will be displayed.
         *
         * The possible values are:
         * * (Default) `overlay`
         * * `push`
         */
        this.mode = 'overlay';
        /**
         * Specifies the position of the Drawer
         * ([see example]({% slug positioning_drawer %})).
         *
         * The possible values are:
         * * (Default) `start`
         * * `end`
         */
        this.position = 'start';
        /**
         * Enables the mini (compact) view of the Drawer which is displayed when the component is collapsed
         * ([see example]({% slug expandmodespositions_drawer %}#toc-mini-view)).
         */
        this.mini = false;
        /**
         * Specifies the state of the Drawer.
         */
        this.expanded = false;
        /**
         * Defines the width of the Drawer when it is expanded.
         * Defaults to `240`.
         */
        this.width = 240;
        /**
         * Defines the width of the Drawer when the mini view is enabled
         * and the component is collapsed. Defaults to `60`.
         */
        this.miniWidth = 50;
        /**
         * Specifies if the Drawer will be automatically collapsed when an item
         * or the overlay is clicked. Defaults to `true`.
         */
        this.autoCollapse = true;
        /**
         * Specifies the animation settings of the Drawer.
         * ([see example]({% slug interaction_drawer %}#toc-toggling-between-states)).
         *
         * The possible values are:
         * * Boolean
         *    * (Default) `true`
         *    * `false`
         * * `DrawerAnimation`
         *    * (Default) `type?: 'slide'`
         *    * `duration`&mdash;Accepts a number in milliseconds. Defaults to `300ms`.
         */
        this.animation = DEFAULT_ANIMATION;
        /**
         * Fires when the Drawer is expanded and its animation is complete.
         */
        this.expand = new EventEmitter();
        /**
         * Fires when the Drawer is collapsed and its animation is complete.
         */
        this.collapse = new EventEmitter();
        /**
         * Fires when a Drawer item is selected. This event is preventable.
         */
        this.select = new EventEmitter();
        /**
         * Fires when the `expanded` property of the component was updated.
         * Used to provide a two-way binding for the `expanded` property.
         */
        this.expandedChange = new EventEmitter();
        this.animationEnd = new EventEmitter();
        this.rtl = false;
        this._items = [];
        validatePackage(packageMetadata);
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
        this.drawerService.owner = this;
    }
    get startPositionClass() {
        return this.position === 'start';
    }
    get endPositionClass() {
        return this.position === 'end';
    }
    get overlayTransofrmStyles() {
        if (this.mode === 'push') {
            return;
        }
        if (this.expanded || this.minimized) {
            return `translateX(0px)`;
        }
        return `translateX(-100%)`;
    }
    get flexStyles() {
        if (this.mode === 'overlay') {
            return;
        }
        if (!this.expanded && !this.minimized) {
            return 0;
        }
        return this.drawerWidth;
    }
    /**
     * The collection of items that will be rendered in the Drawer.
     */
    set items(items) {
        if (isPresent(items)) {
            this._items = items;
            this.drawerService.initSelection();
        }
    }
    get items() {
        return this._items;
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    get minimized() {
        return this.mini && !this.expanded;
    }
    /**
     * @hidden
     */
    get drawerWidth() {
        return this.minimized ? this.miniWidth : this.width;
    }
    /**
     * Toggles the visibility of the Drawer.
     *
     * @param expanded? - Boolean. Specifies if the Drawer will be expanded or collapsed.
     */
    toggle(expanded) {
        const previous = this.expanded;
        const current = isPresent(expanded) ? expanded : !previous;
        if (current === previous) {
            return;
        }
        if (current === true) {
            this.setExpanded(true);
        }
        else if (current === false && !this.animation) {
            this.setExpanded(false);
        }
        if (this.animation) {
            this.animationEnd.pipe(take(1))
                .subscribe(() => { this.onAnimationEnd(current); });
            this.animate(current);
        }
        else {
            this[current ? 'expand' : 'collapse'].emit();
        }
    }
    onAnimationEnd(currentExpanded) {
        if (currentExpanded) {
            this.expand.emit();
        }
        else {
            this.setExpanded(false);
            this.collapse.emit();
        }
    }
    setExpanded(value) {
        this.expanded = value;
        this.expandedChange.emit(value);
    }
    animate(expanded) {
        const settings = {
            mode: this.mode,
            mini: this.mini,
            miniWidth: this.miniWidth,
            width: this.width,
            rtl: this.rtl,
            position: this.position,
            animation: (typeof this.animation !== 'boolean') ? this.animation : DEFAULT_ANIMATION
        };
        const animation = expanded ? expandAnimation(settings) : collapseAnimation(settings);
        const player = this.createPlayer(animation, this.element.nativeElement);
        player.play();
    }
    createPlayer(animation, animatedElement) {
        const factory = this.builder.build(animation);
        let player = factory.create(animatedElement);
        player.onDone(() => {
            if (player) {
                this.animationEnd.emit();
                player.destroy();
                player = null;
            }
        });
        return player;
    }
};
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-drawer'),
    __metadata("design:type", Boolean)
], DrawerComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-drawer-start'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerComponent.prototype, "startPositionClass", null);
__decorate([
    HostBinding('class.k-drawer-end'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerComponent.prototype, "endPositionClass", null);
__decorate([
    HostBinding('style.transform'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], DrawerComponent.prototype, "overlayTransofrmStyles", null);
__decorate([
    HostBinding('style.flexBasis.px'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], DrawerComponent.prototype, "flexStyles", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], DrawerComponent.prototype, "mode", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DrawerComponent.prototype, "position", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerComponent.prototype, "mini", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerComponent.prototype, "expanded", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DrawerComponent.prototype, "width", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DrawerComponent.prototype, "miniWidth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerComponent.prototype, "autoCollapse", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], DrawerComponent.prototype, "items", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], DrawerComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DrawerComponent.prototype, "animation", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DrawerComponent.prototype, "expand", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DrawerComponent.prototype, "collapse", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DrawerComponent.prototype, "select", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], DrawerComponent.prototype, "expandedChange", void 0);
__decorate([
    ContentChild(DrawerTemplateDirective, { static: false }),
    __metadata("design:type", DrawerTemplateDirective)
], DrawerComponent.prototype, "drawerTemplate", void 0);
__decorate([
    ContentChild(DrawerFooterTemplateDirective, { static: false }),
    __metadata("design:type", DrawerFooterTemplateDirective)
], DrawerComponent.prototype, "footerTemplate", void 0);
__decorate([
    ContentChild(DrawerHeaderTemplateDirective, { static: false }),
    __metadata("design:type", DrawerHeaderTemplateDirective)
], DrawerComponent.prototype, "headerTemplate", void 0);
__decorate([
    ContentChild(DrawerItemTemplateDirective, { static: false }),
    __metadata("design:type", DrawerItemTemplateDirective)
], DrawerComponent.prototype, "itemTemplate", void 0);
DrawerComponent = __decorate([
    Component({
        exportAs: 'kendoDrawer',
        providers: [
            LocalizationService,
            DrawerService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.drawer'
            }
        ],
        selector: 'kendo-drawer',
        template: `
        <div class="k-drawer-wrapper" *ngIf="expanded || mini" [style.width.px]="drawerWidth">
            <ng-container *ngIf="!drawerTemplate">
                <ng-template *ngIf="headerTemplate"
                    [ngTemplateOutlet]="headerTemplate?.templateRef">
                </ng-template>

                <ul kendoDrawerList
                    [items]="items" [mini]="mini" [expanded]="expanded"
                    [itemTemplate]="itemTemplate?.templateRef"
                    class="k-drawer-items">
                </ul>

                <ng-template *ngIf="footerTemplate"
                    [ngTemplateOutlet]="footerTemplate?.templateRef">
                </ng-template>
            </ng-container>

            <ng-template *ngIf="drawerTemplate"
                [ngTemplateOutlet]="drawerTemplate?.templateRef">
            </ng-template>
        </div>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        AnimationBuilder,
        LocalizationService,
        DrawerService])
], DrawerComponent);

/**
 * Serves as a container for the [Kendo UI Drawer component for Angular]({% slug overview_drawer %}) and its content.
 */
let DrawerContainerComponent = class DrawerContainerComponent {
    constructor(localizationService) {
        this.localizationService = localizationService;
        this.rtl = false;
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    get hostClass() {
        return true;
    }
    get overlayClass() {
        return this.drawer.mode === 'overlay';
    }
    get miniClass() {
        return this.drawer.mini;
    }
    get pushClass() {
        return this.drawer.mode === 'push';
    }
    get isExpandedClass() {
        return this.drawer.expanded;
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    /**
     * @hidden
     */
    get overlay() {
        return isPresent(this.drawer) &&
            this.drawer.expanded &&
            this.drawer.mode === 'overlay';
    }
    /**
     * @hidden
     */
    closeDrawer() {
        if (this.overlay && this.drawer.autoCollapse) {
            this.drawer.toggle(false);
        }
    }
};
__decorate([
    HostBinding('class.k-drawer-container'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerContainerComponent.prototype, "hostClass", null);
__decorate([
    HostBinding('class.k-drawer-overlay'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerContainerComponent.prototype, "overlayClass", null);
__decorate([
    HostBinding('class.k-drawer-mini'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerContainerComponent.prototype, "miniClass", null);
__decorate([
    HostBinding('class.k-drawer-push'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerContainerComponent.prototype, "pushClass", null);
__decorate([
    HostBinding('class.k-drawer-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerContainerComponent.prototype, "isExpandedClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], DrawerContainerComponent.prototype, "direction", void 0);
__decorate([
    ContentChild(DrawerComponent, { static: false }),
    __metadata("design:type", DrawerComponent)
], DrawerContainerComponent.prototype, "drawer", void 0);
DrawerContainerComponent = __decorate([
    Component({
        selector: 'kendo-drawer-container',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.drawer.container'
            }
        ],
        template: `
        <div class="k-overlay" *ngIf="overlay" (click)="closeDrawer()"></div>
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], DrawerContainerComponent);

/**
 * Represents the content of the [Kendo UI Drawer component for Angular]({% slug overview_drawer %}).
 */
let DrawerContentComponent = class DrawerContentComponent {
    constructor() {
        this.hostClasses = true;
    }
};
__decorate([
    HostBinding('class.k-drawer-content'),
    __metadata("design:type", Boolean)
], DrawerContentComponent.prototype, "hostClasses", void 0);
DrawerContentComponent = __decorate([
    Component({
        selector: 'kendo-drawer-content',
        template: `
        <ng-content></ng-content>
    `,
        encapsulation: ViewEncapsulation.None
    }),
    __metadata("design:paramtypes", [])
], DrawerContentComponent);

/**
 * Represents a template that defines the content of the whole Step.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoStepperStepTemplate` directive inside the `<kendo-stepper>` tag.
 */
let StepperStepTemplateDirective = class StepperStepTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
StepperStepTemplateDirective = __decorate([
    Directive({
        selector: '[kendoStepperStepTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], StepperStepTemplateDirective);

/**
 * Represents a template that defines the content of the Step label.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoStepperLabelTemplate` directive inside the `<kendo-stepper>` tag.
 */
let StepperLabelTemplateDirective = class StepperLabelTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
StepperLabelTemplateDirective = __decorate([
    Directive({
        selector: '[kendoStepperLabelTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], StepperLabelTemplateDirective);

/**
 * Represents a template that defines the content of the Step indicator.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoStepperIndicatorTemplate` directive inside the `<kendo-stepper>` tag.
 */
let StepperIndicatorTemplateDirective = class StepperIndicatorTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
StepperIndicatorTemplateDirective = __decorate([
    Directive({
        selector: '[kendoStepperIndicatorTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], StepperIndicatorTemplateDirective);

/**
 * Arguments for the `activate` event of the Stepper.
 */
class StepperActivateEvent extends PreventableEvent$1 {
}

const DEFAULT_CURRENT_STEP = 0;
const handlers = {};
handlers[Keys.ArrowLeft] = 'left';
handlers[Keys.ArrowRight] = 'right';
handlers[Keys.ArrowUp] = 'up';
handlers[Keys.ArrowDown] = 'down';
handlers[Keys.Home] = 'home';
handlers[Keys.End] = 'end';
handlers[Keys.Enter] = 'enter';
handlers[Keys.Space] = 'enter';
const handlersRTL = Object.assign({}, handlers);
handlersRTL[Keys.ArrowLeft] = 'right';
handlersRTL[Keys.ArrowRight] = 'left';
/**
 * @hidden
 */
let StepperService = class StepperService {
    constructor(localization, ngZone, changeDetector) {
        this.localization = localization;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.currentStep = DEFAULT_CURRENT_STEP;
        this.triggerValidation = new EventEmitter();
        this.focusedStepChange = new EventEmitter();
    }
    get handlers() {
        return this.localization.rtl ? handlersRTL : handlers;
    }
    emit(event, eventArgs) {
        const stepper = this.owner;
        if (hasObservers(stepper[event])) {
            stepper[event].emit(eventArgs);
        }
        return eventArgs.isDefaultPrevented();
    }
    onActivate(currentIdx, originalEvent) {
        const eventArgs = new StepperActivateEvent({
            index: currentIdx,
            step: this.owner.steps[currentIdx],
            originalEvent: originalEvent,
            sender: this.owner
        });
        this.ngZone.run(() => {
            if (!this.emit('activate', eventArgs)) {
                this.currentStep = currentIdx;
                this.owner['currentStepChange'].emit(currentIdx);
                this.changeDetector.detectChanges();
            }
        });
    }
    validateSteps() {
        this.triggerValidation.emit();
    }
    keydown(e) {
        const current = this.focusedStep || this.currentStep;
        const handler = this.handlers[e.keyCode];
        if (!isPresent(current)) {
            return;
        }
        if (handler) {
            e.preventDefault();
            this[handler](e);
        }
    }
    left() {
        if (!this.isHorizontal) {
            return;
        }
        this.focusPrevStep();
    }
    right() {
        if (!this.isHorizontal) {
            return;
        }
        this.focusNextStep();
    }
    up() {
        if (this.isHorizontal) {
            return;
        }
        this.focusPrevStep();
    }
    down() {
        if (this.isHorizontal) {
            return;
        }
        this.focusNextStep();
    }
    home() {
        this.focusedStep = 0;
        this.focusedStepChange.emit();
    }
    end() {
        this.focusedStep = this.owner.steps.length - 1;
        this.focusedStepChange.emit();
    }
    enter(event) {
        if (this.focusedStep === this.currentStep) {
            return;
        }
        if (this.isStepDisabled(this.focusedStep)) {
            return;
        }
        if (this.owner.linear && this.isPrevOrNextStep(this.focusedStep) === false) {
            return;
        }
        this.onActivate(this.focusedStep, event);
    }
    focus(focusedIdx) {
        this.focusedStep = focusedIdx;
    }
    focusNextStep() {
        if (this.focusedStep < this.owner.steps.length) {
            this.focusedStep += 1;
            this.focusedStepChange.emit();
        }
    }
    focusPrevStep() {
        if (this.focusedStep > 0) {
            this.focusedStep -= 1;
            this.focusedStepChange.emit();
        }
    }
    isStepDisabled(index) {
        return this.owner.steps[index].disabled;
    }
    isPrevOrNextStep(index) {
        return index === this.currentStep + 1 || index === this.currentStep - 1;
    }
    get isHorizontal() {
        return this.owner.orientation === 'horizontal';
    }
};
StepperService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [LocalizationService,
        NgZone,
        ChangeDetectorRef])
], StepperService);

const DEFAULT_ANIMATION_DURATION = 400;
/**
 * Represents the [Kendo UI Stepper component for Angular]({% slug overview_stepper %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-stepper [steps]="steps">
 *        </kendo-stepper>
 *    `
 * })
 * class AppComponent {
 *    public steps: Array<StepperStep> = [
 *      { label: 'Step One' }, { label: 'Step Two' }, { label: 'Step Three' }
 *    ];
 * }
 * ```
 */
let StepperComponent = class StepperComponent {
    constructor(renderer, elem, localization, stepperService) {
        this.renderer = renderer;
        this.elem = elem;
        this.localization = localization;
        this.stepperService = stepperService;
        this.hostClasses = true;
        this.ariaRole = 'navigation';
        this.displayStyle = 'grid';
        /**
         * Specifies the type of the steps in the Stepper.
         *
         * The possible values are:
         * * (Default) `indicator`
         * * `label`
         * * `full`
         */
        this.stepType = 'indicator';
        /**
         * Specifies the linear flow of the Stepper.
         *
         * @default true
         */
        this.linear = true;
        /**
         * Specifies the orientation of the Stepper
         * ([see example]({% slug orientation_stepper %})).
         *
         * The possible values are:
         * * (Default) `horizontal`
         * * `vertical`
         */
        this.orientation = 'horizontal';
        /**
         * Specifies the duration of the progress indicator animation in milliseconds. Defaults to `400ms`.
         *
         * The possible values are:
         *  * Boolean
         *    * (Default) `true`
         *    * false
         *  * Number
         */
        this.animation = true;
        /**
         * Fires when a step is about to be activated. This event is preventable.
         */
        this.activate = new EventEmitter();
        /**
         * Fires when the `currentStep` property of the component was updated.
         * Used to provide a two-way binding for the `currentStep` property.
         */
        this.currentStepChange = new EventEmitter();
        this._steps = [];
        this.dynamicRTLSubscription = this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        });
        this.stepperService.owner = this;
    }
    get linearClass() {
        return this.linear;
    }
    /**
     * The index of the current step.
     */
    set currentStep(value) {
        this.stepperService.currentStep = value;
    }
    get currentStep() {
        return this.stepperService.currentStep;
    }
    /**
     * The collection of steps that will be rendered in the Stepper.
     * ([see example]({% slug step_appearance_stepper %}))
     */
    set steps(steps) {
        if (isPresent(steps) && steps.length > 0) {
            this._steps = steps;
        }
    }
    get steps() {
        return this._steps;
    }
    ngOnInit() {
        this.applyHostStyling();
    }
    ngOnChanges(changes) {
        if (changes.steps && !changes.steps.firstChange) {
            this.applyHostStyling();
        }
        if (changes.orientation) {
            this.resetHostStyling();
            this.applyHostStyling();
        }
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
    /**
     * Manually triggers the validity check configured by the [isValid]({% slug api_layout_stepperstep %}#toc-isvalid) property of the steps ([see example]({% slug step_validation_stepper %}#toc-triggering-the-validation)).
     *
     * Steps that have their [validate]({% slug api_layout_stepperstep %}#toc-validate) property set to `false`, will not be validated.
     */
    validateSteps() {
        this.stepperService.validateSteps();
    }
    applyHostStyling() {
        const stepFramesStyle = this.orientation === 'horizontal' ? 'grid-template-columns' : 'grid-template-rows';
        const stepFramesValue = `repeat(${this.steps.length * 2}, 1fr)`;
        this.renderer.setStyle(this.elem.nativeElement, stepFramesStyle, stepFramesValue);
    }
    resetHostStyling() {
        this.renderer.removeStyle(this.elem.nativeElement, 'grid-template-columns');
        this.renderer.removeStyle(this.elem.nativeElement, 'grid-template-rows');
    }
    /**
     * @hidden
     */
    get progressAnimation() {
        return { duration: this.animationDuration };
    }
    /**
     * @hidden
     */
    get animationDuration() {
        if (typeof this.animation === 'number') {
            return this.animation;
        }
        if (typeof this.animation === 'boolean' && this.animation) {
            return DEFAULT_ANIMATION_DURATION;
        }
        return 0;
    }
    /**
     * @hidden
     */
    get stepsListStyling() {
        if (this.orientation === 'horizontal') {
            return { 'grid-column-start': 1, 'grid-column-end': -1 };
        }
        return { 'grid-row-start': 1, 'grid-row-end': -1 };
    }
    /**
     * @hidden
     */
    get progressBarStyling() {
        if (this.orientation === 'horizontal') {
            return {
                'grid-column-start': 2,
                'grid-column-end': this.steps.length * 2
            };
        }
        return {
            'grid-row-start': 2,
            'grid-row-end': this.steps.length * 2
        };
    }
    /***
     * @hidden
     */
    get isHorizontal() {
        return this.stepperService.isHorizontal;
    }
};
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-stepper'),
    __metadata("design:type", Boolean)
], StepperComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('class.k-stepper-linear'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], StepperComponent.prototype, "linearClass", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], StepperComponent.prototype, "ariaRole", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], StepperComponent.prototype, "direction", void 0);
__decorate([
    HostBinding('style.display'),
    __metadata("design:type", String)
], StepperComponent.prototype, "displayStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperComponent.prototype, "stepType", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], StepperComponent.prototype, "linear", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], StepperComponent.prototype, "currentStep", null);
__decorate([
    Input(),
    __metadata("design:type", Array),
    __metadata("design:paramtypes", [Array])
], StepperComponent.prototype, "steps", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperComponent.prototype, "successIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperComponent.prototype, "errorIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], StepperComponent.prototype, "animation", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], StepperComponent.prototype, "activate", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], StepperComponent.prototype, "currentStepChange", void 0);
__decorate([
    ContentChild(StepperStepTemplateDirective, { static: true }),
    __metadata("design:type", StepperStepTemplateDirective)
], StepperComponent.prototype, "stepTemplate", void 0);
__decorate([
    ContentChild(StepperLabelTemplateDirective, { static: true }),
    __metadata("design:type", StepperLabelTemplateDirective)
], StepperComponent.prototype, "labelTemplate", void 0);
__decorate([
    ContentChild(StepperIndicatorTemplateDirective, { static: true }),
    __metadata("design:type", StepperIndicatorTemplateDirective)
], StepperComponent.prototype, "indicatorTemplate", void 0);
StepperComponent = __decorate([
    Component({
        exportAs: 'kendoStepper',
        providers: [
            LocalizationService,
            StepperService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.stepper'
            }
        ],
        selector: 'kendo-stepper',
        template: `
        <ng-container kendoStepperLocalizedMessages
            i18n-optional="kendo.stepper.optional|The text for the optional segment of the step label"
            optional="Optional"
         >
        </ng-container>
        <ol kendoStepperList
            [stepType]='stepType'
            [linear]='linear'
            [orientation]='orientation'
            [steps]='steps'
            [currentStep]='currentStep'
            [successIcon]='successIcon'
            [errorIcon]='errorIcon'
            [indicatorTemplate]='indicatorTemplate?.templateRef'
            [labelTemplate]='labelTemplate?.templateRef'
            [stepTemplate]='stepTemplate?.templateRef'
            class='k-step-list'
            [class.k-step-list-horizontal]='isHorizontal'
            [class.k-step-list-vertical]='!isHorizontal'
            [ngStyle]='stepsListStyling'>
        </ol>

        <kendo-progressbar *ngIf='steps.length > 0'
            [attr.aria-hidden]='true'
            [animation]='progressAnimation'
            [max]='steps.length - 1'
            [label]='false'
            [orientation]='orientation'
            [reverse]='!isHorizontal'
            [value]='currentStep'
            [ngStyle]='progressBarStyling'>
        </kendo-progressbar>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef,
        LocalizationService,
        StepperService])
], StepperComponent);

/**
 * @hidden
 */
class StepperMessages extends ComponentMessages {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperMessages.prototype, "optional", void 0);

var StepperCustomMessagesComponent_1;
/**
 * Custom component messages override default component messages
 * ([see example]({% slug rtl_layout %})).
 */
let StepperCustomMessagesComponent = StepperCustomMessagesComponent_1 = class StepperCustomMessagesComponent extends StepperMessages {
    constructor(service) {
        super();
        this.service = service;
    }
    get override() {
        return true;
    }
};
StepperCustomMessagesComponent = StepperCustomMessagesComponent_1 = __decorate([
    Component({
        providers: [
            {
                provide: StepperMessages,
                useExisting: forwardRef(() => StepperCustomMessagesComponent_1)
            }
        ],
        selector: 'kendo-stepper-messages',
        template: ``
    }),
    __metadata("design:paramtypes", [LocalizationService])
], StepperCustomMessagesComponent);

var LocalizedStepperMessagesDirective_1;
/**
 * @hidden
 */
let LocalizedStepperMessagesDirective = LocalizedStepperMessagesDirective_1 = class LocalizedStepperMessagesDirective extends StepperMessages {
    constructor(service) {
        super();
        this.service = service;
    }
};
LocalizedStepperMessagesDirective = LocalizedStepperMessagesDirective_1 = __decorate([
    Directive({
        providers: [
            {
                provide: StepperMessages,
                useExisting: forwardRef(() => LocalizedStepperMessagesDirective_1)
            }
        ],
        selector: `
      [kendoStepperLocalizedMessages]
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], LocalizedStepperMessagesDirective);

/**
 * Displays images, icons or initials representing people or other entities.
 */
let AvatarComponent = class AvatarComponent {
    constructor(renderer, element) {
        this.renderer = renderer;
        this.element = element;
        this.hostClass = true;
        /**
         * Sets a border to the avatar.
         */
        this.border = false;
        this._themeColor = 'primary';
        this._size = 'medium';
        this._fillMode = 'solid';
        this._rounded = 'full';
        validatePackage(packageMetadata);
    }
    /**
     * @hidden
     */
    get borderClass() {
        return this.border;
    }
    /**
     * @hidden
     */
    get flexBasis() {
        return this.width;
    }
    /**
     * Sets the shape for the avatar.
     * @hidden
     */
    set shape(shape) {
        this.rounded = mapShapeToRounded(shape);
    }
    /**
     * Specifies the size of the avatar
     * ([see example]({% slug appearance_avatar %}#toc-size)).
     *
     * The possible values are:
     * * `small`
     * * `medium` (Default)
     * * `large`
     *
     */
    set size(size) {
        if (size !== this._size) {
            this.handleClasses('size', size);
            this._size = size === null ? null : size || 'medium';
        }
    }
    get size() {
        return this._size;
    }
    /**
     * Specifies the rounded styling of the avatar
     * ([see example]({% slug appearance_avatar %}#toc-rounded-corners)).
     *
     * The possible values are:
     * * `small`
     * * `medium`
     * * `large`
     * * `full` (Default)
     * * null
     *
     */
    set rounded(rounded) {
        if (rounded !== this._rounded) {
            this.handleClasses('rounded', rounded);
            this._rounded = rounded === null ? null : rounded || 'full';
        }
    }
    get rounded() {
        return this._rounded;
    }
    /**
     * Specifies the theme color of the avatar.
     * The theme color will be applied as background and border color, while also amending the text color accordingly.
     *
     * The possible values are:
     * * `base`&mdash; Applies the base coloring value.
     * * `primary` (Default)&mdash;Applies coloring based on primary theme color.
     * * `secondary`&mdash;Applies coloring based on secondary theme color.
     * * `tertiary`&mdash; Applies coloring based on tertiary theme color.
     * * `info`&mdash;Applies coloring based on info theme color.
     * * `success`&mdash; Applies coloring based on success theme color.
     * * `warning`&mdash; Applies coloring based on warning theme color.
     * * `error`&mdash; Applies coloring based on error theme color.
     * * `dark`&mdash; Applies coloring based on dark theme color.
     * * `light`&mdash; Applies coloring based on light theme color.
     * * `inverse`&mdash; Applies coloring based on inverted theme color.
     */
    set themeColor(themeColor) {
        if (themeColor !== this._themeColor) {
            this._themeColor = themeColor === null ? null : (themeColor || 'primary');
            this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
        }
    }
    get themeColor() {
        return this._themeColor;
    }
    /**
     * Specifies the appearance fill style of the avatar.
     *
     * The possible values are:
     * * `solid` (Default)
     * * `outline`
     * * null
     *
     */
    set fillMode(fillMode) {
        if (fillMode !== this.fillMode) {
            this._fillMode = fillMode === null ? null : (fillMode || 'solid');
            this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
        }
    }
    get fillMode() {
        return this._fillMode;
    }
    /**
     * Specifies the appearance fill style of the avatar.
     * Deprecated, left for backward compatibility.
     *
     * @hidden
     */
    set fill(fillMode) {
        this.fillMode = fillMode;
    }
    /**
     * @hidden
     */
    get avatarWidth() {
        return this.width;
    }
    /**
     * @hidden
     */
    get avatarHeight() {
        return this.height;
    }
    ngOnInit() {
        this.verifyProperties();
    }
    ngAfterViewInit() {
        const stylingInputs = ['size', 'rounded'];
        stylingInputs.forEach(input => {
            this.handleClasses(input, this[input]);
        });
        this.handleFillModeAndThemeColorClasses(this.fillMode, this.themeColor);
    }
    /**
     * @hidden
     */
    iconClasses() {
        if (this.icon) {
            return `k-icon k-i-${this.icon}`;
        }
        if (this.iconClass) {
            return `${this.iconClass}`;
        }
    }
    /**
     * @hidden
     */
    get customAvatar() {
        return !(this.imageSrc || this.initials || this.icon || this.iconClass);
    }
    verifyProperties() {
        if (!isDevMode()) {
            return;
        }
        const inputs = [this.icon || this.iconClass, this.imageSrc, this.initials];
        const inputsLength = inputs.filter((value) => value).length;
        if (inputsLength > 1) {
            throw new Error(`
                Invalid property configuration given.
                The kendo-avatar component can accept only one of:
                icon, imageSrc or initials properties.
            `);
        }
    }
    handleClasses(styleType, value) {
        const elem = this.element.nativeElement;
        const classes = getStylingClasses('avatar', styleType, this[styleType], value);
        if (classes.toRemove) {
            this.renderer.removeClass(elem, classes.toRemove);
        }
        if (classes.toAdd) {
            this.renderer.addClass(elem, classes.toAdd);
        }
    }
    handleFillModeAndThemeColorClasses(fill, themeColor) {
        const wrapperElement = this.element.nativeElement;
        // remove existing fill and theme color classes
        const currentClasses = Array.from(wrapperElement.classList);
        const classesToRemove = currentClasses.filter(cl => {
            return cl.startsWith('k-avatar-solid') || cl.startsWith('k-avatar-outline');
        });
        classesToRemove.forEach((cl => this.renderer.removeClass(wrapperElement, cl)));
        // add fill if needed
        if (fill !== null) {
            this.renderer.addClass(wrapperElement, `k-avatar-${fill}`);
        }
        // add theme color class if fill and theme color
        if (fill !== null && themeColor !== null) {
            this.renderer.addClass(wrapperElement, `k-avatar-${fill}-${themeColor}`);
        }
    }
};
__decorate([
    HostBinding('class.k-avatar'),
    __metadata("design:type", Boolean)
], AvatarComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-avatar-bordered'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], AvatarComponent.prototype, "borderClass", null);
__decorate([
    HostBinding('style.flexBasis'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], AvatarComponent.prototype, "flexBasis", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "shape", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "size", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "rounded", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "themeColor", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "fillMode", null);
__decorate([
    Input(),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [String])
], AvatarComponent.prototype, "fill", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], AvatarComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "iconClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "width", void 0);
__decorate([
    HostBinding('style.width'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], AvatarComponent.prototype, "avatarWidth", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "height", void 0);
__decorate([
    HostBinding('style.height'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], AvatarComponent.prototype, "avatarHeight", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], AvatarComponent.prototype, "cssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "initials", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], AvatarComponent.prototype, "imageSrc", void 0);
AvatarComponent = __decorate([
    Component({
        selector: 'kendo-avatar',
        template: `
        <ng-content *ngIf="customAvatar"></ng-content>

        <ng-container *ngIf="imageSrc">
            <span class="k-avatar-image">
                <img src="{{imageSrc}}" [ngStyle]="cssStyle" />
            </span>
        </ng-container>

        <ng-container *ngIf="initials">
            <span class="k-avatar-text" [ngStyle]="cssStyle">{{ initials.substring(0, 2) }}</span>
        </ng-container>

        <ng-container *ngIf="icon || iconClass">
            <span class="k-avatar-icon" [ngStyle]="cssStyle" [ngClass]="iconClasses()"></span>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [Renderer2, ElementRef])
], AvatarComponent);

/**
 * Represents the [Kendo UI Card component for Angular]({% slug overview_card %})
 */
let CardComponent = class CardComponent {
    constructor(localizationService) {
        this.localizationService = localizationService;
        this.hostClass = true;
        /**
         * Specifies the layout of the Card content.
         *
         * The possible values are:
         * * (Default) `vertical`
         * * `horizontal`
         *
         */
        this.orientation = 'vertical';
        /**
         * Defines the width of the Card.
         * Defaults to `285px`.
         */
        this.width = '285px';
        this.rtl = false;
        validatePackage(packageMetadata);
        this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
            this.direction = this.rtl ? 'rtl' : 'ltr';
        });
    }
    get widthStyle() {
        return this.width;
    }
    get vertical() {
        return this.orientation === 'vertical';
    }
    get horizontal() {
        return this.orientation === 'horizontal';
    }
    ngOnDestroy() {
        if (this.dynamicRTLSubscription) {
            this.dynamicRTLSubscription.unsubscribe();
        }
    }
};
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-card'),
    __metadata("design:type", Boolean)
], CardComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('style.width'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], CardComponent.prototype, "widthStyle", null);
__decorate([
    HostBinding('class.k-card-vertical'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardComponent.prototype, "vertical", null);
__decorate([
    HostBinding('class.k-card-horizontal'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardComponent.prototype, "horizontal", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], CardComponent.prototype, "direction", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CardComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CardComponent.prototype, "width", void 0);
CardComponent = __decorate([
    Component({
        selector: 'kendo-card',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.card.component'
            }
        ],
        template: `
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService])
], CardComponent);

/**
 * Specifies the content in the Card header.
 */
let CardHeaderComponent = class CardHeaderComponent {
    /**
     * Specifies the content in the Card header.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-header'),
    __metadata("design:type", Boolean)
], CardHeaderComponent.prototype, "hostClass", void 0);
CardHeaderComponent = __decorate([
    Component({
        selector: 'kendo-card-header',
        template: `
        <ng-content></ng-content>
    `
    })
], CardHeaderComponent);

/**
 * Specifies the content in the Card body.
 */
let CardBodyComponent = class CardBodyComponent {
    /**
     * Specifies the content in the Card body.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-body'),
    __metadata("design:type", Boolean)
], CardBodyComponent.prototype, "hostClass", void 0);
CardBodyComponent = __decorate([
    Component({
        selector: 'kendo-card-body',
        template: `
        <ng-content></ng-content>
    `
    })
], CardBodyComponent);

/**
 * Specifies the content in the Card footer.
 */
let CardFooterComponent = class CardFooterComponent {
    /**
     * Specifies the content in the Card footer.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-footer'),
    __metadata("design:type", Boolean)
], CardFooterComponent.prototype, "hostClass", void 0);
CardFooterComponent = __decorate([
    Component({
        selector: 'kendo-card-footer',
        template: `
        <ng-content></ng-content>
    `
    })
], CardFooterComponent);

/**
 * Specifies the action buttons of the Card.
 * * ([see example]({% slug actions_card %})).
 */
let CardActionsComponent = class CardActionsComponent {
    /**
     * Specifies the action buttons of the Card.
     * * ([see example]({% slug actions_card %})).
     */
    constructor() {
        this.hostClass = true;
        /**
         * Specifies the layout of the Card action buttons.
         *
         * * The possible values are:
         * * (Default) `horizontal`
         * * `vertical`
         *
         */
        this.orientation = 'horizontal';
        /**
         * Specifies the layout of the Card action buttons.
         *
         * The possible values are:
         * * (Default) `start`
         * * `center`
         * * `end`
         * * `stretched`
         *
         */
        this.layout = 'start';
        /**
         * Fires when the user clicks an action button.
         */
        this.action = new EventEmitter();
    }
    get stretchedClass() {
        return this.layout === 'stretched';
    }
    get startClass() {
        return this.layout === 'start';
    }
    get endClass() {
        return this.layout === 'end';
    }
    get centerClass() {
        return this.layout === 'center';
    }
    get verticalClass() {
        return this.orientation === 'vertical';
    }
    get horizontalClass() {
        return this.orientation === 'horizontal';
    }
    /**
     * @hidden
     */
    onClick(action) {
        this.action.emit(action);
    }
    /**
     * @hidden
     */
    actionTemplate() {
        return this.actions instanceof TemplateRef;
    }
};
__decorate([
    HostBinding('class.k-card-actions'),
    __metadata("design:type", Boolean)
], CardActionsComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-card-actions-stretched'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "stretchedClass", null);
__decorate([
    HostBinding('class.k-card-actions-start'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "startClass", null);
__decorate([
    HostBinding('class.k-card-actions-end'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "endClass", null);
__decorate([
    HostBinding('class.k-card-actions-center'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "centerClass", null);
__decorate([
    HostBinding('class.k-card-actions-vertical'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "verticalClass", null);
__decorate([
    HostBinding('class.k-card-actions-horizontal'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardActionsComponent.prototype, "horizontalClass", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], CardActionsComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CardActionsComponent.prototype, "layout", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], CardActionsComponent.prototype, "actions", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], CardActionsComponent.prototype, "action", void 0);
CardActionsComponent = __decorate([
    Component({
        selector: 'kendo-card-actions',
        template: `
        <ng-content *ngIf="!actions"></ng-content>

        <ng-container *ngIf="!actionTemplate()">
            <button type="button"
                class="k-button"
                [class.k-primary]="action.primary"
                [class.k-flat]="action.flat"
                (click)="onClick(action)"
                *ngFor="let action of actions"
            >
                {{ action.text }}
            </button>
        </ng-container>

        <ng-template [ngTemplateOutlet]="actions" *ngIf="actionTemplate()"></ng-template>
    `
    })
], CardActionsComponent);

/**
 * Specifies a separator in the content of the Card.
 */
let CardSeparatorDirective = class CardSeparatorDirective {
    /**
     * Specifies a separator in the content of the Card.
     */
    constructor() {
        this.hostClass = true;
        /**
         * Specifies the orientation of the Card separator.
         *
         * The possible values are:
         * (Default) `horizontal`
         * `vertical`
         */
        this.orientation = 'horizontal';
    }
    get verticalClass() {
        return this.orientation === 'vertical';
    }
    get horizontalClass() {
        return this.orientation === 'horizontal';
    }
};
__decorate([
    HostBinding('class.k-card-separator'),
    __metadata("design:type", Boolean)
], CardSeparatorDirective.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-separator-vertical'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardSeparatorDirective.prototype, "verticalClass", null);
__decorate([
    HostBinding('class.k-separator-horizontal'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], CardSeparatorDirective.prototype, "horizontalClass", null);
__decorate([
    HostBinding('style.color'),
    Input(),
    __metadata("design:type", String)
], CardSeparatorDirective.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], CardSeparatorDirective.prototype, "orientation", void 0);
CardSeparatorDirective = __decorate([
    Directive({
        selector: '[kendoCardSeparator]'
    })
], CardSeparatorDirective);

/**
 * Specifies the text and styles for the title of the Card.
 */
let CardTitleDirective = class CardTitleDirective {
    /**
     * Specifies the text and styles for the title of the Card.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-title'),
    __metadata("design:type", Boolean)
], CardTitleDirective.prototype, "hostClass", void 0);
CardTitleDirective = __decorate([
    Directive({
        selector: '[kendoCardTitle]'
    })
], CardTitleDirective);

/**
 * Specifies the text and styles for the subtitle of the Card.
 */
let CardSubtitleDirective = class CardSubtitleDirective {
    /**
     * Specifies the text and styles for the subtitle of the Card.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-subtitle'),
    __metadata("design:type", Boolean)
], CardSubtitleDirective.prototype, "hostClass", void 0);
CardSubtitleDirective = __decorate([
    Directive({
        selector: '[kendoCardSubtitle]'
    })
], CardSubtitleDirective);

/**
 * Specifies any media that will be displayed and aligned in the Card.
 */
let CardMediaDirective = class CardMediaDirective {
    /**
     * Specifies any media that will be displayed and aligned in the Card.
     */
    constructor() {
        this.hostClass = true;
    }
};
__decorate([
    HostBinding('class.k-card-media'),
    __metadata("design:type", Boolean)
], CardMediaDirective.prototype, "hostClass", void 0);
CardMediaDirective = __decorate([
    Directive({
        selector: '[kendoCardMedia]'
    })
], CardMediaDirective);

/**
 * The settings of the Card action buttons.
 */
class CardAction {
}

/**
 * Represents a template that defines the content of the ExpansionPanel title.
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoExpansionPanelTitleDirective` directive inside the `<kendo-expansionpanel>` tag.
 * ([see example]({% slug title_expansionpanel %}#toc-title-template)).
 */
let ExpansionPanelTitleDirective = class ExpansionPanelTitleDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ExpansionPanelTitleDirective = __decorate([
    Directive({
        selector: '[kendoExpansionPanelTitleDirective]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], ExpansionPanelTitleDirective);

/**
 * @hidden
 */
function expand(duration, height) {
    return [
        style({ overflow: 'hidden', display: 'block', height: 0 }),
        animate(`${duration}ms ease-in`, style({ height: `${height}` }))
    ];
}
/**
 * @hidden
 */
function collapse(duration, height) {
    return [
        style({ overflow: 'hidden', height: `${height}` }),
        animate(`${duration}ms ease-in`, style({ overflow: 'hidden', height: 0 }))
    ];
}

/**
 * Arguments for the `action` event of the ExpansionPanel.
 */
class ExpansionPanelActionEvent extends PreventableEvent$1 {
}

const DEFAULT_DURATION = 200;
const CONTENT_HIDDEN_CLASS = 'k-hidden';
/**
 * Represents the [Kendo UI ExpansionPanel component for Angular]({% slug overview_expansionpanel %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-expansionpanel title="Chile" subtitle="South America">
 *              There are various theories about the origin of the word Chile.
 *        </kendo-expansionpanel>
 *    `
 * })
 * class AppComponent {}
 * ```
 */
let ExpansionPanelComponent = class ExpansionPanelComponent {
    constructor(renderer, hostElement, ngZone, localizationService, builder) {
        this.renderer = renderer;
        this.hostElement = hostElement;
        this.ngZone = ngZone;
        this.localizationService = localizationService;
        this.builder = builder;
        /**
         * Specifies the primary text in the header of the ExpansionPanel
         * ([see example]({% slug title_expansionpanel %}#toc-title-and-subtitle)).
         */
        this.title = '';
        /**
         * Specifies the secondary text in the header of the ExpansionPanel, which is rendered next to the collapse/expand icon
         * ([see example]({% slug title_expansionpanel %}#toc-title-and-subtitle)).
         */
        this.subtitle = '';
        /**
         * Specifies whether the ExpansionPanel is disabled. If disabled, the ExpansionPanel can be neither expanded nor collapsed
         * ([see example]({% slug disabled_expansionpanel %})).
         *
         * @default false
         */
        this.disabled = false;
        /**
         * Specifies the animation settings of the ExpansionPanel
         * ([see example]({% slug animations_expansionpanel %})).
         *
         * The possible values are:
         * * Boolean
         *    * (Default) `true` Numeric values represent duration. Default duration is 200ms.
         *    * false
         * * Number
         */
        this.animation = true;
        /**
         * Fires when the `expanded` property of the component is updated.
         * Used to provide a two-way binding for the `expanded` property
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.expandedChange = new EventEmitter();
        /**
         * Fires when the expanded state of the ExpansionPanel is about to change. This event is preventable
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.action = new EventEmitter();
        /**
         * Fires when the ExpansionPanel is expanded. If there is animation it will fire when the animation is complete
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.expand = new EventEmitter();
        /**
         * Fires when the ExpansionPanel is collapsed. If there is animation it will fire when the animation is complete
         * ([see example]({% slug overview_expansionpanel %}#toc-events)).
         */
        this.collapse = new EventEmitter();
        this.hostClass = true;
        this.tabindex = 0;
        /**
         * @hidden
         */
        this.focused = false;
        this.animationEnd = new EventEmitter();
        this.subscriptions = new Subscription();
        this._expanded = false;
        validatePackage(packageMetadata);
        this.direction = localizationService.rtl ? 'rtl' : 'ltr';
    }
    /**
     * Specifies whether the ExpansionPanel is expanded. The property supports two-way binding.
     * ([see example]({% slug interaction_expansionpanel %}#toc-setting-the-initial-state)).
     *
     * @default false
     */
    set expanded(value) {
        if (value === this.expanded) {
            return;
        }
        this._expanded = value;
        if (this.expanded) {
            this.removeContentHiddenClass();
        }
        else {
            this.addContentHiddenClass();
        }
    }
    get expanded() {
        return this._expanded;
    }
    get expandedClass() {
        return this.expanded && !this.disabled;
    }
    get focusClass() {
        return this.focused;
    }
    get disabledClass() {
        return this.disabled;
    }
    /**
     * @hidden
     */
    onComponentBlur() {
        if (this.focused) {
            this.focused = false;
        }
    }
    /**
     * @hidden
     */
    onComponentFocus() {
        if (!this.focused) {
            this.focused = true;
        }
    }
    ngOnInit() {
        this.renderer.removeAttribute(this.hostElement.nativeElement, 'title');
        this.subscriptions = this.localizationService.changes.subscribe(({ rtl }) => { this.direction = rtl ? 'rtl' : 'ltr'; });
    }
    ngAfterViewInit() {
        this.initDomEvents();
        if (!this.expanded) {
            this.renderer.addClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    /**
     * @hidden
     */
    initDomEvents() {
        if (!this.hostElement) {
            return;
        }
        if (!this.disabled) {
            this.ngZone.runOutsideAngular(() => {
                const nativeElement = this.hostElement.nativeElement;
                this.subscriptions.add(this.renderer.listen(nativeElement, 'keydown', this.keyDownHandler.bind(this)));
            });
        }
    }
    /**
     * @hidden
     */
    keyDownHandler(ev) {
        const isEnterOrSpace = ev.keyCode === Keys.Enter || ev.keyCode === Keys.Space;
        if (!isEnterOrSpace) {
            return;
        }
        if (hasClass(ev.target, 'k-expander')) {
            ev.preventDefault();
            this.ngZone.run(() => {
                this.onHeaderAction();
            });
        }
    }
    /**
     * @hidden
     */
    onHeaderClick(ev) {
        if (!isFocusable(ev.target) && !this.disabled) {
            this.onHeaderAction();
        }
    }
    /**
     * @hidden
     */
    onHeaderAction() {
        this.focused = true;
        const eventArgs = new ExpansionPanelActionEvent();
        eventArgs.action = this.expanded ? 'collapse' : 'expand';
        this.action.emit(eventArgs);
        if (!eventArgs.isDefaultPrevented()) {
            this.setExpanded(!this.expanded);
            if (this.expanded) {
                this.removeContentHiddenClass();
            }
            if (this.animation) {
                this.animateContent();
                return;
            }
            if (!this.expanded) {
                this.addContentHiddenClass();
            }
            this.emitExpandCollapseEvent();
        }
    }
    /**
     * @hidden
     */
    get indicatorClasses() {
        if (this.expanded) {
            return this.collapseIcon ? this.collapseIcon : `k-icon k-i-arrow-chevron-up`;
        }
        else {
            return this.expandIcon ? this.expandIcon : `k-icon k-i-arrow-chevron-down`;
        }
    }
    /**
     * Toggles the visibility of the ExpansionPanel
     * ([see example]({% slug interaction_expansionpanel %}#toc-toggling-the-content)).
     *
     * @param expanded? - Boolean. Specifies, whether the ExpansionPanel will be expanded or collapsed.
     */
    toggle(expanded) {
        const previous = this.expanded;
        const current = isPresent(expanded) ? expanded : !previous;
        if (current === previous) {
            return;
        }
        this.setExpanded(current);
        if (this.expanded) {
            this.removeContentHiddenClass();
        }
        if (this.animation) {
            this.animateContent();
            return;
        }
        if (!this.expanded) {
            this.addContentHiddenClass();
        }
        this.emitExpandCollapseEvent();
    }
    setExpanded(value) {
        this._expanded = value;
        this.expandedChange.emit(value);
    }
    animateContent() {
        const duration = typeof this.animation === 'boolean' ? DEFAULT_DURATION : this.animation;
        const contentHeight = getComputedStyle(this.content.nativeElement).height;
        const animation = this.expanded ? expand(duration, contentHeight) : collapse(duration, contentHeight);
        const player = this.createPlayer(animation, this.content.nativeElement);
        this.animationEnd.pipe(take(1)).subscribe(() => {
            if (!this.expanded) {
                this.addContentHiddenClass();
            }
            this.emitExpandCollapseEvent();
        });
        player.play();
    }
    createPlayer(animation, animatedElement) {
        const factory = this.builder.build(animation);
        let player = factory.create(animatedElement);
        player.onDone(() => {
            if (player) {
                this.animationEnd.emit();
                player.destroy();
                player = null;
            }
        });
        return player;
    }
    emitExpandCollapseEvent() {
        this[this.expanded ? 'expand' : 'collapse'].emit();
    }
    addContentHiddenClass() {
        this.renderer.addClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
    }
    removeContentHiddenClass() {
        this.renderer.removeClass(this.content.nativeElement, CONTENT_HIDDEN_CLASS);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ExpansionPanelComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ExpansionPanelComponent.prototype, "subtitle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ExpansionPanelComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], ExpansionPanelComponent.prototype, "expanded", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], ExpansionPanelComponent.prototype, "expandIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ExpansionPanelComponent.prototype, "collapseIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ExpansionPanelComponent.prototype, "animation", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ExpansionPanelComponent.prototype, "expandedChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ExpansionPanelComponent.prototype, "action", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ExpansionPanelComponent.prototype, "expand", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ExpansionPanelComponent.prototype, "collapse", void 0);
__decorate([
    ContentChild(ExpansionPanelTitleDirective, { static: false }),
    __metadata("design:type", ExpansionPanelTitleDirective)
], ExpansionPanelComponent.prototype, "titleTemplate", void 0);
__decorate([
    ViewChild('content', { static: true }),
    __metadata("design:type", ElementRef)
], ExpansionPanelComponent.prototype, "content", void 0);
__decorate([
    HostBinding('class.k-expander'),
    __metadata("design:type", Boolean)
], ExpansionPanelComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('attr.aria-expanded'),
    HostBinding('class.k-expanded'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ExpansionPanelComponent.prototype, "expandedClass", null);
__decorate([
    HostBinding('class.k-state-focus'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ExpansionPanelComponent.prototype, "focusClass", null);
__decorate([
    HostBinding('attr.aria-disabled'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ExpansionPanelComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], ExpansionPanelComponent.prototype, "direction", void 0);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number)
], ExpansionPanelComponent.prototype, "tabindex", void 0);
__decorate([
    HostListener('blur'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ExpansionPanelComponent.prototype, "onComponentBlur", null);
__decorate([
    HostListener('focus'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ExpansionPanelComponent.prototype, "onComponentFocus", null);
ExpansionPanelComponent = __decorate([
    Component({
        exportAs: 'kendoExpansionPanel',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.expansionpanel'
            }
        ],
        selector: 'kendo-expansionpanel',
        template: `
        <div
            [class.k-expander-header]="true"
            (click)="onHeaderClick($event)">
            <ng-container *ngIf="!titleTemplate">
                <div *ngIf="title" class="k-expander-title">{{ title }}</div>
                <span class="k-spacer"></span>
                <div *ngIf="subtitle" class="k-expander-sub-title">
                    {{ subtitle }}
                </div>
            </ng-container>
            <ng-template
                *ngIf="titleTemplate"
                [ngTemplateOutlet]="titleTemplate?.templateRef">
            </ng-template>
            <span class="k-expander-indicator">
                <span [ngClass]="indicatorClasses"></span>
            </span>
        </div>
        <div #content class="k-expander-content-wrapper">
            <div  class="k-expander-content" [attr.aria-hidden]="!expanded">
                <ng-content></ng-content>
            </div>
        </div>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef,
        NgZone,
        LocalizationService,
        AnimationBuilder])
], ExpansionPanelComponent);

/**
 * @hidden
 */
const normalizeValue = (value) => {
    return value ? +value : undefined;
};
/**
 * @hidden
 */
const isRowItemPresent = (items) => {
    return items.some(item => isPresent(item.row));
};
/**
 * @hidden
 */
const propsChanged = (initialProps, newProps) => {
    for (let i = 0; i < initialProps.length; i++) {
        if (initialProps[i] !== newProps[i]) {
            return true;
        }
    }
    return false;
};
/**
 * @hidden
 *
 * Extracts the event target from the viewport coords. Required for touch devices
 * where the `event.target` of a `pointermove` event is always the initially dragged item.
 */
const getDropTarget = (event) => {
    if (!(isDocumentAvailable() && isPresent(document.elementsFromPoint))) {
        return [event.target];
    }
    return document.elementsFromPoint(event.clientX, event.clientY)
        .filter(item => item.classList && (item.classList.contains('k-tilelayout-item') || item.classList.contains('k-layout-item-hint')));
};
/**
 * @hidden
 */
const setElementStyles = (renderer, elem, styles) => {
    const props = Object.keys(styles);
    props.forEach(p => {
        renderer.setStyle(elem, p, styles[p]);
    });
};
/**
 * @hidden
 */
const calculateCellFromPosition = (coordinates, tileLayoutElement, gap, cellSize, columns, rtl = false) => {
    const tileLayoutRect = tileLayoutElement.getBoundingClientRect();
    const totalRows = (tileLayoutRect.height - gap.rows) / (cellSize.height + gap.rows);
    const distanceFromTop = coordinates.y - tileLayoutRect.y;
    const distanceFromLeft = coordinates.x - tileLayoutRect.x;
    const distanceFromRight = tileLayoutRect.right - coordinates.x;
    const distanceToCellWidthRatio = Math.floor((rtl ? distanceFromRight : distanceFromLeft) / (gap.columns + cellSize.width));
    const cappedColumnPosition = Math.min(distanceToCellWidthRatio + 1, columns);
    const distanceToCellHeightRatio = Math.floor(distanceFromTop / (gap.rows + cellSize.height));
    const cappedRowPosition = Math.min(distanceToCellHeightRatio + 1, totalRows);
    const col = Math.max(cappedColumnPosition, 0);
    const row = Math.max(cappedRowPosition, 0);
    return {
        row, col
    };
};

/**
 * @hidden
 */
const RESIZE_HANDLE_UNIDIRECTIONAL_SIZE = 9;
/**
 * @hidden
 */
const RESIZE_HANDLE_BIDIRECTIONAL_SIZE = 25;
/**
 * @hidden
 */
const RESIZE_HANDLE_UNIDIRECTIONAL_OVERLAP = 5;
/**
 * @hidden
 */
const RESIZE_HANDLE_BIDIRECTIONAL_OVERLAP = 18;
/**
 * @hidden
 */
const DRAGGED_ZINDEX = 10;
/**
 * @hidden
 */
const RESIZE_DIRECTIONS = ['ew', 'ns', 'nwse'];
/**
 * @hidden
 */
const RTL_RESIZE_DIRECTIONS = ['ew', 'ns', 'nesw'];
/**
 * @hidden
 */
const RESIZE_HANDLE_DIMENSIONS = {
    // numeric values represent pixels
    ew: {
        width: RESIZE_HANDLE_UNIDIRECTIONAL_SIZE,
        overlapX: RESIZE_HANDLE_UNIDIRECTIONAL_OVERLAP
    },
    ns: {
        height: RESIZE_HANDLE_UNIDIRECTIONAL_SIZE,
        overlapY: RESIZE_HANDLE_UNIDIRECTIONAL_OVERLAP
    },
    nwse: {
        width: RESIZE_HANDLE_BIDIRECTIONAL_SIZE,
        height: RESIZE_HANDLE_BIDIRECTIONAL_SIZE,
        overlapX: RESIZE_HANDLE_BIDIRECTIONAL_OVERLAP,
        overlapY: RESIZE_HANDLE_BIDIRECTIONAL_OVERLAP
    },
    nesw: {
        width: RESIZE_HANDLE_BIDIRECTIONAL_SIZE,
        height: RESIZE_HANDLE_BIDIRECTIONAL_SIZE,
        overlapX: RESIZE_HANDLE_BIDIRECTIONAL_OVERLAP,
        overlapY: RESIZE_HANDLE_BIDIRECTIONAL_OVERLAP
    }
};
/**
 * @hidden
 */
const OVERLAP_THRESHOLD = 0.3;
/**
 * @hidden
 */
const REVERSE_OVERLAP_THRESHOLD = -0.7;
/**
 * @hidden
 */
const HINT_BORDERS_HEIGHT = 2;

/**
 * @hidden
 */
class PreventableEvent$2 {
    constructor() {
        this.prevented = false;
    }
    /**
     * Prevents the default action for a specified event.
     * In this way, the source component suppresses the built-in behavior that follows the event.
     */
    preventDefault() {
        this.prevented = true;
    }
    /**
     * If the event is prevented by any of its subscribers, returns `true`.
     *
     * @returns `true` if the default action was prevented. Otherwise, returns `false`.
     */
    isDefaultPrevented() {
        return this.prevented;
    }
}

/**
 * Arguments for the `reorder` event. The event fires when the order or starting
 * positions of the items is changed via the UI. If you prevent the event, the change is canceled ([see example]({% slug reordering_tilelayout %}#toc-handling-the-reorder-event)).
 */
class TileLayoutReorderEvent extends PreventableEvent$2 {
    /**
     * Constructs the event arguments for the `reorder` event.
     * @param item - The TileLayoutItem being reordered.
     * @param items - The TileLayoutItem collection that holds the currently rendered items and their internal state.
     * @param newIndex - The new order index of the reordered item used to determine its positioning relative to the other items.
     * @param oldIndex - The initial order index of the reordered item used to determine its positioning relative to the other items.
     * @param newCol - The new start column of the reordered item.
     * @param oldCol - The initial start column of the reordered item.
     * @param newRow - The new start row of the reordered item.
     * @param oldRow - The initial start row of the reordered item.
     * @hidden
     */
    constructor(item, items, newIndex, oldIndex, newCol, oldCol, newRow, oldRow) {
        super();
        this.item = item;
        this.items = items;
        this.newIndex = newIndex;
        this.oldIndex = oldIndex;
        this.newCol = newCol;
        this.oldCol = oldCol;
        this.newRow = newRow;
        this.oldRow = oldRow;
    }
}

/**
 * Arguments for the `resize` event. The `resize` event fires when any item size
 * is changed from the UI. If you cancel the event, the change is prevented ([see example]({% slug resizing_tilelayout %}#toc-handling-the-resize-event)).
 */
class TileLayoutResizeEvent extends PreventableEvent$2 {
    /**
     * Constructs the event arguments for the `resize` event.
     * @param item - The TileLayoutItem being resized
     * @param items - The TileLayoutItem collection
     * @param newRowSpan - The new rowSpan of the resized item
     * @param oldRowSpan - The initial rowSpan of the resized item
     * @param newColSpan - The new colSpan of the resized item
     * @param oldColSpan - The initial colSpan of the resized item
     * @hidden
     */
    constructor(item, items, newRowSpan, oldRowSpan, newColSpan, oldColSpan) {
        super();
        this.item = item;
        this.items = items;
        this.newRowSpan = newRowSpan;
        this.oldRowSpan = oldRowSpan;
        this.newColSpan = newColSpan;
        this.oldColSpan = oldColSpan;
    }
}

/**
 * @hidden
 */
let TileLayoutDraggingService = class TileLayoutDraggingService {
    constructor(zone, renderer, localization) {
        this.zone = zone;
        this.renderer = renderer;
        this.localization = localization;
        this.reorderable = new BehaviorSubject(null);
        this.resizable = new BehaviorSubject(null);
        this.reorder = new Subject();
        this.resize = new Subject();
        this.lastDragCursorOffset = {
            x: 0,
            y: 0
        };
        this.localizationSubscription = this.localization.changes.subscribe(({ rtl }) => this.rtl = rtl);
    }
    get colStart() {
        return this.currentColStart;
    }
    get rowStart() {
        return this.currentRowStart;
    }
    get itemWrapper() {
        return this.draggedItemWrapper;
    }
    get order() {
        return this.targetOrder;
    }
    ngOnDestroy() {
        this.localizationSubscription.unsubscribe();
    }
    handlePress(originalEvent) {
        const resizing = !!originalEvent.target.classList.contains('k-resize-handle');
        const closestTile = closestInScope(originalEvent.target, el => el.classList.contains('k-tilelayout-item'), this.tileLayoutSettings.tileLayoutElement);
        const closestHeader = closestInScope(originalEvent.target, el => el.classList.contains('k-tilelayout-item-header'), this.tileLayoutSettings.tileLayoutElement);
        if (!closestTile) {
            return;
        }
        this.zone.run(() => {
            this.draggedItemWrapper = closestTile;
            this.draggedItem = this.tileLayoutSettings.items
                .find(item => item.order === +closestTile.style.order);
        });
        const reordering = !resizing && this.reorderable.getValue() && this.draggedItem.reorderable && closestHeader;
        if (!(reordering || resizing)) {
            return;
        }
        else {
            originalEvent.preventDefault();
        }
        this.zone.run(() => {
            this.reordering = reordering;
            this.resizing = resizing;
        });
        const tileRect = this.draggedItemWrapper.getBoundingClientRect();
        this.zone.run(() => {
            this.offset = {
                top: originalEvent.clientY - tileRect.top,
                left: originalEvent.clientX - tileRect.left,
                x: tileRect.x,
                y: tileRect.y,
                width: tileRect.width,
                height: tileRect.height
            };
            this.targetSize = {
                rowSpan: this.draggedItem.rowSpan,
                colSpan: this.draggedItem.colSpan
            };
            this.cellSize = {
                width: (tileRect.width - ((this.targetSize.colSpan - 1) * this.tileLayoutSettings.gap.columns)) / this.targetSize.colSpan,
                height: (tileRect.height - ((this.targetSize.rowSpan - 1) * this.tileLayoutSettings.gap.rows)) / this.targetSize.rowSpan
            };
            this.lastDragCursorOffset = {
                x: originalEvent.clientX,
                y: originalEvent.clientY
            };
        });
        setElementStyles(this.renderer, this.draggedItemWrapper, {
            left: tileRect.left + window.pageXOffset + 'px',
            top: tileRect.top + window.pageYOffset + 'px',
            width: tileRect.width + 'px',
            height: tileRect.height + 'px',
            zIndex: DRAGGED_ZINDEX
        });
        setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
            display: 'flex',
            height: (tileRect.height - HINT_BORDERS_HEIGHT) + 'px'
        });
        this.zone.run(() => this.targetOrder = this.draggedItem.order);
        setElementStyles(this.renderer, this.draggedItemWrapper, {
            position: 'absolute'
        });
        if (this.reorderable.getValue() && !resizing) {
            this.zone.run(() => {
                this.currentColStart = this.draggedItem.colStart;
                this.currentRowStart = this.draggedItem.rowStart;
            });
            const headerEl = this.draggedItem.elem.nativeElement.querySelector('.k-tilelayout-item-header');
            this.renderer.addClass(headerEl, 'k-cursor-grabbing');
        }
        else if (this.resizable && resizing) {
            this.zone.run(() => {
                this.startingPoint = {
                    top: originalEvent.clientY,
                    left: originalEvent.clientX
                };
                this.currentResizingColSpan = this.draggedItem.colSpan;
                this.currentResizingRowSpan = this.draggedItem.rowSpan;
                if (this.draggedItem.col) {
                    this.currentColStart = this.draggedItem.col.toString();
                }
                if (this.draggedItem.row) {
                    this.currentRowStart = this.draggedItem.row.toString();
                }
                this.direction = originalEvent.target.classList[1];
            });
        }
    }
    handleDrag(originalEvent) {
        if (this.draggedItemWrapper) {
            if (this.reordering) {
                this.reorderItems(originalEvent);
            }
            else if (this.resizing) {
                this.resizeItem(originalEvent);
            }
            this.lastDragCursorOffset = {
                x: originalEvent.clientX,
                y: originalEvent.clientY
            };
        }
    }
    handleRelease(originalEvent) {
        originalEvent.preventDefault();
        if (this.reordering) {
            const initialOrder = this.draggedItem.order;
            const initialCol = this.draggedItem.col;
            const initialRow = this.draggedItem.row;
            const targetCol = normalizeValue(this.currentColStart);
            const targetRow = normalizeValue(this.currentRowStart);
            if (propsChanged([this.targetOrder, targetCol, targetRow], [initialOrder, initialCol, initialRow])) {
                const reorderEvent = new TileLayoutReorderEvent(this.draggedItem, this.tileLayoutSettings.items, this.targetOrder, initialOrder, normalizeValue(this.currentColStart), initialCol, targetRow, initialRow);
                this.reorder.next(reorderEvent);
                if (!reorderEvent.isDefaultPrevented()) {
                    if (this.targetOrder > initialOrder) {
                        this.zone.run(() => {
                            for (let i = initialOrder + 1; i <= this.targetOrder; i++) {
                                this.tileLayoutSettings.items.find(item => item.order === i).order = i - 1;
                            }
                        });
                    }
                    else {
                        this.zone.run(() => {
                            for (let i = this.targetOrder; i < initialOrder; i++) {
                                this.tileLayoutSettings.items.find(item => item.order === i).order = i + 1;
                            }
                        });
                    }
                    this.draggedItem.order = this.targetOrder;
                    if (this.draggedItem.col) {
                        this.draggedItem.col = +this.currentColStart;
                    }
                    if (this.draggedItem.row) {
                        this.draggedItem.row = +this.currentRowStart;
                    }
                }
            }
            this.tileLayoutSettings.tileLayoutElement.appendChild(this.tileLayoutSettings.hintElement);
            this.zone.run(() => this.cleanUp());
        }
        else if (!this.reordering && this.resizing) {
            const initialRowSpan = this.draggedItem.rowSpan;
            const initialColSpan = this.draggedItem.colSpan;
            const { targetColSpan, targetRowSpan } = isRowItemPresent(this.tileLayoutSettings.items) ?
                this.targetSpan() :
                { targetColSpan: this.currentResizingColSpan, targetRowSpan: this.currentResizingRowSpan };
            if (propsChanged([initialRowSpan, initialColSpan], [targetRowSpan, targetColSpan])) {
                const resizeEvent = new TileLayoutResizeEvent(this.draggedItem, this.tileLayoutSettings.items, targetRowSpan, initialRowSpan, targetColSpan, initialColSpan);
                this.resize.next(resizeEvent);
                if (!resizeEvent.isDefaultPrevented()) {
                    this.draggedItem.colSpan = this.currentResizingColSpan;
                    this.draggedItem.rowSpan = this.currentResizingRowSpan;
                }
            }
            this.zone.run(() => this.cleanUp());
        }
    }
    reorderItems(event) {
        const targets = getDropTarget(event);
        const closestTile = targets.find(t => t !== this.draggedItemWrapper);
        let tileOrder = closestTile ? +closestTile.style.order : +this.draggedItemWrapper.style.order;
        if (this.tileLayoutSettings.autoFlow !== 'none') {
            const deltaX = event.clientX - this.lastDragCursorOffset.x;
            const deltaY = event.clientY - this.lastDragCursorOffset.y;
            const directionX = deltaX > 0 ? 'right' : deltaX < 0 ? 'left' : undefined;
            const directionY = deltaY > 0 ? 'down' : deltaX < 0 ? 'up' : undefined;
            const rect = this.draggedItemWrapper.getBoundingClientRect();
            const horizontalGap = this.tileLayoutSettings.gap.columns;
            const verticalGap = this.tileLayoutSettings.gap.rows;
            if (directionX && this.draggedItem.col) {
                const { col } = calculateCellFromPosition({
                    x: directionX === 'right' ? rect.right - horizontalGap : rect.left + horizontalGap,
                    y: event.clientY
                }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
                const targetStartCol = this.getTargetCol(col, directionX);
                this.currentColStart = targetStartCol.toString();
            }
            if (directionY && this.draggedItem.row) {
                const { row } = calculateCellFromPosition({
                    x: event.clientX,
                    y: directionY === 'down' ? rect.bottom - verticalGap : rect.top + verticalGap
                }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
                const targetStartRow = this.getTargetRow(row, directionY);
                this.currentRowStart = targetStartRow.toString();
            }
        }
        const hintBefore = tileOrder < this.targetOrder;
        const hintAfter = tileOrder > this.targetOrder;
        this.zone.run(() => this.targetOrder = tileOrder);
        if (hintBefore) {
            this.tileLayoutSettings.tileLayoutElement
                .insertBefore(this.tileLayoutSettings.hintElement, this.tileLayoutSettings.tileLayoutElement.firstChild);
        }
        else if (hintAfter) {
            this.tileLayoutSettings.tileLayoutElement.appendChild(this.tileLayoutSettings.hintElement);
        }
        setElementStyles(this.renderer, this.draggedItemWrapper, {
            top: (event.pageY - this.offset.top) + 'px',
            left: (event.pageX - this.offset.left) + 'px'
        });
    }
    resizeItem(event) {
        setElementStyles(this.renderer, this.tileLayoutSettings.tileLayoutElement, {
            cursor: this.direction.split('k-cursor-')[1]
        });
        const currentWidth = this.rtl ?
            this.offset.width + (this.offset.x - event.clientX) :
            this.offset.width + (event.clientX - this.startingPoint.left);
        const currentHeight = this.offset.height + (event.clientY - this.startingPoint.top);
        const hintRect = this.tileLayoutSettings.hintElement.getBoundingClientRect();
        const hintWidth = hintRect.width;
        const hintHeight = hintRect.height;
        const horizontalDragDirection = event.clientX - this.lastDragCursorOffset.x;
        const verticalDragDirection = event.clientY - this.lastDragCursorOffset.y;
        const startCol = this.draggedItem.col ? this.draggedItem.col : calculateCellFromPosition({
            x: this.rtl ? hintRect.right : hintRect.x,
            y: hintRect.y
        }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl).col;
        const maxWidth = (this.tileLayoutSettings.columns - startCol) * (this.cellSize.width + this.tileLayoutSettings.gap.columns) + this.cellSize.width;
        const resizeHorizontally = () => {
            setElementStyles(this.renderer, this.draggedItemWrapper, {
                width: Math.min(Math.max(currentWidth, this.cellSize.width), maxWidth) + 'px'
            });
            if (this.rtl && currentWidth > this.cellSize.width) {
                const totalWidth = this.tileLayoutSettings.columns * (this.cellSize.width + this.tileLayoutSettings.gap.columns);
                const leftBoundary = this.tileLayoutSettings.tileLayoutElement.getBoundingClientRect().right - totalWidth;
                setElementStyles(this.renderer, this.draggedItemWrapper, {
                    left: Math.max(event.clientX, leftBoundary) + 'px'
                });
            }
            const deltaX = currentWidth - hintWidth;
            const { x, y, right } = this.draggedItem.elem.nativeElement.getBoundingClientRect();
            const { col } = calculateCellFromPosition({ x: (this.rtl ? right : x), y: y }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
            const resizedColSpan = col + this.currentResizingColSpan;
            const expandingCondition = this.rtl ? horizontalDragDirection < 0 : horizontalDragDirection > 0;
            const shrinkingCondition = this.rtl ? horizontalDragDirection > 0 : horizontalDragDirection < 0;
            if (deltaX > OVERLAP_THRESHOLD * this.cellSize.width &&
                expandingCondition &&
                resizedColSpan <= this.tileLayoutSettings.columns) {
                this.currentResizingColSpan++;
            }
            else if (this.currentResizingColSpan > 1 &&
                shrinkingCondition &&
                deltaX < REVERSE_OVERLAP_THRESHOLD * this.cellSize.width) {
                this.currentResizingColSpan--;
            }
            setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
                gridColumnEnd: `span ${this.currentResizingColSpan}`
            });
        };
        const resizeVertically = () => {
            setElementStyles(this.renderer, this.draggedItemWrapper, {
                height: Math.max(currentHeight, this.cellSize.height) + 'px'
            });
            const deltaY = currentHeight - hintHeight;
            if (deltaY > OVERLAP_THRESHOLD * this.cellSize.height && verticalDragDirection > 0) {
                this.currentResizingRowSpan++;
            }
            else if (this.currentResizingRowSpan > 1 &&
                verticalDragDirection < 0 && deltaY < REVERSE_OVERLAP_THRESHOLD * this.cellSize.height) {
                this.currentResizingRowSpan--;
            }
            setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
                gridRowEnd: `span ${this.currentResizingRowSpan}`
            });
            setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
                height: `${this.calculateHintHeight()}px`
            });
        };
        if (this.direction.indexOf('ew') > -1) {
            resizeHorizontally();
        }
        else if (this.direction.indexOf('ns') > -1) {
            resizeVertically();
        }
        else {
            resizeHorizontally();
            resizeVertically();
        }
    }
    cleanUp() {
        this.targetOrder = this.currentResizingColSpan = this.currentColStart = this.currentResizingRowSpan = this.currentRowStart = undefined;
        this.resizing = this.reordering = false;
        this.direction = null;
        if (this.draggedItemWrapper) {
            const grabHandle = this.draggedItemWrapper.querySelector('.k-cursor-grab');
            if (grabHandle) {
                this.renderer.removeClass(grabHandle, 'k-cursor-grabbing');
            }
            setElementStyles(this.renderer, this.draggedItemWrapper, {
                top: '',
                left: '',
                display: '',
                width: '',
                height: '',
                zIndex: '',
                position: ''
            });
            setElementStyles(this.renderer, this.tileLayoutSettings.hintElement, {
                display: 'none',
                height: 'auto'
            });
            setElementStyles(this.renderer, this.tileLayoutSettings.tileLayoutElement, {
                cursor: 'default'
            });
            this.draggedItemWrapper =
                this.offset =
                    this.draggedItem =
                        this.resizing =
                            this.reordering =
                                this.currentResizingColSpan =
                                    this.currentResizingRowSpan =
                                        this.startingPoint = undefined;
            this.lastDragCursorOffset = {
                x: 0,
                y: 0
            };
        }
    }
    targetSpan() {
        const itemRect = this.draggedItem.elem.nativeElement.getBoundingClientRect();
        const startingCell = calculateCellFromPosition({ x: this.rtl ? itemRect.right : itemRect.x, y: itemRect.y }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
        const targetEndCell = calculateCellFromPosition({
            x: this.rtl ? itemRect.x + OVERLAP_THRESHOLD * this.cellSize.width : itemRect.right - OVERLAP_THRESHOLD * this.cellSize.width,
            y: itemRect.bottom - OVERLAP_THRESHOLD * this.cellSize.height
        }, this.tileLayoutSettings.tileLayoutElement, this.tileLayoutSettings.gap, this.cellSize, this.tileLayoutSettings.columns, this.rtl);
        return {
            targetColSpan: targetEndCell.col - startingCell.col + 1,
            targetRowSpan: targetEndCell.row - startingCell.row + 1
        };
    }
    getTargetCol(col, direction) {
        if (this.rtl) {
            return direction === 'left' ? col - this.draggedItem.colSpan + 1 : col;
        }
        return direction === 'right' ? col - this.draggedItem.colSpan + 1 : col;
    }
    getTargetRow(row, direction) {
        return direction === 'down' ? row - this.draggedItem.rowSpan + 1 : row;
    }
    calculateHintHeight() {
        const totalHintCellsHeight = this.currentResizingRowSpan * this.cellSize.height;
        const totalHintGapsHeight = (this.currentResizingRowSpan - 1) * this.tileLayoutSettings.gap.rows;
        const hintHeight = totalHintCellsHeight + totalHintGapsHeight - HINT_BORDERS_HEIGHT;
        return hintHeight;
    }
};
TileLayoutDraggingService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [NgZone,
        Renderer2,
        LocalizationService])
], TileLayoutDraggingService);

/**
 * Represents a tile item within the TileLayoutComponent.
 */
let TileLayoutItemComponent = class TileLayoutItemComponent {
    constructor(elem, renderer, localization, draggingService) {
        this.elem = elem;
        this.renderer = renderer;
        this.localization = localization;
        this.draggingService = draggingService;
        /**
         * Determines how many rows will the tile item span ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.rowSpan = 1;
        /**
         * Determines how many columns will the tile item span ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.colSpan = 1;
        /**
         * Determines whether the item can be reordered. By default all items are reorderable when the [reorderable]({% slug api_layout_tilelayoutcomponent %}#toc-reorderable) property of the TileLayoutComponent is set to `true` ([see example]({% slug reordering_tilelayout %}#toc-disable-reordering)).
         *
         * @default true
         */
        this.reorderable = true;
        /**
         * Determines whether the item can be resized. By default all items are resizable when the [resizable]({% slug api_layout_tilelayoutcomponent %}#resizable) property of the TileLayoutComponent is set to `true` ([see example]({% slug resizing_tilelayout %}#toc-disable-resizing)).
         * @default true
         */
        this.resizable = true;
        this.itemClass = true;
        this.subs = new Subscription();
        this.subs.add(this.localization.changes.subscribe(({ rtl }) => {
            this.rtl = rtl;
        }));
        this.subs.add(this.draggingService.resizable.subscribe(resizable => {
            this.resizeDirections = resizable && this.resizable ?
                this.rtl ? RTL_RESIZE_DIRECTIONS : RESIZE_DIRECTIONS : undefined;
        }));
    }
    /**
     * Determines the order of the tile items within the TileLayout.
     * If not set, the items will receive increasing sequential order in accordance with
     * their position in the DOM when initially rendered.
     */
    set order(value) {
        this._order = value;
        this.renderer.setStyle(this.elem.nativeElement, 'order', `${this._order}`);
    }
    get order() {
        return this._order;
    }
    get colEnd() {
        return `span ${this.colSpan}`;
    }
    get rowEnd() {
        return `span ${this.rowSpan}`;
    }
    get colStart() {
        return isPresent(this.col) ? this.col.toString() : undefined;
    }
    get rowStart() {
        return isPresent(this.row) ? this.row.toString() : undefined;
    }
    ngAfterViewInit() {
        this.subs.add(this.draggingService.reorderable.subscribe(reorderable => {
            this.toggleCursorClass(reorderable && this.reorderable);
        }));
    }
    ngOnChanges(changes) {
        if (changes.reorderable && !changes.reorderable.firstChange) {
            this.toggleCursorClass(changes.reorderable.currentValue && this.draggingService.reorderable.getValue());
        }
        if (changes.resizable) {
            this.resizeDirections = this.resizable && this.draggingService.resizable.getValue() ?
                this.rtl ? RTL_RESIZE_DIRECTIONS : RESIZE_DIRECTIONS : undefined;
        }
    }
    ngOnDestroy() {
        this.subs.unsubscribe();
    }
    toggleCursorClass(isReorderable) {
        const headerEl = this.elem.nativeElement.querySelector('.k-tilelayout-item-header');
        if (!headerEl) {
            return;
        }
        if (isReorderable) {
            this.renderer.addClass(headerEl, 'k-cursor-grab');
        }
        else {
            this.renderer.removeClass(headerEl, 'k-cursor-grab');
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], TileLayoutItemComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TileLayoutItemComponent.prototype, "rowSpan", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TileLayoutItemComponent.prototype, "colSpan", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [Number])
], TileLayoutItemComponent.prototype, "order", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TileLayoutItemComponent.prototype, "col", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TileLayoutItemComponent.prototype, "row", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TileLayoutItemComponent.prototype, "reorderable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TileLayoutItemComponent.prototype, "resizable", void 0);
__decorate([
    HostBinding('class.k-tilelayout-item'),
    HostBinding('class.k-card'),
    __metadata("design:type", Boolean)
], TileLayoutItemComponent.prototype, "itemClass", void 0);
__decorate([
    HostBinding('style.grid-column-end'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TileLayoutItemComponent.prototype, "colEnd", null);
__decorate([
    HostBinding('style.grid-row-end'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TileLayoutItemComponent.prototype, "rowEnd", null);
__decorate([
    HostBinding('style.grid-column-start'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TileLayoutItemComponent.prototype, "colStart", null);
__decorate([
    HostBinding('style.grid-row-start'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TileLayoutItemComponent.prototype, "rowStart", null);
TileLayoutItemComponent = __decorate([
    Component({
        selector: 'kendo-tilelayout-item',
        template: `
        <kendo-tilelayout-item-header *ngIf="title">
            <h5 class="k-card-title">{{ title }}</h5>
        </kendo-tilelayout-item-header>
        <ng-content></ng-content>
        <ng-container *ngIf="resizable">
            <div
                *ngFor="let dir of resizeDirections"
                class="k-resize-handle k-cursor-{{dir}}-resize k-touch-action-none"
                kendoTileLayoutResizeHandle
                [rtl]="rtl"
                [resizeDirection]="dir">
            </div>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        LocalizationService,
        TileLayoutDraggingService])
], TileLayoutItemComponent);

const autoFlowClasses = {
    column: 'k-grid-flow-col',
    row: 'k-grid-flow-row',
    'column-dense': 'k-grid-flow-col-dense',
    'row-dense': 'k-grid-flow-row-dense'
};
/**
 * Represents the [Kendo UI TileLayout component for Angular]({% slug overview_tilelayout %})
 */
let TileLayoutComponent = class TileLayoutComponent {
    constructor(elem, renderer, localization, draggingService, zone) {
        this.elem = elem;
        this.renderer = renderer;
        this.localization = localization;
        this.draggingService = draggingService;
        this.zone = zone;
        /**
         * Defines the number of columns ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default 1
         */
        this.columns = 1;
        /**
         * Determines the width of the columns. Numeric values are treated as pixels ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default '1fr'
         */
        this.columnWidth = '1fr';
        /**
         * Determines whether the reordering functionality will be enabled ([see example]({% slug reordering_tilelayout %})).
         * @default false
         */
        this.reorderable = false;
        /**
         * Determines whether the resizing functionality will be enabled ([see example]({% slug resizing_tilelayout %})).
         * @default false
         */
        this.resizable = false;
        /**
         * Determines the height of the rows. Numeric values are treated as pixels ([see example]({% slug tiles_tilelayout %}#toc-tile-size-and-position)).
         * @default '1fr'
         */
        this.rowHeight = '1fr';
        /**
         * Controls how the auto-placement algorithm works, specifying exactly how auto-placed items are flowed in the TileLayout ([see example]({% slug tiles_autoflow_tilelayout %})).
         * For further reference, check the [grid-auto-flow CSS article](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow).
         *
         * The possible values are:
         * * (Default) `column`
         * * `row`
         * * `row dense`
         * * `column dense`
         * * `none`
         *
         */
        this.autoFlow = 'column';
        /**
         * Fires when the user completes the reordering of the item ([see example]({% slug overview_tilelayout %}#toc-events)).
         * This event is preventable. If you cancel it, the item will not be reordered.
         */
        this.reorder = new EventEmitter();
        /**
         * Fires when the user completes the resizing of the item ([see example]({% slug overview_tilelayout %}#toc-events)).
         * This event is preventable. If you cancel it, the item will not be resized.
         */
        this.resize = new EventEmitter();
        this.hostClass = true;
        this.subs = new Subscription();
        this._gap = {
            rows: 16,
            columns: 16
        };
        validatePackage(packageMetadata);
    }
    /**
     * The numeric values which determine the spacing in pixels between the layout items horizontally and vertically.
     * Properties:
     * * rows - the vertical spacing. Numeric values are treated as pixels. Defaults to `16`.
     * * columns - the horizontal spacing. Numeric values are treated as pixels. Defaults to `16`.
     *
     * When bound to a single numeric value, it will be set to both `rows` and `columns` properties.
     */
    set gap(value) {
        this._gap = (typeof value === 'number') ? { rows: value, columns: value } : Object.assign(this._gap, value);
    }
    get gap() {
        return this._gap;
    }
    get gapStyle() {
        return `${this.gap.rows}px ${this.gap.columns}px`;
    }
    get currentColStart() {
        return this.draggingService.colStart;
    }
    get currentRowStart() {
        return this.draggingService.rowStart;
    }
    get draggedItemWrapper() {
        return this.draggingService.itemWrapper;
    }
    get targetOrder() {
        return this.draggingService.order;
    }
    ngOnInit() {
        this.applyColStyling();
        this.applyRowStyling();
        this.draggingService.reorderable.next(this.reorderable);
        this.draggingService.resizable.next(this.resizable);
        if (hasObservers(this.reorder)) {
            this.subs.add(this.draggingService.reorder.subscribe(e => this.reorder.emit(e)));
        }
        if (hasObservers(this.resize)) {
            this.subs.add(this.draggingService.resize.subscribe(e => this.resize.emit(e)));
        }
        this.subs.add(this.draggingService.reorderable.subscribe(reorderable => {
            if (reorderable && !this.draggable) {
                this.initializeDraggable();
            }
        }));
        this.subs.add(this.draggingService.resizable.subscribe(resizable => {
            if (resizable && !this.draggable) {
                this.initializeDraggable();
            }
        }));
        this.subs.add(this.localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        }));
    }
    ngAfterViewInit() {
        this.draggingService.tileLayoutSettings = this.draggingServiceConfig();
        this.applyAutoFlow(null, autoFlowClasses[this.autoFlow]);
        this.items.changes.subscribe(() => {
            this.setItemsOrder();
            this.draggingService.tileLayoutSettings.items = this.items.toArray();
        });
    }
    ngAfterContentInit() {
        this.setItemsOrder();
    }
    ngOnChanges(changes) {
        if (changes.columns || changes.columnWidth) {
            this.applyColStyling();
        }
        if (changes.rowHeight) {
            this.applyRowStyling();
        }
        if (changes.reorderable) {
            this.draggingService.reorderable.next(changes.reorderable.currentValue);
        }
        if (changes.resizable) {
            this.draggingService.resizable.next(changes.resizable.currentValue);
        }
        if (changes.gap || changes.autoFlow || changes.columns) {
            this.draggingService.tileLayoutSettings = this.draggingServiceConfig();
            if (changes.autoFlow) {
                this.applyAutoFlow(autoFlowClasses[changes.autoFlow.previousValue] || '', autoFlowClasses[changes.autoFlow.currentValue]);
            }
        }
    }
    ngOnDestroy() {
        if (this.draggable) {
            this.draggable.destroy();
        }
        this.subs.unsubscribe();
    }
    handlePress({ originalEvent }) {
        this.draggingService.handlePress(originalEvent);
    }
    handleDrag({ originalEvent }) {
        this.draggingService.handleDrag(originalEvent);
    }
    handleRelease({ originalEvent }) {
        this.draggingService.handleRelease(originalEvent);
    }
    applyColStyling() {
        const colWidth = typeof this.columnWidth === 'number' ? `${this.columnWidth}px` : this.columnWidth;
        const gridTemplateColumnsStyle = `repeat(${this.columns}, ${colWidth})`;
        this.renderer.setStyle(this.elem.nativeElement, 'grid-template-columns', gridTemplateColumnsStyle);
    }
    applyRowStyling() {
        const rowHeight = typeof this.rowHeight === 'number' ? `${this.rowHeight}px` : this.rowHeight;
        const gridAutoRowsStyle = `${rowHeight}`;
        this.renderer.setStyle(this.elem.nativeElement, 'grid-auto-rows', gridAutoRowsStyle);
    }
    draggingServiceConfig() {
        return {
            tileLayoutElement: this.elem ? this.elem.nativeElement : undefined,
            hintElement: this.hint ? this.hint.nativeElement : undefined,
            gap: this.gap,
            columns: this.columns,
            autoFlow: this.autoFlow,
            items: this.items ? this.items.toArray() : []
        };
    }
    initializeDraggable() {
        this.draggable = new Draggable({
            press: this.handlePress.bind(this),
            drag: this.handleDrag.bind(this),
            release: this.handleRelease.bind(this)
        });
        this.zone.runOutsideAngular(() => this.draggable.bindTo(this.elem.nativeElement));
    }
    applyAutoFlow(classToRemove, classToAdd) {
        const element = this.elem.nativeElement;
        if (classToRemove) {
            this.renderer.removeClass(element, classToRemove);
        }
        if (this.autoFlow !== 'none' && isPresent(classToAdd)) {
            this.renderer.addClass(element, classToAdd);
        }
    }
    setItemsOrder() {
        this.items.forEach((item, index) => {
            if (!isPresent(item.order)) {
                item.order = index;
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], TileLayoutComponent.prototype, "columns", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TileLayoutComponent.prototype, "columnWidth", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], TileLayoutComponent.prototype, "gap", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TileLayoutComponent.prototype, "reorderable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TileLayoutComponent.prototype, "resizable", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], TileLayoutComponent.prototype, "rowHeight", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TileLayoutComponent.prototype, "autoFlow", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TileLayoutComponent.prototype, "reorder", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], TileLayoutComponent.prototype, "resize", void 0);
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-tilelayout'),
    __metadata("design:type", Boolean)
], TileLayoutComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('style.gap'),
    HostBinding('style.padding'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], TileLayoutComponent.prototype, "gapStyle", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String)
], TileLayoutComponent.prototype, "direction", void 0);
__decorate([
    ContentChildren(TileLayoutItemComponent),
    __metadata("design:type", QueryList)
], TileLayoutComponent.prototype, "items", void 0);
__decorate([
    ViewChild('hint', { static: false }),
    __metadata("design:type", ElementRef)
], TileLayoutComponent.prototype, "hint", void 0);
TileLayoutComponent = __decorate([
    Component({
        selector: 'kendo-tilelayout',
        providers: [
            LocalizationService,
            TileLayoutDraggingService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.tilelayout.component'
            }
        ],
        template: `
        <ng-content></ng-content>
        <div #hint class="k-layout-item-hint k-layout-item-hint-reorder"
            [style.display]="'none'"
            [style.order]="targetOrder"
            [style.gridColumnEnd]="draggedItemWrapper?.style.gridColumnEnd"
            [style.gridRowEnd]="draggedItemWrapper?.style.gridRowEnd"
            [style.gridColumnStart]="currentColStart"
            [style.gridRowStart]="currentRowStart"
            [style.zIndex]="'1'"></div>
    `
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2,
        LocalizationService,
        TileLayoutDraggingService,
        NgZone])
], TileLayoutComponent);

/**
 * Holds the main content of the TileLayoutItem component.
 */
let TileLayoutItemBodyComponent = class TileLayoutItemBodyComponent {
    /**
     * Holds the main content of the TileLayoutItem component.
     */
    constructor() {
        this.hostClass = true;
        this.minHeight = 0;
    }
};
__decorate([
    HostBinding('class.k-tilelayout-item-body'),
    HostBinding('class.k-card-body'),
    __metadata("design:type", Boolean)
], TileLayoutItemBodyComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('style.min-height'),
    __metadata("design:type", Number)
], TileLayoutItemBodyComponent.prototype, "minHeight", void 0);
TileLayoutItemBodyComponent = __decorate([
    Component({
        selector: 'kendo-tilelayout-item-body',
        template: `
        <ng-content></ng-content>
    `
    })
], TileLayoutItemBodyComponent);

/**
 * Holds the content of the header section of the TileLayoutItem component.
 * This is the area which can be dragged to reorder the items if reordering is enabled for this item.
 */
let TileLayoutItemHeaderComponent = class TileLayoutItemHeaderComponent {
    constructor(draggingService) {
        this.draggingService = draggingService;
        this.hostClass = true;
    }
    get touchActionNone() {
        return this.draggingService.reorderable.getValue();
    }
    get touchActionAuto() {
        return !this.draggingService.reorderable.getValue();
    }
};
__decorate([
    HostBinding('class.k-tilelayout-item-header'),
    HostBinding('class.k-card-header'),
    __metadata("design:type", Boolean)
], TileLayoutItemHeaderComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-touch-action-none'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TileLayoutItemHeaderComponent.prototype, "touchActionNone", null);
__decorate([
    HostBinding('class.k-touch-action-auto'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TileLayoutItemHeaderComponent.prototype, "touchActionAuto", null);
TileLayoutItemHeaderComponent = __decorate([
    Component({
        selector: 'kendo-tilelayout-item-header',
        template: `
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [TileLayoutDraggingService])
], TileLayoutItemHeaderComponent);

const exportedModules = [
    AvatarComponent
];
const declarations = [
    ...exportedModules
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Avatar component.
 */
let AvatarModule = class AvatarModule {
};
AvatarModule = __decorate([
    NgModule({
        declarations: [declarations],
        exports: [exportedModules],
        imports: [CommonModule]
    })
], AvatarModule);

const cardDirectives = [
    CardTitleDirective,
    CardSubtitleDirective,
    CardSeparatorDirective,
    CardMediaDirective
];
const exportedModules$1 = [
    CardComponent,
    CardHeaderComponent,
    CardBodyComponent,
    CardFooterComponent,
    CardActionsComponent,
    ...cardDirectives
];
const declarations$1 = [...exportedModules$1];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Card component.
 */
let CardModule = class CardModule {
};
CardModule = __decorate([
    NgModule({
        declarations: [declarations$1],
        exports: [exportedModules$1],
        imports: [CommonModule]
    })
], CardModule);

/**
 * @hidden
 */
const DRAWER_LINK_SELECTOR = '.k-drawer-link';
/**
 * @hidden
 */
const ACTIVE_NESTED_LINK_SELECTOR = ':focus:not(.k-state-disabled) .k-drawer-link';
/**
 * @hidden
 */
const nestedLink = (element, selector) => element.querySelector(selector);

/**
 * @hidden
 */
let DrawerItemComponent = class DrawerItemComponent {
    constructor(drawer, element, renderer) {
        this.drawer = drawer;
        this.element = element;
        this.renderer = renderer;
    }
    get disabledClass() {
        return this.item.disabled;
    }
    get selectedClass() {
        return this.drawer.selectedIndices.indexOf(this.index) >= 0;
    }
    get label() {
        return this.item.text ? this.item.text : null;
    }
    /**
     * @hidden
     */
    ngAfterViewInit() {
        const link = nestedLink(this.element.nativeElement, DRAWER_LINK_SELECTOR);
        if (link) {
            this.renderer.removeAttribute(link, 'tabindex');
        }
    }
    /**
     * @hidden
     */
    get iconClasses() {
        const classes = [];
        if (this.item.icon) {
            classes.push(`k-icon ${this.item.icon}`);
        }
        if (this.item.iconClass) {
            classes.push(`${this.item.iconClass}`);
        }
        return classes;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], DrawerItemComponent.prototype, "item", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], DrawerItemComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], DrawerItemComponent.prototype, "itemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerItemComponent.prototype, "mini", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerItemComponent.prototype, "expanded", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerItemComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerItemComponent.prototype, "cssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerItemComponent.prototype, "cssStyle", void 0);
__decorate([
    HostBinding('attr.aria-disabled'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerItemComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('attr.aria-selected'),
    HostBinding('class.k-state-selected'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerItemComponent.prototype, "selectedClass", null);
__decorate([
    HostBinding('attr.aria-label'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], DrawerItemComponent.prototype, "label", null);
DrawerItemComponent = __decorate([
    Component({
        selector: '[kendoDrawerItem]',
        template: `
        <ng-template *ngIf="itemTemplate; else defaultTemplate"
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ $implicit: item }">
        </ng-template>

        <ng-template #defaultTemplate>
            <ng-container *ngIf="expanded">
                <span [ngClass]="iconClasses"></span>
                <span class="k-item-text">{{ item.text }}</span>
            </ng-container>
            <ng-container *ngIf="mini && !expanded">
                <span [ngClass]="iconClasses"></span>
            </ng-container>
        </ng-template>
    `
    }),
    __metadata("design:paramtypes", [DrawerService, ElementRef, Renderer2])
], DrawerItemComponent);

/**
 * @hidden
 */
const DRAWER_ITEM_INDEX = 'data-kendo-drawer-index';

/**
 * @hidden
 */
let DrawerListComponent = class DrawerListComponent {
    constructor(drawerService, renderer, ngZone, changeDetector, element) {
        this.drawerService = drawerService;
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.changeDetector = changeDetector;
        this.element = element;
        this.subscriptions = new Subscription();
    }
    ngOnInit() {
        this.initialSelection();
        this.initDomEvents();
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    initialSelection() {
        /* Differentiates a user selected item */
        if (this.drawerService.selectedIndices.length === 0) {
            this.drawerService.initSelection();
        }
    }
    initDomEvents() {
        if (!this.element) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            const nativeElement = this.element.nativeElement;
            this.subscriptions.add(this.renderer.listen(nativeElement, 'click', this.clickHandler.bind(this)));
            this.subscriptions.add(this.renderer.listen(nativeElement, 'keydown', this.keyDownHandler.bind(this)));
        });
    }
    clickHandler(e) {
        const itemIdx = this.getDrawerItemIndex(e.target);
        const item = this.items[itemIdx];
        if (!item) {
            return;
        }
        if (item.disabled) {
            e.preventDefault();
            return;
        }
        const args = {
            index: itemIdx,
            item: item,
            originalEvent: e
        };
        this.ngZone.run(() => {
            if (!this.drawerService.emit('select', args)) {
                this.drawerService.onSelect(itemIdx);
                this.changeDetector.detectChanges();
            }
        });
    }
    keyDownHandler(e) {
        const isEnterOrSpace = e.keyCode === Keys.Enter || e.keyCode === Keys.Space;
        if (!isEnterOrSpace) {
            return;
        }
        this.clickHandler(e);
        const link = nestedLink(this.element.nativeElement, ACTIVE_NESTED_LINK_SELECTOR);
        if (link) {
            link.click();
        }
        return false;
    }
    getDrawerItemIndex(target) {
        const item = closestItem(target, DRAWER_ITEM_INDEX, this.element.nativeElement);
        if (item) {
            return itemIndex(item, DRAWER_ITEM_INDEX);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], DrawerListComponent.prototype, "items", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], DrawerListComponent.prototype, "itemTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerListComponent.prototype, "mini", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DrawerListComponent.prototype, "expanded", void 0);
DrawerListComponent = __decorate([
    Component({
        selector: '[kendoDrawerList]',
        template: `
        <ng-container *ngFor="let item of items; let idx = index">
            <li *ngIf="!item.separator" kendoDrawerItem
                class="k-drawer-item"
                [item]="item"
                [index]="idx"
                [mini]="mini"
                [expanded]="expanded"
                [itemTemplate]="itemTemplate"
                [attr.${DRAWER_ITEM_INDEX}]="idx"
                [ngClass]="item.cssClass"
                [ngStyle]="item.cssStyle"
                tabindex="0">
            </li>

            <li *ngIf="item.separator"
                class="k-drawer-item k-drawer-separator"
                [ngClass]="item.cssClass"
                [ngStyle]="item.cssStyle">
                &nbsp;
            </li>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [DrawerService,
        Renderer2,
        NgZone,
        ChangeDetectorRef,
        ElementRef])
], DrawerListComponent);

const templateDirectives = [
    DrawerTemplateDirective,
    DrawerHeaderTemplateDirective,
    DrawerFooterTemplateDirective,
    DrawerItemTemplateDirective
];
const exportedModules$2 = [
    DrawerComponent,
    DrawerContainerComponent,
    DrawerContentComponent,
    ...templateDirectives
];
const declarations$2 = [
    DrawerItemComponent,
    DrawerListComponent,
    ...exportedModules$2
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Drawer component.
 */
let DrawerModule = class DrawerModule {
};
DrawerModule = __decorate([
    NgModule({
        declarations: [declarations$2],
        exports: [exportedModules$2],
        imports: [CommonModule]
    })
], DrawerModule);

const exportedModules$3 = [
    ExpansionPanelComponent,
    ExpansionPanelTitleDirective
];
const declarations$3 = [
    ...exportedModules$3
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the ExpansionPanel component.
 */
let ExpansionPanelModule = class ExpansionPanelModule {
};
ExpansionPanelModule = __decorate([
    NgModule({
        declarations: [declarations$3],
        exports: [exportedModules$3],
        imports: [CommonModule, EventsModule]
    })
], ExpansionPanelModule);

const exportedModules$4 = [
    PanelBarComponent,
    PanelBarItemComponent,
    PanelBarContentDirective,
    PanelBarItemTemplateDirective,
    PanelBarItemTitleDirective
];
const declarations$4 = [
    ...exportedModules$4
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the PanelBar component.
 *
 * The module registers:
 * - `PanelBarComponent`&mdash;The `PanelBar` component class.
 * - `PanelBarItemComponent`&mdash;The `PanelBarItem` component class.
 * - `PanelBarContentComponent`&mdash;The `PanelBarContent` component class.
 * - `PanelBarItemTemplateDirective&mdash;The `PanelBarItemTemplate` directive.
 * - `PanelBarItemTitleDirective&mdash;The `PanelBarItemTitle` directive.
 */
let PanelBarModule = class PanelBarModule {
};
PanelBarModule = __decorate([
    NgModule({
        declarations: [declarations$4],
        exports: [exportedModules$4],
        imports: [CommonModule]
    })
], PanelBarModule);

const exportedModules$5 = [
    SplitterComponent,
    SplitterPaneComponent
];
const declarations$5 = [
    SplitterBarComponent,
    ...exportedModules$5
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Splitter component.
 *
 * The module registers:
 * - `SplitterComponent`&mdash;The `Splitter` component class.
 * - `SplitterPaneComponent`&mdash;The `SplitterPane` component class.
 */
let SplitterModule = class SplitterModule {
};
SplitterModule = __decorate([
    NgModule({
        declarations: [declarations$5],
        exports: [exportedModules$5],
        imports: [
            CommonModule,
            DraggableModule
        ]
    })
], SplitterModule);

/**
 * @hidden
 */
let StepperStepComponent = class StepperStepComponent {
    constructor(service, localization, ngZone) {
        this.service = service;
        this.localization = localization;
        this.ngZone = ngZone;
        this.isStepValid = undefined;
        this.shouldCheckValidity = undefined;
        validatePackage(packageMetadata);
        this.subs = this.service.focusedStepChange.subscribe(() => {
            this.onFocusedStepChange();
        });
        this.subs.add(this.service.triggerValidation.subscribe(() => {
            this.handleValidityChecks();
        }));
    }
    get errorStepClass() {
        if (isPresent(this.isStepValid)) {
            return !this.isStepValid;
        }
        return false;
    }
    get successStepClass() {
        if (isPresent(this.isStepValid)) {
            return this.isStepValid;
        }
        return false;
    }
    ngOnInit() {
        this.handleValidityChecks();
    }
    ngOnChanges(changes) {
        if (changes.current && !changes.current.firstChange) {
            this.handleValidityChecks();
        }
    }
    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }
    onFocusedStepChange() {
        this.ngZone.runOutsideAngular(() => {
            if (this.index === this.service.focusedStep) {
                this.stepLink.nativeElement.focus();
            }
        });
    }
    onFocus() {
        this.service.focus(this.index);
    }
    get tabIndexAttr() {
        const active = this.service.focusedStep || this.service.currentStep;
        return this.index === active ? 0 : -1;
    }
    get indicatorIconClasses() {
        if (this.step.icon) {
            return `k-icon k-i-${this.step.icon}`;
        }
        if (this.step.iconClass) {
            return `${this.step.iconClass}`;
        }
        if (this.shouldCheckValidity) {
            return this.validationIconClasses;
        }
    }
    get showIndicatorIcon() {
        if (this.shouldCheckValidity) {
            return true;
        }
        if (this.step.icon || this.step.iconClass) {
            return true;
        }
        return false;
    }
    get showLabelIcon() {
        if (this.shouldCheckValidity) {
            if (this.type === 'label') {
                return true;
            }
            if (this.step.icon || this.step.iconClass) {
                return true;
            }
        }
        return false;
    }
    get showLabelText() {
        return this.type === 'label' || this.type === 'full';
    }
    get validationIconClasses() {
        if (this.isStepValid) {
            return this.successIcon ? `${this.successIcon}` : 'k-icon k-i-check';
        }
        else {
            return this.errorIcon ? `${this.errorIcon}` : 'k-icon k-i-warning';
        }
    }
    get indicatorText() {
        const text = this.step.text;
        return text ? text : this.index + 1;
    }
    updateStepValidity() {
        if (typeof this.step.isValid === 'boolean') {
            return this.step.isValid;
        }
        if (typeof this.step.isValid === 'function') {
            return this.step.isValid(this.index);
        }
        return undefined;
    }
    get showIndicator() {
        return this.type === 'indicator' || this.type === 'full';
    }
    get showLabel() {
        if (this.type === 'label' || this.type === 'full') {
            return true;
        }
        return this.step.optional;
    }
    get optionalText() {
        return this.localization.get('optional');
    }
    get transitionDuration() {
        return this.service.owner.animationDuration;
    }
    _shouldCheckValidity() {
        if (isPresent(this.step.validate)) {
            if (typeof this.step.validate === 'boolean') {
                return this.step.validate;
            }
            if (typeof this.step.validate === 'function') {
                return this.step.validate(this.index);
            }
        }
        return isPresent(this.step.isValid) && this.index < this.current;
    }
    handleValidityChecks() {
        this.isStepValid = undefined;
        this.shouldCheckValidity = this._shouldCheckValidity();
        if (this.shouldCheckValidity) {
            this.isStepValid = this.updateStepValidity();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], StepperStepComponent.prototype, "step", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], StepperStepComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], StepperStepComponent.prototype, "current", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperStepComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperStepComponent.prototype, "successIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperStepComponent.prototype, "errorIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperStepComponent.prototype, "indicatorTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperStepComponent.prototype, "labelTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperStepComponent.prototype, "stepTemplate", void 0);
__decorate([
    ViewChild('stepLink', { static: true }),
    __metadata("design:type", ElementRef)
], StepperStepComponent.prototype, "stepLink", void 0);
__decorate([
    HostBinding('class.k-step-error'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], StepperStepComponent.prototype, "errorStepClass", null);
__decorate([
    HostBinding('class.k-step-success'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], StepperStepComponent.prototype, "successStepClass", null);
StepperStepComponent = __decorate([
    Component({
        selector: '[kendoStepperStep]',
        template: `
        <a href='#' class='k-step-link' #stepLink
            [attr.tabindex]='tabIndexAttr'
            [attr.title]='step.label'
            [attr.aria-disabled]='step.disabled'
            [attr.aria-current]='index === current ? "step" : null'
            (focus)='onFocus()'
        >
            <ng-template *ngIf='stepTemplate'
                [ngTemplateOutlet]='stepTemplate'
                [ngTemplateOutletContext]='{ $implicit: step, index: index }'>
            </ng-template>

            <ng-container *ngIf='!stepTemplate'>
                <span *ngIf='showIndicator'
                    class='k-step-indicator'
                    aria-hidden='true'
                    [style.transition-duration.ms]='transitionDuration'
                >
                    <ng-template *ngIf='indicatorTemplate'
                        [ngTemplateOutlet]='indicatorTemplate'
                        [ngTemplateOutletContext]='{ $implicit: step, index: index }'>
                    </ng-template>

                    <ng-container *ngIf='!indicatorTemplate'>
                        <span *ngIf='showIndicatorIcon' class='k-step-indicator-icon' [ngClass]='indicatorIconClasses'></span>
                        <span class='k-step-indicator-text' *ngIf='!showIndicatorIcon'>{{ indicatorText }}</span>
                    </ng-container>
                </span>

                <span class='k-step-label' *ngIf='showLabel'>
                    <ng-template *ngIf='labelTemplate'
                        [ngTemplateOutlet]='labelTemplate'
                        [ngTemplateOutletContext]='{ $implicit: step, index: index }'>
                    </ng-template>

                    <ng-container *ngIf='!labelTemplate'>
                        <span class='k-step-text' *ngIf='showLabelText'>{{ step.label }}</span>
                        <span [ngClass]='validationIconClasses' *ngIf='showLabelIcon' aria-hidden='true'></span>
                        <span class='k-step-label-optional' *ngIf='step.optional'>({{optionalText}})</span>
                    </ng-container>
                </span>
            </ng-container>
        </a>
    `
    }),
    __metadata("design:paramtypes", [StepperService,
        LocalizationService,
        NgZone])
], StepperStepComponent);

/**
 * @hidden
 */
const STEPPER_STEP_INDEX = 'data-kendo-stepper-index';

/**
 * @hidden
 */
let StepperListComponent = class StepperListComponent {
    constructor(renderer, ngZone, service, element) {
        this.renderer = renderer;
        this.ngZone = ngZone;
        this.service = service;
        this.element = element;
    }
    ngOnInit() {
        this.initDomEvents();
    }
    ngOnDestroy() {
        if (this.domSubs) {
            this.domSubs();
        }
    }
    get maxStepWidth() {
        return this.maxStepDimension('width');
    }
    get maxStepHeight() {
        return this.maxStepDimension('height');
    }
    maxStepDimension(dimension) {
        if (dimension === 'width' && this.orientation === 'vertical') {
            return null;
        }
        if (dimension === 'height' && this.orientation === 'horizontal') {
            return null;
        }
        return 100 / this.steps.length;
    }
    initDomEvents() {
        if (!this.element) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            const nativeElement = this.element.nativeElement;
            const clickSubscription = this.renderer.listen(nativeElement, 'click', this.clickHandler.bind(this));
            const keydownSubscription = this.renderer.listen(nativeElement, 'keydown', (e) => {
                if (hasClass(e.target, 'k-step-link')) {
                    this.service.keydown(e);
                }
            });
            this.domSubs = () => {
                clickSubscription();
                keydownSubscription();
            };
        });
    }
    clickHandler(e) {
        e.preventDefault();
        const stepIdx = this.getStepIndex(e.target);
        const step = this.steps[stepIdx];
        if (!step || step.disabled) {
            return;
        }
        if (stepIdx === this.currentStep) {
            this.service.focus(stepIdx);
            return;
        }
        if (this.linear && this.service.isPrevOrNextStep(stepIdx) === false) {
            return;
        }
        this.service.onActivate(stepIdx, e);
    }
    getStepIndex(target) {
        const step = closestItem(target, STEPPER_STEP_INDEX, this.element.nativeElement);
        if (step) {
            return itemIndex(step, STEPPER_STEP_INDEX);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], StepperListComponent.prototype, "linear", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperListComponent.prototype, "stepType", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperListComponent.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], StepperListComponent.prototype, "currentStep", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], StepperListComponent.prototype, "steps", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperListComponent.prototype, "successIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StepperListComponent.prototype, "errorIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperListComponent.prototype, "indicatorTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperListComponent.prototype, "labelTemplate", void 0);
__decorate([
    Input(),
    __metadata("design:type", TemplateRef)
], StepperListComponent.prototype, "stepTemplate", void 0);
StepperListComponent = __decorate([
    Component({
        selector: '[kendoStepperList]',
        template: `
        <ng-container *ngFor='let step of steps; let idx = index'>
            <li kendoStepperStep
                [attr.${STEPPER_STEP_INDEX}]='idx'
                [type]='stepType'
                [step]='step'
                [index]='idx'
                [current]='currentStep'
                [successIcon]='successIcon'
                [errorIcon]='errorIcon'
                [indicatorTemplate]='indicatorTemplate'
                [labelTemplate]='labelTemplate'
                [stepTemplate]='stepTemplate'
                class='k-step'
                [class.k-step-first]='idx === 0'
                [class.k-step-last]='idx === steps.length - 1'
                [class.k-step-done]='idx < currentStep'
                [class.k-step-current]='idx === currentStep'
                [class.k-step-optional]='step.optional'
                [class.k-step-disabled]='step.disabled'
                [ngClass]='step.cssClass'
                [ngStyle]='step.cssStyle'
                [style.max-width.%] = 'maxStepWidth'
                [style.max-height.%] = 'maxStepHeight'
            >
            </li>
        </ng-container>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        NgZone,
        StepperService,
        ElementRef])
], StepperListComponent);

const templateDirectives$1 = [
    StepperStepTemplateDirective,
    StepperLabelTemplateDirective,
    StepperIndicatorTemplateDirective
];
const exportedModules$6 = [
    StepperComponent,
    StepperCustomMessagesComponent,
    ...templateDirectives$1
];
const declarations$6 = [
    StepperStepComponent,
    StepperListComponent,
    LocalizedStepperMessagesDirective,
    ...exportedModules$6
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Stepper component.
 */
let StepperModule = class StepperModule {
};
StepperModule = __decorate([
    NgModule({
        declarations: [declarations$6],
        exports: [exportedModules$6],
        imports: [CommonModule, ProgressBarModule]
    })
], StepperModule);

/**
 * @hidden
 */
let TabComponent = class TabComponent {
    constructor(localization, tabstripService) {
        this.localization = localization;
        this.tabstripService = tabstripService;
        this.hostClasses = true;
    }
    get activeClass() {
        return this.tab.selected;
    }
    get disabledClass() {
        return this.tab.disabled;
    }
    get focusedClass() {
        return this.tab.focused;
    }
    get tabIndex() {
        return this.tab.selected || this.tab.focused ? 0 : -1;
    }
    get tabClosable() {
        if (this.tab.closable !== undefined) {
            return this.tab.closable;
        }
        return this.tabStripClosable;
    }
    get closeButtonClasses() {
        if (isPresent(this.tab.closeIcon)) {
            return this.tab.closeIcon;
        }
        return this.tabStripCloseIcon;
    }
    get closeButtonTitle() {
        return this.localization.get('closeTitle');
    }
    closeTab(index) {
        this.tabstripService.onTabClose(this.tab, index);
    }
};
__decorate([
    Input(),
    __metadata("design:type", TabStripTabComponent)
], TabComponent.prototype, "tab", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], TabComponent.prototype, "index", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TabComponent.prototype, "tabStripClosable", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], TabComponent.prototype, "tabStripCloseIcon", void 0);
__decorate([
    HostBinding('class.k-item'),
    HostBinding('class.k-state-default'),
    __metadata("design:type", Boolean)
], TabComponent.prototype, "hostClasses", void 0);
__decorate([
    HostBinding('attr.aria-selected]'),
    HostBinding('class.k-state-active'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabComponent.prototype, "activeClass", null);
__decorate([
    HostBinding('attr.aria-disabled]'),
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabComponent.prototype, "disabledClass", null);
__decorate([
    HostBinding('class.k-state-focused'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], TabComponent.prototype, "focusedClass", null);
__decorate([
    HostBinding('attr.tabindex'),
    __metadata("design:type", Number),
    __metadata("design:paramtypes", [])
], TabComponent.prototype, "tabIndex", null);
TabComponent = __decorate([
    Component({
        selector: '[kendoTabStripTab]',
        template: `
        <span class="k-link" *ngIf="!tab.tabTitle">{{ tab.title }}</span>
        <span class="k-link" *ngIf="tab.tabTitle">
            <ng-template [ngTemplateOutlet]="tab.tabTitle?.templateRef">
            </ng-template>
        </span>
        <span class="k-remove-tab k-button k-icon-button k-flat" *ngIf="tabClosable"
            role="button"
            [title]="closeButtonTitle"
            [attr.aria-label]="closeButtonTitle"
            (click)="closeTab(index)"
        >
            <span [ngClass]="closeButtonClasses"></span>
        </span>
    `
    }),
    __metadata("design:paramtypes", [LocalizationService,
        TabStripService])
], TabComponent);

const exportedModules$7 = [
    TabStripComponent,
    TabStripTabComponent,
    TabContentDirective,
    TabTitleDirective,
    TabComponent,
    TabStripCustomMessagesComponent,
    LocalizedTabStripMessagesDirective
];
const declarations$7 = [
    ...exportedModules$7,
    TabStripScrollableButtonComponent
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the TabStrip component.
 *
 * The module registers:
 * - `TabStripComponent`&mdash;The `TabStrip` component class.
 * - `TabStripTabComponent`&mdash;The `TabStripTab` component class.
 * - `TabContentDirective`&mdash;The tab content directive used on the `<ng-template>` tag.
 * - `TabTitleDirective`&mdash;The tab title directive used on the `<ng-template>` tag.
 */
let TabStripModule = class TabStripModule {
};
TabStripModule = __decorate([
    NgModule({
        declarations: [declarations$7],
        exports: [exportedModules$7],
        imports: [CommonModule, ResizeSensorModule]
    })
], TabStripModule);

/**
 * @hidden
 */
let TileLayoutResizeHandleDirective = class TileLayoutResizeHandleDirective {
    constructor(el, renderer) {
        this.el = el;
        this.renderer = renderer;
    }
    ngOnInit() {
        this.sizeHandle();
    }
    setHorizontalPosition(element) {
        this.renderer.setStyle(element, this.rtl ? 'left' : 'right', -RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].overlapX + 'px');
    }
    setBottom(element) {
        this.renderer.setStyle(element, 'bottom', -RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].overlapY + 'px');
    }
    sizeHandle() {
        const element = this.el.nativeElement;
        const handleWidth = RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].width ?
            `${RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].width}px` : '100%';
        const handleHeight = RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].height ?
            `${RESIZE_HANDLE_DIMENSIONS[this.resizeDirection].height}px` : '100%';
        this.renderer.setStyle(element, 'width', handleWidth);
        this.renderer.setStyle(element, 'height', handleHeight);
        if (this.resizeDirection === 'ew') {
            this.setHorizontalPosition(element);
        }
        else if (this.resizeDirection === 'ns') {
            this.setBottom(element);
        }
        else {
            this.setHorizontalPosition(element);
            this.setBottom(element);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], TileLayoutResizeHandleDirective.prototype, "resizeDirection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], TileLayoutResizeHandleDirective.prototype, "rtl", void 0);
TileLayoutResizeHandleDirective = __decorate([
    Directive({
        selector: '[kendoTileLayoutResizeHandle]'
    }),
    __metadata("design:paramtypes", [ElementRef,
        Renderer2])
], TileLayoutResizeHandleDirective);

const exportedModules$8 = [
    TileLayoutComponent,
    TileLayoutItemComponent,
    TileLayoutItemHeaderComponent,
    TileLayoutItemBodyComponent,
    TileLayoutResizeHandleDirective
];
const declarations$8 = [
    ...exportedModules$8
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the TileLayout component.
 *
 * The module registers:
 * - `TileLayoutComponent`&mdash;The `TileLayoutComponent` component class.
 * - `TileLayoutItemComponent`&mdash;The `TileLayoutItemComponent` component class.
 * - `TileLayoutItemHeaderComponent`&mdash;The `TileLayoutItemHeaderComponent` component class.
 * - `TileLayoutItemBodyComponent`&mdash;The `TileLayoutItemBodyComponent` component class.
 */
let TileLayoutModule = class TileLayoutModule {
};
TileLayoutModule = __decorate([
    NgModule({
        declarations: [declarations$8],
        exports: [exportedModules$8],
        imports: [CommonModule]
    })
], TileLayoutModule);

/**
 * @hidden
 */
const VERTICAL_SUFFIX = {
    top: 'start',
    middle: 'center',
    bottom: 'end',
    stretch: 'stretch'
};
/**
 * @hidden
 */
const JUSTIFY_PREFIX = `k-justify-content`;
/**
 * @hidden
 */
const GRID_JUSTIFY_PREFIX = `k-justify-items`;
/**
 * @hidden
 */
const ALIGN_PREFIX = `k-align-items`;
/**
 * @hidden
 */
const normalizeGap = (gap) => {
    if (typeof gap === 'number' || typeof gap === 'string') {
        return { cols: gap, rows: gap };
    }
    else {
        let parsedGap = {};
        parsedGap.rows = gap.rows ? gap.rows : 0;
        parsedGap.cols = gap.cols ? gap.cols : 0;
        return parsedGap;
    }
};
/**
 * @hidden
 */
const generateGapStyle = (gap) => {
    if (gap.rows === gap.cols) {
        return typeof gap.rows === 'number' ? `${gap.rows}px` : gap.rows;
    }
    else {
        let rowStyle = `${typeof gap.rows === 'number' ? gap.rows + 'px' : gap.rows}`;
        let colStyle = `${typeof gap.cols === 'number' ? gap.cols + 'px' : gap.cols}`;
        return `${rowStyle} ${colStyle}`;
    }
};
/**
 * @hidden
 */
const generateGridStyle = (items, itemType) => {
    let styling = [];
    items.forEach((item) => {
        if (typeof item === 'number') {
            styling.push(`${item}px`);
        }
        else if (typeof item === 'string') {
            styling.push(item);
        }
        else {
            if (itemType === 'rows') {
                const rowHeight = item.height;
                if (rowHeight) {
                    styling.push(typeof rowHeight === 'number' ? `${rowHeight}px` : rowHeight);
                }
                else {
                    styling.push('0px');
                }
            }
            else {
                const colWidth = item.width;
                if (colWidth) {
                    styling.push(typeof colWidth === 'number' ? `${colWidth}px` : colWidth);
                }
                else {
                    styling.push('0px');
                }
            }
        }
    });
    return styling;
};
/**
 * @hidden
 */
const validateGridLayoutRowsCols = (arr) => {
    for (const el of arr) {
        const isNum = typeof el === 'number';
        const isStr = typeof el === 'string';
        const isObject = typeof el === 'object' && el !== null;
        if (!isNum && !isStr && !isObject) {
            return false;
        }
    }
    return true;
};

/**
 * Represents the [Kendo UI StackLayout component for Angular]({% slug overview_stacklayout %}).
 */
let StackLayoutComponent = class StackLayoutComponent {
    constructor(renderer, element, localization) {
        this.renderer = renderer;
        this.element = element;
        this.localization = localization;
        this.hostClass = true;
        /**
         * Specifies the gap between the inner StackLayout elements. The default value is `0`
         * ([see example]({% slug layout_stacklayout %}#toc-gaps)).
         */
        this.gap = 0;
        /**
         * Specifies the orientation of the StackLayout
         * ([see example]({% slug layout_stacklayout %}#toc-orientation)).
         *
         * The possible values are:
         * (Default) `horizontal`
         * `vertical`
         */
        this.orientation = 'horizontal';
        this._align = {
            horizontal: 'stretch',
            vertical: 'stretch'
        };
        validatePackage(packageMetadata);
    }
    get horizontalClass() {
        return this.orientation === 'horizontal';
    }
    get verticalClass() {
        return this.orientation === 'vertical';
    }
    get dir() {
        return this.direction;
    }
    /**
     * Specifies the horizontal and vertical alignment of the inner StackLayout elements
     * ([see example]({% slug layout_stacklayout %}#toc-alignment)).
     */
    set align(align) {
        this._align = Object.assign({}, this._align, align);
        this.handleAlignClasses();
    }
    get align() {
        return this._align;
    }
    ngAfterViewInit() {
        this.handleAlignClasses();
        this.setGap();
    }
    ngOnChanges(changes) {
        if (isChanged('gap', changes)) {
            this.setGap();
        }
        if (isChanged('orientation', changes)) {
            this.handleAlignClasses();
        }
    }
    handleAlignClasses() {
        const elem = this.element.nativeElement;
        if (isPresent(this.justifyClass)) {
            this.renderer.removeClass(elem, this.justifyClass);
        }
        if (isPresent(this.alignClass)) {
            this.renderer.removeClass(elem, this.alignClass);
        }
        if (this.orientation === 'horizontal') {
            this.justifyClass = `${JUSTIFY_PREFIX}-${this.align.horizontal}`;
            this.alignClass = `${ALIGN_PREFIX}-${VERTICAL_SUFFIX[this.align.vertical]}`;
        }
        else {
            this.justifyClass = `${JUSTIFY_PREFIX}-${VERTICAL_SUFFIX[this.align.vertical]}`;
            this.alignClass = `${ALIGN_PREFIX}-${this.align.horizontal}`;
        }
        this.renderer.addClass(elem, this.justifyClass);
        this.renderer.addClass(elem, this.alignClass);
    }
    setGap() {
        const parsedGap = isNumber(this.gap) ? `${this.gap}px` : this.gap;
        this.renderer.setStyle(this.element.nativeElement, 'gap', parsedGap);
    }
    get direction() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
};
__decorate([
    HostBinding('class.k-stack-layout'),
    __metadata("design:type", Boolean)
], StackLayoutComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('class.k-hstack'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], StackLayoutComponent.prototype, "horizontalClass", null);
__decorate([
    HostBinding('class.k-vstack'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], StackLayoutComponent.prototype, "verticalClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], StackLayoutComponent.prototype, "dir", null);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], StackLayoutComponent.prototype, "align", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], StackLayoutComponent.prototype, "gap", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], StackLayoutComponent.prototype, "orientation", void 0);
StackLayoutComponent = __decorate([
    Component({
        exportAs: 'kendoStackLayout',
        selector: 'kendo-stacklayout',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.stacklayout'
            }
        ],
        template: `
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef,
        LocalizationService])
], StackLayoutComponent);

const exportedModules$9 = [
    StackLayoutComponent
];
const declarations$9 = [
    ...exportedModules$9
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the StackLayout component.
 */
let StackLayoutModule = class StackLayoutModule {
};
StackLayoutModule = __decorate([
    NgModule({
        declarations: [declarations$9],
        exports: [exportedModules$9],
        imports: [CommonModule]
    })
], StackLayoutModule);

/**
 * Represents the [Kendo UI GridLayout component for Angular]({% slug overview_gridlayout %}).
 */
let GridLayoutComponent = class GridLayoutComponent {
    constructor(renderer, element, localization) {
        this.renderer = renderer;
        this.element = element;
        this.localization = localization;
        this.hostClass = true;
        /**
         * Specifies the gaps between the elements. The default value is `0`
         * ([see example]({% slug layout_gridlayout %}#toc-gaps)).
         */
        this.gap = 0;
        this._align = {
            horizontal: 'stretch',
            vertical: 'stretch'
        };
        validatePackage(packageMetadata);
    }
    get dir() {
        return this.direction;
    }
    /**
     * Specifies the horizontal and vertical alignment of the inner GridLayout elements
     * ([see example]({% slug layout_gridlayout %}#toc-alignment)).
     */
    set align(align) {
        this._align = Object.assign({}, this._align, align);
        this.handleAlignClasses();
    }
    get align() {
        return this._align;
    }
    ngAfterViewInit() {
        this.handleAlignClasses();
        this.handleGridTemplateStyling('rows');
        this.handleGridTemplateStyling('cols');
        this.setGap();
    }
    ngOnChanges(changes) {
        if (isChanged('gap', changes)) {
            this.setGap();
        }
        if (isChanged('rows', changes)) {
            this.handleGridTemplateStyling('rows');
        }
        if (isChanged('cols', changes)) {
            this.handleGridTemplateStyling('cols');
        }
    }
    handleAlignClasses() {
        const elem = this.element.nativeElement;
        if (isPresent(this.justifyClass)) {
            this.renderer.removeClass(elem, this.justifyClass);
        }
        if (isPresent(this.alignClass)) {
            this.renderer.removeClass(elem, this.alignClass);
        }
        this.justifyClass = `${GRID_JUSTIFY_PREFIX}-${this.align.horizontal}`;
        this.alignClass = `${ALIGN_PREFIX}-${VERTICAL_SUFFIX[this.align.vertical]}`;
        this.renderer.addClass(elem, this.justifyClass);
        this.renderer.addClass(elem, this.alignClass);
    }
    setGap() {
        let parsedGap = normalizeGap(this.gap);
        let gapStyle = generateGapStyle(parsedGap);
        this.renderer.setStyle(this.element.nativeElement, 'gap', gapStyle);
    }
    handleGridTemplateStyling(type) {
        if (!isPresent(this[type])) {
            return;
        }
        const isValid = validateGridLayoutRowsCols(this[type]);
        if (!isValid && isDevMode()) {
            const valueType = type === 'rows' ? 'GridLayoutRowSize' : 'GridLayoutColSize';
            throw new Error(`The provided ${type} value contains invalid elements. The array supports values of type number, string or ${valueType}.`);
        }
        const gridTemplateStyle = type === 'rows' ? 'grid-template-rows' : 'grid-template-columns';
        const gridStyle = generateGridStyle(this[type], type);
        this.renderer.setStyle(this.element.nativeElement, gridTemplateStyle, gridStyle.join(' '));
    }
    get direction() {
        return this.localization.rtl ? 'rtl' : 'ltr';
    }
};
__decorate([
    HostBinding('class.k-grid-layout'),
    __metadata("design:type", Boolean)
], GridLayoutComponent.prototype, "hostClass", void 0);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], GridLayoutComponent.prototype, "dir", null);
__decorate([
    Input(),
    __metadata("design:type", Array)
], GridLayoutComponent.prototype, "rows", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], GridLayoutComponent.prototype, "cols", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GridLayoutComponent.prototype, "gap", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], GridLayoutComponent.prototype, "align", null);
GridLayoutComponent = __decorate([
    Component({
        exportAs: 'kendoGridLayout',
        selector: 'kendo-gridlayout',
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.gridlayout'
            }
        ],
        template: `
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef,
        LocalizationService])
], GridLayoutComponent);

let GridLayoutItemComponent = class GridLayoutItemComponent {
    constructor(renderer, element) {
        this.renderer = renderer;
        this.element = element;
    }
    ngOnInit() {
        this.setItemStyle();
    }
    ngOnChanges() {
        this.setItemStyle();
    }
    setItemStyle() {
        const row = this.row || 'auto';
        const col = this.col || 'auto';
        const rowSpan = this.rowSpan ? `span ${this.rowSpan}` : 'auto';
        const colSpan = this.colSpan ? `span ${this.colSpan}` : 'auto';
        const gridAreaStyle = `${row} / ${col} / ${rowSpan} / ${colSpan}`;
        this.renderer.setStyle(this.element.nativeElement, 'grid-area', gridAreaStyle);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], GridLayoutItemComponent.prototype, "row", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GridLayoutItemComponent.prototype, "col", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GridLayoutItemComponent.prototype, "rowSpan", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GridLayoutItemComponent.prototype, "colSpan", void 0);
GridLayoutItemComponent = __decorate([
    Component({
        selector: 'kendo-gridlayout-item',
        template: `
        <ng-content></ng-content>
    `
    }),
    __metadata("design:paramtypes", [Renderer2,
        ElementRef])
], GridLayoutItemComponent);

const exportedModules$a = [
    GridLayoutComponent,
    GridLayoutItemComponent
];
const declarations$a = [
    ...exportedModules$a
];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the GridLayout component.
 */
let GridLayoutModule = class GridLayoutModule {
};
GridLayoutModule = __decorate([
    NgModule({
        declarations: [declarations$a],
        exports: [exportedModules$a],
        imports: [CommonModule]
    })
], GridLayoutModule);

/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmoduleapi'] }})
 * definition for the Layout components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Layout module
 * import { LayoutModule } from '@progress/kendo-angular-layout';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, LayoutModule], // import Layout module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let LayoutModule = class LayoutModule {
};
LayoutModule = __decorate([
    NgModule({
        exports: [
            AvatarModule,
            CardModule,
            DrawerModule,
            PanelBarModule,
            ExpansionPanelModule,
            SplitterModule,
            StepperModule,
            TabStripModule,
            TileLayoutModule,
            StackLayoutModule,
            GridLayoutModule
        ]
    })
], LayoutModule);

/**
 * Generated bundle index. Do not edit.
 */

export { PreventableEvent$1 as PreventableEvent, DrawerService, DrawerItemComponent, DrawerListComponent, DRAWER_ITEM_INDEX, PanelBarService, SplitterBarComponent, SplitterService, StepperListComponent, StepperMessages, STEPPER_STEP_INDEX, StepperStepComponent, StepperService, TabStripMessages, TabComponent, TabStripScrollableButtonComponent, ScrollService, TabStripService, TileLayoutDraggingService, PreventableEvent$2 as PreventableEvent$1, TileLayoutResizeHandleDirective, PanelBarComponent, PanelBarItemComponent, PanelBarContentDirective, PanelBarItemTemplateDirective, PanelBarItemTitleDirective, PanelBarExpandMode, SplitterComponent, SplitterPaneComponent, TabStripComponent, TabStripTabComponent, TabContentDirective, TabTitleDirective, LocalizedTabStripMessagesDirective, TabStripCustomMessagesComponent, DrawerComponent, DrawerContainerComponent, DrawerContentComponent, DrawerSelectEvent, StepperComponent, StepperActivateEvent, StepperCustomMessagesComponent, LocalizedStepperMessagesDirective, AvatarComponent, CardComponent, CardHeaderComponent, CardBodyComponent, CardFooterComponent, CardActionsComponent, CardSeparatorDirective, CardTitleDirective, CardSubtitleDirective, CardMediaDirective, CardAction, ExpansionPanelComponent, ExpansionPanelTitleDirective, ExpansionPanelActionEvent, TileLayoutComponent, TileLayoutItemComponent, TileLayoutItemBodyComponent, TileLayoutItemHeaderComponent, TileLayoutReorderEvent, TileLayoutResizeEvent, AvatarModule, CardModule, DrawerModule, LayoutModule, PanelBarModule, SplitterModule, StepperModule, TabStripModule, ExpansionPanelModule, TileLayoutModule, StackLayoutModule, GridLayoutModule, StackLayoutComponent, GridLayoutComponent, GridLayoutItemComponent, TabCloseEvent, SelectEvent, DrawerTemplateDirective, DrawerItemTemplateDirective, DrawerHeaderTemplateDirective, DrawerFooterTemplateDirective, StepperIndicatorTemplateDirective, StepperLabelTemplateDirective, StepperStepTemplateDirective };
