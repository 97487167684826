/**-----------------------------------------------------------------------------------------
* Copyright © 2021 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata } from 'tslib';
import { isDevMode, HostBinding, Input, EventEmitter, Output, ViewChild, ElementRef, Component, NgZone, Renderer2, NgModule } from '@angular/core';
import { validatePackage } from '@progress/kendo-licensing';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { hasObservers } from '@progress/kendo-angular-common';
import { CommonModule } from '@angular/common';

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-progressbar',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1642588075,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

/**
 * @hidden
 */
const MIN_MAX_ERROR_MESSAGE = `The max value should be greater than the min.`;
/**
 * @hidden
 */
const LABEL_DECIMALS = 3;
/**
 * @hidden
 */
const MIN_RATIO = 0.0001;

/**
 * @hidden
 */
const reverseChunks = (orientation, reverse) => (orientation === 'vertical' && !reverse || orientation === 'horizontal' && reverse);
/**
 * @hidden
 */
const formatValue = (value, min, max, label) => {
    const defaultFormattedValue = truncateNumber(value);
    if (typeof label !== 'boolean') {
        if (typeof label.format === 'string') {
            switch (label.format) {
                case 'value':
                    return defaultFormattedValue;
                case 'percent':
                    return `${Math.floor(calculatePercentage(value, min, max))}%`;
                default:
                    return defaultFormattedValue;
            }
        }
        else if (typeof label.format === 'function') {
            return label.format(value);
        }
        else {
            return defaultFormattedValue;
        }
    }
    return defaultFormattedValue;
};
/**
 * @hidden
 */
const validateRange = (min, max) => {
    if (isDevMode && min > max) {
        throw new Error(MIN_MAX_ERROR_MESSAGE);
    }
};
/**
 * @hidden
 */
const adjustValueToRange = (min, max, value) => Math.max(Math.min(value, max), min);
/**
 * @hidden
 */
const calculatePercentage = (value, min, max) => {
    const onePercent = Math.abs((max - min) / 100);
    return Math.abs((value - min) / onePercent);
};
/**
 * @hidden
 */
const truncateNumber = (value) => {
    const numberParts = value.toString().split('.');
    return numberParts.length === 1 ? `${numberParts[0]}` : `${numberParts[0]}.${numberParts[1].substr(0, LABEL_DECIMALS)}`;
};
/**
 * @hidden
 */
const calculateRatio = (min, max, value) => Math.max((value - min) / (max - min), MIN_RATIO);
/**
 * @hidden
 */
const extractValueFromChanges = (changes, type, value) => changes[type] && changes[type].currentValue !== undefined ? changes[type].currentValue : value;
/**
 * @hidden
 */
const runAnimation = (changes, animation, previousValue, displayValue) => animation && typeof requestAnimationFrame !== 'undefined' && changes.value && previousValue !== displayValue;
/**
 * @hidden
 */
const stopCurrentAnimation = (changes) => {
    const isAnimationChanged = Boolean(changes.animation);
    const hasAnimation = isAnimationChanged && changes.animation.currentValue;
    return isAnimationChanged && !hasAnimation;
};

/**
 * @hidden
 */
class ProgressBarBase {
    /**
     * @hidden
     */
    constructor(localization) {
        this.localization = localization;
        this.widgetClasses = true;
        this.roleAttribute = 'progressbar';
        /**
         * The maximum value of the ProgressBar.
         * Defaults to `100`.
         */
        this.max = 100;
        /**
         * The minimum value of the ProgressBar.
         * Defaults to `0`.
         */
        this.min = 0;
        /**
         * The value of the ProgressBar.
         * Has to be between `min` and `max`.
         * By default, the value is equal to the `min` value.
         */
        /**
         * The value of the ProgressBar.
         * Has to be between `min` and `max`.
         * Defaults to `0`.
         */
        this.value = 0;
        /**
         * Defines the orientation of the ProgressBar
         * ([see example]({% slug progressbar_orientation %})).
         * Defaults to `horizontal`.
         */
        this.orientation = 'horizontal';
        /**
         * If set to `true`, the ProgressBar will be disabled
         * ([see example]({% slug progressbar_disabled %})).
         * It will still allow you to change its value.
         * Defaults to `false`.
         */
        this.disabled = false;
        /**
         * If set to `true`, the ProgressBar will be reversed
         * ([see example]({% slug progressbar_direction %})).
         * Defaults to `false`.
         */
        this.reverse = false;
        /**
         * Sets the `indeterminate` state of the ProgressBar.
         * Defaults to `false`.
         */
        this.indeterminate = false;
        this.displayValue = 0;
        this.previousValue = 0;
        validatePackage(packageMetadata);
        this.localizationChangeSubscription = localization.changes.subscribe(({ rtl }) => {
            this.direction = rtl ? 'rtl' : 'ltr';
        });
    }
    get isHorizontal() {
        return this.orientation === 'horizontal';
    }
    get isVertical() {
        return this.orientation === 'vertical';
    }
    get disabledClass() {
        return this.disabled;
    }
    get reverseClass() {
        return this.reverse;
    }
    get indeterminateClass() {
        return this.indeterminate;
    }
    get dirAttribute() {
        return this.direction;
    }
    get ariaMinAttribute() {
        return String(this.min);
    }
    get ariaMaxAttribute() {
        return String(this.max);
    }
    get ariaValueAttribute() {
        return this.indeterminate ? undefined : String(this.displayValue);
    }
    /**
     * @hidden
     */
    get isCompleted() {
        return this.value === this.max;
    }
    /**
     * @hidden
     */
    get statusWidth() {
        return this.orientation === 'horizontal' ? this._progressRatio * 100 : 100;
    }
    /**
     * @hidden
     */
    get statusHeight() {
        return this.orientation === 'vertical' ? this._progressRatio * 100 : 100;
    }
    /**
     * @hidden
     */
    get statusWrapperWidth() {
        return this.orientation === 'horizontal' ? 100 / this._progressRatio : 100;
    }
    /**
     * @hidden
     */
    get statusWrapperHeight() {
        return this.orientation === 'vertical' ? 100 / this._progressRatio : 100;
    }
    get _progressRatio() {
        return calculateRatio(this.min, this.max, this.displayValue);
    }
    ngOnChanges(changes) {
        const min = extractValueFromChanges(changes, 'min', this.min);
        const max = extractValueFromChanges(changes, 'max', this.max);
        const value = extractValueFromChanges(changes, 'value', this.value);
        if (changes.min || changes.max || changes.value) {
            if (changes.min || changes.max) {
                validateRange(min, max);
            }
            if (changes.value) {
                if (value == null || Number.isNaN(value)) {
                    this.value = min;
                }
                const previousValue = this.displayValue;
                this.displayValue = adjustValueToRange(this.min, this.max, value);
                this.previousValue = previousValue;
            }
            this.min = min;
            this.max = max;
            this.displayValue = adjustValueToRange(this.min, this.max, value);
        }
    }
    ngOnDestroy() {
        if (this.localizationChangeSubscription) {
            this.localizationChangeSubscription.unsubscribe();
        }
    }
}
__decorate([
    HostBinding('class.k-widget'),
    HostBinding('class.k-progressbar'),
    __metadata("design:type", Boolean)
], ProgressBarBase.prototype, "widgetClasses", void 0);
__decorate([
    HostBinding('class.k-progressbar-horizontal'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "isHorizontal", null);
__decorate([
    HostBinding('class.k-progressbar-vertical'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "isVertical", null);
__decorate([
    HostBinding('class.k-state-disabled'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "disabledClass", null);
__decorate([
    HostBinding('class.k-progressbar-reverse'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "reverseClass", null);
__decorate([
    HostBinding('class.k-progressbar-indeterminate'),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "indeterminateClass", null);
__decorate([
    HostBinding('attr.dir'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "dirAttribute", null);
__decorate([
    HostBinding('attr.role'),
    __metadata("design:type", String)
], ProgressBarBase.prototype, "roleAttribute", void 0);
__decorate([
    HostBinding('attr.aria-valuemin'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "ariaMinAttribute", null);
__decorate([
    HostBinding('attr.aria-valuemax'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "ariaMaxAttribute", null);
__decorate([
    HostBinding('attr.aria-valuenow'),
    __metadata("design:type", String),
    __metadata("design:paramtypes", [])
], ProgressBarBase.prototype, "ariaValueAttribute", null);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ProgressBarBase.prototype, "max", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ProgressBarBase.prototype, "min", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ProgressBarBase.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ProgressBarBase.prototype, "orientation", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ProgressBarBase.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ProgressBarBase.prototype, "reverse", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ProgressBarBase.prototype, "indeterminate", void 0);

/**
 * Represents the [Kendo UI ProgressBar component for Angular]({% slug overview_progressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-progressbar [value]="value">
 *        </kendo-progressbar>
 *    `
 * })
 * class AppComponent {
 *     public value = 50;
 * }
 * ```
 */
let ProgressBarComponent = class ProgressBarComponent extends ProgressBarBase {
    /**
     * @hidden
     */
    constructor(localization, zone, renderer) {
        super(localization);
        this.localization = localization;
        this.zone = zone;
        this.renderer = renderer;
        /**
         * Determines whether the status label will be visible.
         * Defaults to `true`&mdash;the label will be visible and displayed with the default
         * `LabelSettings` having its position set to `end` and its format set to `value`.
         */
        this.label = true;
        /**
         * The animation configuration of the ProgressBar.
         * Defaults to `false`.
         */
        this.animation = false;
        /**
         * Fires when the animation which indicates the latest value change is completed.
         */
        this.animationEnd = new EventEmitter();
    }
    /**
     * @hidden
     */
    get showLabel() {
        if (typeof this.label === 'boolean') {
            return this.label;
        }
        else {
            if (this.label && !this.label.hasOwnProperty('visible')) {
                this.label.visible = true;
            }
            return this.label.visible;
        }
    }
    /**
     * @hidden
     */
    get labelPosition() {
        if (typeof this.label === 'boolean') {
            return 'end';
        }
        else {
            if (this.label && !this.label.hasOwnProperty('position')) {
                this.label.position = 'end';
            }
            return this.label.position;
        }
    }
    /**
     * @hidden
     */
    get isPositionStart() {
        return this.labelPosition === 'start';
    }
    /**
     * @hidden
     */
    get isPositionCenter() {
        return this.labelPosition === 'center';
    }
    /**
     * @hidden
     */
    get isPositionEnd() {
        return this.labelPosition === 'end';
    }
    /**
     * @hidden
     */
    get formattedLabelValue() {
        return formatValue(this.displayValue, this.min, this.max, this.label);
    }
    /**
     * @hidden
     */
    ngOnChanges(changes) {
        super.ngOnChanges(changes);
        if (this.isAnimationInProgress && stopCurrentAnimation(changes)) {
            this.cancelCurrentAnimation = true;
        }
        if (runAnimation(changes, this.animation, this.previousValue, this.displayValue) && !changes.value.firstChange) {
            this.startAnimation(this.previousValue);
        }
    }
    /**
     * @hidden
     */
    ngOnDestroy() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
    }
    /**
     * @hidden
     */
    startAnimation(previousValue) {
        this.isAnimationInProgress = true;
        const element = this.progressStatusElement.nativeElement;
        const wrapperElement = this.progressStatusWrapperElement.nativeElement;
        const animationOptions = this.getAnimationOptions(previousValue);
        this.zone.runOutsideAngular(() => {
            if (this.animationFrame) {
                cancelAnimationFrame(this.animationFrame);
            }
            const animate = () => {
                const elapsed = new Date().getTime() - animationOptions.startTime;
                const position = Math.min(elapsed / animationOptions.duration, 1);
                const size = animationOptions.startSize + animationOptions.deltaSize * position;
                const wrapperSize = (100 / size) * 100;
                this.renderValueChange(element, wrapperElement, animationOptions.property, size, wrapperSize);
                if (position < 1) {
                    if (this.cancelCurrentAnimation) {
                        this.resetProgress(element, wrapperElement, animationOptions.property);
                        return;
                    }
                    this.animationFrame = requestAnimationFrame(animate);
                }
                else {
                    this.stopAnimation(previousValue);
                }
            };
            animate();
        });
    }
    /**
     * @hidden
     */
    get animationDuration() {
        if (typeof this.animation === 'boolean') {
            return 400;
        }
        else {
            if (this.animation && !this.animation.hasOwnProperty('duration')) {
                this.animation.duration = 400;
            }
            return this.animation.duration;
        }
    }
    stopAnimation(value) {
        if (hasObservers(this.animationEnd)) {
            this.zone.run(() => {
                this.animationEnd.emit({
                    from: value,
                    to: this.displayValue
                });
            });
        }
        this.zone.run(() => {
            this.isAnimationInProgress = false;
        });
    }
    getAnimationOptions(value) {
        const isHorizontal = this.orientation === 'horizontal';
        const previousRatio = calculateRatio(this.min, this.max, value);
        const previousStatusWidth = isHorizontal ? previousRatio * 100 : 100;
        const previousStatusHeight = !isHorizontal ? previousRatio * 100 : 100;
        const property = isHorizontal ? 'width' : 'height';
        const startTime = new Date().getTime();
        const startSize = isHorizontal ? previousStatusWidth : previousStatusHeight;
        const deltaSize = isHorizontal ? this.statusWidth - previousStatusWidth : this.statusHeight - previousStatusHeight;
        const duration = this.animationDuration * Math.abs((deltaSize / 100));
        return {
            property,
            startTime,
            startSize,
            deltaSize,
            duration
        };
    }
    renderValueChange(element, wrapperElement, property, size, wrapperSize) {
        this.renderer.setStyle(element, property, size + '%');
        this.renderer.setStyle(wrapperElement, property, wrapperSize + '%');
    }
    resetProgress(element, wrapperElement, property) {
        const size = calculateRatio(this.min, this.max, this.value);
        const newSize = size * 100;
        const newWrapperSize = 100 / size;
        this.renderValueChange(element, wrapperElement, property, newSize, newWrapperSize);
        this.zone.run(() => {
            this.cancelCurrentAnimation = false;
            this.isAnimationInProgress = false;
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "progressCssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "progressCssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "emptyCssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "emptyCssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ProgressBarComponent.prototype, "animation", void 0);
__decorate([
    Output(),
    __metadata("design:type", EventEmitter)
], ProgressBarComponent.prototype, "animationEnd", void 0);
__decorate([
    ViewChild('progressStatus', { static: false }),
    __metadata("design:type", ElementRef)
], ProgressBarComponent.prototype, "progressStatusElement", void 0);
__decorate([
    ViewChild('progressStatusWrap', { static: false }),
    __metadata("design:type", ElementRef)
], ProgressBarComponent.prototype, "progressStatusWrapperElement", void 0);
ProgressBarComponent = __decorate([
    Component({
        exportAs: 'kendoProgressBar',
        selector: 'kendo-progressbar',
        template: `
        <span class="k-progress-status-wrap"
            [class.k-progress-start]="isPositionStart"
            [class.k-progress-center]="isPositionCenter"
            [class.k-progress-end]="isPositionEnd"
            [ngStyle]="emptyCssStyle"
            [ngClass]="emptyCssClass">
            <span *ngIf="showLabel" class="k-progress-status">{{formattedLabelValue}}</span>
        </span>
        <div
            #progressStatus
            class="k-state-selected"
            [class.k-complete]="isCompleted"
            [ngStyle]="progressCssStyle"
            [ngClass]="progressCssClass"
            [style.width.%]="statusWidth"
            [style.height.%]="statusHeight"
            >
            <span
                #progressStatusWrap
                class="k-progress-status-wrap"
                [style.width.%]="statusWrapperWidth"
                [style.height.%]="statusWrapperHeight"
                [class.k-progress-start]="isPositionStart"
                [class.k-progress-center]="isPositionCenter"
                [class.k-progress-end]="isPositionEnd"
                >
                <span *ngIf="showLabel" class="k-progress-status">{{formattedLabelValue}}</span>
            </span>
        </div>
       `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.progressbar'
            }
        ]
    }),
    __metadata("design:paramtypes", [LocalizationService,
        NgZone,
        Renderer2])
], ProgressBarComponent);

/**
 * Represents the [Kendo UI ChunkProgressBar component for Angular]({% slug overview_chunkprogressbar %}).
 *
 * @example
 * ```ts-preview
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-chunkprogressbar [value]="value">
 *        </kendo-chunkprogressbar>
 *    `
 * })
 * class AppComponent {
 *     public value = 40;
 * }
 * ```
 */
let ChunkProgressBarComponent = class ChunkProgressBarComponent extends ProgressBarBase {
    /**
     * @hidden
     */
    constructor(localization) {
        super(localization);
        this.localization = localization;
        /**
         * Sets the number of chunks into which the ChunkProgressBar will be split.
         * Defaults to `5`.
         */
        this.chunkCount = 5;
        this._orientationStyles = {
            width: `${this.chunkSizePercentage}%`
        };
    }
    /**
     * @hidden
     */
    get chunks() {
        const count = this.chunkCount;
        const chunks = Array(count).fill(false);
        const completedChunks = Math.floor(this._progressRatio * count);
        for (let i = 0; i < completedChunks; i++) {
            chunks[i] = true;
        }
        if (reverseChunks(this.orientation, this.reverse)) {
            chunks.reverse();
        }
        return chunks;
    }
    /**
     * @hidden
     */
    get chunkSizePercentage() {
        return 100 / this.chunkCount;
    }
    /**
     * @hidden
     */
    get orientationStyles() {
        if (this.orientation === 'horizontal') {
            this._orientationStyles.width = `${this.chunkSizePercentage}%`;
            this._orientationStyles.height = undefined;
        }
        else {
            this._orientationStyles.height = `${this.chunkSizePercentage}%`;
            this._orientationStyles.width = undefined;
        }
        return this._orientationStyles;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], ChunkProgressBarComponent.prototype, "chunkCount", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ChunkProgressBarComponent.prototype, "progressCssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ChunkProgressBarComponent.prototype, "progressCssClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ChunkProgressBarComponent.prototype, "emptyCssStyle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ChunkProgressBarComponent.prototype, "emptyCssClass", void 0);
ChunkProgressBarComponent = __decorate([
    Component({
        exportAs: 'kendoChunkProgressBar',
        selector: 'kendo-chunkprogressbar',
        template: `
        <ul class="k-reset">
            <li class="k-item" *ngFor="let chunk of chunks; let i = index;"
                [class.k-first]="i === 0"
                [class.k-last]="i === chunkCount - 1"
                [class.k-state-selected]="chunk"
                [ngClass]="chunk ? progressCssClass : emptyCssClass"
                [ngStyle]="chunk ? progressCssStyle : emptyCssStyle"
                [style.width]="orientationStyles.width"
                [style.height]="orientationStyles.height"
                >
            </li>
        </ul>
    `,
        providers: [
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.chunkprogressbar'
            }
        ]
    }),
    __metadata("design:paramtypes", [LocalizationService])
], ChunkProgressBarComponent);

const COMPONENT_DIRECTIVES = [ProgressBarComponent, ChunkProgressBarComponent];
const MODULES = [CommonModule];
/**
 * Represents the [NgModule]({{ site.data.urls.angular['ngmodules'] }})
 * definition for the ProgressBar components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the ProgressBar module
 * import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, ProgressBarModule], // import ProgressBar module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
let ProgressBarModule = class ProgressBarModule {
};
ProgressBarModule = __decorate([
    NgModule({
        declarations: COMPONENT_DIRECTIVES,
        exports: COMPONENT_DIRECTIVES,
        imports: MODULES
    })
], ProgressBarModule);

/**
 * Generated bundle index. Do not edit.
 */

export { ProgressBarBase, ProgressBarComponent, ChunkProgressBarComponent, ProgressBarModule };
